var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "profile" },
    [
      _c(
        "div",
        { staticClass: "card-collapsible" },
        [
          _c(
            "div",
            {
              staticClass:
                "header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer",
              on: {
                click: function ($event) {
                  return _vm.handleClickHeader("personnal-data")
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "title w-90" },
                [
                  _c("b-icon", {
                    staticStyle: { color: "rgba(6, 38, 62, 0.84)" },
                    attrs: { icon: "person-check-fill" },
                  }),
                  _c("span", { staticClass: "ml-3" }, [
                    _vm._v(_vm._s(_vm.FormMSG(1, "Personal data"))),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-content-end w-10" },
                [
                  !_vm.configAccordion["personnal-data"].isVisible
                    ? _c(_vm.getIconLucide("ChevronDown"), {
                        tag: "component",
                        attrs: { color: "#06263E", size: 22 },
                      })
                    : _vm._e(),
                  _vm.configAccordion["personnal-data"].isVisible
                    ? _c(_vm.getIconLucide("ChevronUp"), {
                        tag: "component",
                        attrs: { color: "#06263E", size: 22 },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-collapse",
            {
              attrs: {
                visible: _vm.configAccordion["personnal-data"].isVisible,
              },
            },
            [
              _c(
                "div",
                { staticClass: "content-card-collapsible" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "8", md: "8", lg: "9", xl: "9" } },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: "4",
                                    xl: "4",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c(
                                        "label",
                                        { attrs: { for: "reference" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.FormMSG(2, "Reference")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "text",
                                          id: "reference",
                                          placeholder: _vm.FormMSG(
                                            3,
                                            "Enter your personal number."
                                          ),
                                          disabled:
                                            _vm.isFilmSingleUserFree ||
                                            _vm.isFilmSingleUser,
                                        },
                                        model: {
                                          value: _vm.profile.reference,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profile,
                                              "reference",
                                              $$v
                                            )
                                          },
                                          expression: "profile.reference",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: "4",
                                    xl: "4",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: { label: _vm.FormMSG(4, "Email") },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              value: _vm.profile.email,
                                              type: "text",
                                              id: "email",
                                              placeholder: _vm.FormMSG(
                                                5,
                                                "Enter your email"
                                              ),
                                              disabled: "",
                                            },
                                          }),
                                          _vm.isFilmSingleUserFree ||
                                          _vm.isFilmSingleUser
                                            ? _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "cursor-pointer",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.showChangeMailModal = true
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getIconLucide(
                                                          "Pencil"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            color: "#007A77",
                                                            size: 16,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: "4",
                                    xl: "4",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: { label: _vm.FormMSG(6, "Phone") },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              value: _vm.profile.phone,
                                              type: "text",
                                              id: "phone",
                                              disabled: "",
                                              placeholder: _vm.FormMSG(
                                                7,
                                                "Enter your phone number"
                                              ),
                                            },
                                          }),
                                          _vm.isFilmSingleUserFree ||
                                          _vm.isFilmSingleUser
                                            ? _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "cursor-pointer",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.showChangePhoneModal = true
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getIconLucide(
                                                          "Pencil"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            color: "#007A77",
                                                            size: 16,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: "3",
                                    xl: "3",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c(
                                        "label",
                                        { attrs: { for: "firstname" } },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.FormMSG(8, "First Name"))
                                          ),
                                        ]
                                      ),
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "text",
                                          id: "firstname",
                                          placeholder: _vm.FormMSG(
                                            9,
                                            "Enter your first name."
                                          ),
                                        },
                                        model: {
                                          value: _vm.profile.firstName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profile,
                                              "firstName",
                                              $$v
                                            )
                                          },
                                          expression: "profile.firstName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: "3",
                                    xl: "3",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c("label", { attrs: { for: "name" } }, [
                                        _vm._v(
                                          _vm._s(_vm.FormMSG(10, "Last Name")) +
                                            " "
                                        ),
                                      ]),
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "text",
                                          id: "name",
                                          placeholder: _vm.FormMSG(
                                            11,
                                            "Enter your name."
                                          ),
                                        },
                                        model: {
                                          value: _vm.profile.name,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.profile, "name", $$v)
                                          },
                                          expression: "profile.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: "3",
                                    xl: "3",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c(
                                        "label",
                                        { attrs: { for: "maidenName" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(
                                                1239,
                                                " Maiden name (if different)"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "text",
                                          id: "maidenName",
                                        },
                                        model: {
                                          value: _vm.profile.maidenName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profile,
                                              "maidenName",
                                              $$v
                                            )
                                          },
                                          expression: "profile.maidenName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "d-flex justify-content-start",
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: "3",
                                    xl: "3",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.FormMSG(86, "Color"),
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-100 d-flex justify-content-start",
                                        },
                                        [
                                          _c("verte", {
                                            attrs: {
                                              menuPosition: "top",
                                              picker: "square",
                                              model: "hex",
                                              "menu-position": "left",
                                            },
                                            model: {
                                              value: _vm.profile.color,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.profile,
                                                  "color",
                                                  $$v
                                                )
                                              },
                                              expression: "profile.color",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: "3",
                                    xl: "3",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c(
                                        "label",
                                        { attrs: { for: "nationality" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(12, "Nationality")
                                            )
                                          ),
                                        ]
                                      ),
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "text",
                                          id: "nationality",
                                          placeholder: _vm.FormMSG(
                                            13,
                                            "Enter your nationality"
                                          ),
                                        },
                                        model: {
                                          value: _vm.profile.nationality,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profile,
                                              "nationality",
                                              $$v
                                            )
                                          },
                                          expression: "profile.nationality",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: "3",
                                    xl: "3",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.FormMSG(14, "Langue"),
                                        "label-for": "optionsForLanguages",
                                      },
                                    },
                                    [
                                      _c("b-form-select", {
                                        attrs: {
                                          options: _vm.optionsForLanguages,
                                          id: "optionsForLanguages",
                                        },
                                        model: {
                                          value: _vm.profile.language,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profile,
                                              "language",
                                              $$v
                                            )
                                          },
                                          expression: "profile.language",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: "3",
                                    xl: "3",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.FormMSG(20, "Gender"),
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-select",
                                        {
                                          model: {
                                            value: _vm.profile.gender,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.profile,
                                                "gender",
                                                $$v
                                              )
                                            },
                                            expression: "profile.gender",
                                          },
                                        },
                                        _vm._l(
                                          _vm.menuGenders,
                                          function (gender, index) {
                                            return _c(
                                              "b-form-select-option",
                                              {
                                                key: index,
                                                attrs: { value: gender.value },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(gender.text) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: "3",
                                    xl: "3",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.FormMSG(1240, "Title"),
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-select",
                                        {
                                          model: {
                                            value: _vm.profile.title,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.profile,
                                                "title",
                                                $$v
                                              )
                                            },
                                            expression: "profile.title",
                                          },
                                        },
                                        _vm._l(
                                          _vm.menuTitle,
                                          function (title, index) {
                                            return _c(
                                              "b-form-select-option",
                                              {
                                                key: index,
                                                attrs: { value: title.value },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(title.text) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: "3",
                                    xl: "3",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.FormMSG(15, "Birthdate"),
                                        "label-for": "birthDate",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          value: _vm.displayBirthDate,
                                          type: "date",
                                          id: "birthDate",
                                        },
                                        on: { input: _vm.updateBirthDate },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: "3",
                                    xl: "3",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.FormMSG(
                                          16,
                                          "Place of birth"
                                        ),
                                        "label-for": "placeOfBirth",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "placeOfBirth",
                                          type: "text",
                                          placeholder: _vm.FormMSG(
                                            17,
                                            "Place of birth"
                                          ),
                                          autocomplete: "off",
                                        },
                                        model: {
                                          value: _vm.profile.placeOfBirth,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profile,
                                              "placeOfBirth",
                                              $$v
                                            )
                                          },
                                          expression: "profile.placeOfBirth",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: "3",
                                    xl: "3",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.FormMSG(
                                          18,
                                          "Place of birth ZIP"
                                        ),
                                        "label-for": "placeOfBirthZip",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "placeOfBirthZip",
                                          type: "text",
                                          placeholder: _vm.FormMSG(
                                            19,
                                            "Place of birth zip"
                                          ),
                                        },
                                        model: {
                                          value: _vm.profile.placeOfBirthZip,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profile,
                                              "placeOfBirthZip",
                                              $$v
                                            )
                                          },
                                          expression: "profile.placeOfBirthZip",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: "3",
                                    xl: "3",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.FormMSG(
                                          1241,
                                          "Country of birth"
                                        ),
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          placeholder: _vm.FormMSG(
                                            1315,
                                            "Enter your country of ..."
                                          ),
                                        },
                                        model: {
                                          value: _vm.profile.birthCountry,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profile,
                                              "birthCountry",
                                              $$v
                                            )
                                          },
                                          expression: "profile.birthCountry",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.isDeterProject === true
                            ? _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "12",
                                        md: "12",
                                        lg: "3",
                                        xl: "3",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.FormMSG(
                                              12410,
                                              "Custom function name"
                                            ),
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              placeholder:
                                                _vm.profile.functionName,
                                            },
                                            model: {
                                              value:
                                                _vm.profile.customFunctionName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.profile,
                                                  "customFunctionName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "profile.customFunctionName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "text-center",
                          attrs: { sm: "4", md: "4", lg: "3", xl: "3" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "profile-picture-block" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  directives: [
                                    {
                                      name: "lazy-container",
                                      rawName: "v-lazy-container",
                                      value: { selector: "img" },
                                      expression: "{ selector: 'img' }",
                                    },
                                  ],
                                  model: {
                                    value: _vm.profile.picture,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.profile, "picture", $$v)
                                    },
                                    expression: "profile.picture",
                                  },
                                },
                                [
                                  _c("img", {
                                    staticClass: "profile-picture-preview",
                                    attrs: {
                                      "data-src": _vm.imageName,
                                      "data-error": "img/brand/avatar.jpg",
                                      "data-loading": "img/brand/tenor.gif",
                                      alt: "pict",
                                    },
                                  }),
                                ]
                              ),
                              _vm.isFilmSingleUserFree || _vm.isFilmSingleUser
                                ? _c(
                                    "b-form-group",
                                    [
                                      !_vm.$isPwa()
                                        ? _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                variant: "outline-secondary",
                                                size: "md",
                                              },
                                              on: { click: _vm.startCapture },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "icon-camera",
                                              }),
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      21,
                                                      "Change your picture"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.$isPwa()
                                        ? _c("MobileProfilePicCaptureBtn", {
                                            attrs: {
                                              "label-button": _vm.FormMSG(
                                                22,
                                                "Change your picture"
                                              ),
                                              "parent-type": "user",
                                              parentId: _vm.profile.id,
                                            },
                                            on: { change: _vm.handleImgsTaked },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.isFilmSingle
                            ? _c("ShareInformation", {
                                attrs: {
                                  "btn-label": _vm.FormMSG(
                                    23,
                                    "Share my Information"
                                  ),
                                  "title-modal": _vm.FormMSG(
                                    24,
                                    "Send your personal informations sheet"
                                  ),
                                  "msg-success": _vm.FormMSG(
                                    25,
                                    "Profile successfully sent"
                                  ),
                                  "show-check-memo-deal": "",
                                  "send-a-copy": "",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "card-collapsible mt-3" },
        [
          _c(
            "div",
            {
              staticClass:
                "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
              on: {
                click: function ($event) {
                  return _vm.handleClickHeader("identification")
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "title w-90" },
                [
                  _c("b-icon", {
                    staticStyle: { color: "rgba(6, 38, 62, 0.84)" },
                    attrs: { icon: "person-lines-fill" },
                  }),
                  _c("span", { staticClass: "ml-3" }, [
                    _vm._v(_vm._s(_vm.FormMSG(26, "Identification"))),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-content-end w-10" },
                [
                  !_vm.configAccordion["identification"].isVisible
                    ? _c(_vm.getIconLucide("ChevronDown"), {
                        tag: "component",
                        attrs: { color: "#06263E", size: 22 },
                      })
                    : _vm._e(),
                  _vm.configAccordion["identification"].isVisible
                    ? _c(_vm.getIconLucide("ChevronUp"), {
                        tag: "component",
                        attrs: { color: "#06263E", size: 22 },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-collapse",
            {
              attrs: {
                visible: _vm.configAccordion["identification"].isVisible,
              },
            },
            [
              _c("div", { staticClass: "sub-title" }, [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.FormMSG(27, "Identification Numbers")) +
                    "\n\t\t\t"
                ),
              ]),
              _c(
                "div",
                { staticClass: "content-card-collapsible" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "label",
                                { attrs: { for: "nationalRegistryNumber" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.FormMSG(
                                        30,
                                        "National Registration Number"
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  id: "nationalRegistryNumber",
                                  placeholder: _vm.FormMSG(
                                    31,
                                    "Enter your National Registration Number "
                                  ),
                                },
                                model: {
                                  value: _vm.profile.nationalRegistryNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.profile,
                                      "nationalRegistryNumber",
                                      $$v
                                    )
                                  },
                                  expression: "profile.nationalRegistryNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", { attrs: { for: "companyNumber" } }, [
                                _vm._v(
                                  _vm._s(_vm.FormMSG(32, "Company Number"))
                                ),
                              ]),
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  id: "companyNumber",
                                  placeholder: _vm.FormMSG(
                                    33,
                                    "Enter your Company official Number "
                                  ),
                                },
                                model: {
                                  value: _vm.profile.companyNumber,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.profile, "companyNumber", $$v)
                                  },
                                  expression: "profile.companyNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "label",
                                { attrs: { for: "conge_spectacles_id" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.FormMSG(
                                        1242,
                                        '"Congés spectacles" ID'
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  id: "conge_spectacles_id",
                                  placeholder: _vm.FormMSG(
                                    1243,
                                    "Enter your conges spectacles id"
                                  ),
                                },
                                model: {
                                  value: _vm.profile.congesSpectacle,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.profile,
                                      "congesSpectacle",
                                      $$v
                                    )
                                  },
                                  expression: "profile.congesSpectacle",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", { attrs: { for: "signatureId" } }, [
                                _vm._v(_vm._s(_vm.FormMSG(106, "Signature"))),
                              ]),
                              _c(
                                "b-row",
                                { staticClass: "ml-0" },
                                [
                                  _vm.checkFile(_vm.profile.signatureId)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-center align-items-center cursor-pointer",
                                          on: {
                                            click: function ($event) {
                                              _vm.onOpenUrl(
                                                _vm.getImage(
                                                  _vm.profile.signatureId,
                                                  "signatureId"
                                                )
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "file-earmark-pdf-fill",
                                              "font-scale": 1.6,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "b-img",
                                        _vm._b(
                                          {
                                            staticClass: "cursor-pointer",
                                            attrs: {
                                              rounded: "circle",
                                              alt: "Signature",
                                              src: _vm.getImage(
                                                _vm.profile.signatureId,
                                                "signatureId"
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showImage(
                                                  _vm.profile.signatureId
                                                )
                                              },
                                            },
                                          },
                                          "b-img",
                                          _vm.imgPropSignatureId,
                                          false
                                        )
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", { attrs: { for: "idNumber" } }, [
                                _vm._v(_vm._s(_vm.FormMSG(99, "ID Name"))),
                              ]),
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  id: "idNumber",
                                  placeholder: _vm.FormMSG(
                                    100,
                                    "Enter your ID Name ..."
                                  ),
                                },
                                model: {
                                  value: _vm.profile.idName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.profile, "idName", $$v)
                                  },
                                  expression: "profile.idName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", { attrs: { for: "idNumber" } }, [
                                _vm._v(_vm._s(_vm.FormMSG(34, "ID Number"))),
                              ]),
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  id: "idNumber",
                                  placeholder: _vm.FormMSG(
                                    35,
                                    "Enter your ID Number ..."
                                  ),
                                },
                                model: {
                                  value: _vm.profile.idNumber,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.profile, "idNumber", $$v)
                                  },
                                  expression: "profile.idNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", { attrs: { for: "pictureId" } }, [
                                _vm._v(
                                  _vm._s(_vm.FormMSG(101, "ID picture - Front"))
                                ),
                              ]),
                              _c(
                                "b-row",
                                { staticClass: "ml-0" },
                                [
                                  _vm.checkFile(_vm.profile.pictureId)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-center align-items-center cursor-pointer",
                                          on: {
                                            click: function ($event) {
                                              _vm.onOpenUrl(
                                                _vm.getImage(
                                                  _vm.profile.pictureId,
                                                  "pictureId"
                                                )
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "file-earmark-pdf-fill",
                                              "font-scale": 1.6,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "b-img",
                                        _vm._b(
                                          {
                                            staticClass: "cursor-pointer",
                                            attrs: {
                                              rounded: "circle",
                                              alt: "ID picture - front",
                                              src: _vm.getImage(
                                                _vm.profile.pictureId,
                                                "pictureId"
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showImage(
                                                  _vm.profile.pictureId
                                                )
                                              },
                                            },
                                          },
                                          "b-img",
                                          _vm.imgPropPictureId,
                                          false
                                        )
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "label",
                                { attrs: { for: "pictureIdVerso" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.FormMSG(102, "ID picture - Back")
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "b-row",
                                { staticClass: "ml-0" },
                                [
                                  _vm.checkFile(_vm.profile.pictureIdVerso)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-center align-items-center cursor-pointer",
                                          on: {
                                            click: function ($event) {
                                              _vm.onOpenUrl(
                                                _vm.getImage(
                                                  _vm.profile.pictureIdVerso,
                                                  "pictureIdVerso"
                                                )
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "file-earmark-pdf-fill",
                                              "font-scale": 1.6,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "b-img",
                                        _vm._b(
                                          {
                                            staticClass: "cursor-pointer",
                                            attrs: {
                                              rounded: "circle",
                                              alt: "ID picture - back",
                                              src: _vm.getImage(
                                                _vm.profile.pictureIdVerso,
                                                "pictureIdVerso"
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showImage(
                                                  _vm.profile.pictureIdVerso
                                                )
                                              },
                                            },
                                          },
                                          "b-img",
                                          _vm.imgPropPictureIdVerso,
                                          false
                                        )
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", [
                                _vm._v(
                                  _vm._s(_vm.FormMSG(36, "Driver licence"))
                                ),
                              ]),
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.FormMSG(
                                    37,
                                    "Enter your driver license"
                                  ),
                                },
                                model: {
                                  value: _vm.profile.driverLicense,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.profile, "driverLicense", $$v)
                                  },
                                  expression: "profile.driverLicense",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", [
                                _vm._v(
                                  _vm._s(
                                    _vm.FormMSG(1314, "Driver licence Number")
                                  )
                                ),
                              ]),
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.FormMSG(
                                    1316,
                                    "Enter your driver license number"
                                  ),
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                  },
                                },
                                model: {
                                  value: _vm.profile.driverLicenseNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.profile,
                                      "driverLicenseNumber",
                                      $$v
                                    )
                                  },
                                  expression: "profile.driverLicenseNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", { attrs: { for: "idNumber" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.FormMSG(
                                      103,
                                      "Driver license picture - Front"
                                    )
                                  )
                                ),
                              ]),
                              _c(
                                "b-row",
                                { staticClass: "ml-0" },
                                [
                                  _vm.checkFile(
                                    _vm.profile.pictureDriverLicense
                                  )
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-center align-items-center cursor-pointer",
                                          on: {
                                            click: function ($event) {
                                              _vm.onOpenUrl(
                                                _vm.getImage(
                                                  _vm.profile
                                                    .pictureDriverLicense,
                                                  "pictureDriverLicense"
                                                )
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "file-earmark-pdf-fill",
                                              "font-scale": 1.6,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "b-img",
                                        _vm._b(
                                          {
                                            staticClass: "cursor-pointer",
                                            attrs: {
                                              rounded: "",
                                              alt: "Driver license picture - Front",
                                              src: _vm.getImage(
                                                _vm.profile
                                                  .pictureDriverLicense,
                                                "pictureDriverLicense"
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showImage(
                                                  _vm.profile
                                                    .pictureDriverLicense
                                                )
                                              },
                                            },
                                          },
                                          "b-img",
                                          _vm.imgPropPictureDriverLicense,
                                          false
                                        )
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", { attrs: { for: "idNumber" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.FormMSG(
                                      104,
                                      "Driver license picture - Back"
                                    )
                                  )
                                ),
                              ]),
                              _c(
                                "b-row",
                                { staticClass: "ml-0" },
                                [
                                  _vm.checkFile(
                                    _vm.profile.pictureDriverLicenseVerso
                                  )
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-center align-items-center cursor-pointer",
                                          on: {
                                            click: function ($event) {
                                              _vm.onOpenUrl(
                                                _vm.getImage(
                                                  _vm.profile
                                                    .pictureDriverLicenseVerso,
                                                  "pictureDriverLicenseVerso"
                                                )
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "file-earmark-pdf-fill",
                                              "font-scale": 1.6,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "b-img",
                                        _vm._b(
                                          {
                                            staticClass: "cursor-pointer",
                                            attrs: {
                                              rounded: "",
                                              alt: "Driver license picture - Front",
                                              src: _vm.getImage(
                                                _vm.profile
                                                  .pictureDriverLicenseVerso,
                                                "pictureDriverLicenseVerso"
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showImage(
                                                  _vm.profile
                                                    .pictureDriverLicenseVerso
                                                )
                                              },
                                            },
                                          },
                                          "b-img",
                                          _vm.imgPropPictureDriverLicenseVerso,
                                          false
                                        )
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "card-collapsible mt-3" },
        [
          _c(
            "div",
            {
              staticClass:
                "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
              on: {
                click: function ($event) {
                  return _vm.handleClickHeader("finance")
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "title w-90" },
                [
                  _c(_vm.getLucideIcon("Wallet"), {
                    tag: "component",
                    attrs: { color: "rgba(6, 38, 62, 0.84)", size: 22 },
                  }),
                  _c("span", { staticClass: "ml-3" }, [
                    _vm._v(_vm._s(_vm.FormMSG(1244, "Finance"))),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-content-end w-10" },
                [
                  !_vm.configAccordion["finance"].isVisible
                    ? _c(_vm.getIconLucide("ChevronDown"), {
                        tag: "component",
                        attrs: { color: "#06263E", size: 22 },
                      })
                    : _vm._e(),
                  _vm.configAccordion["finance"].isVisible
                    ? _c(_vm.getIconLucide("ChevronUp"), {
                        tag: "component",
                        attrs: { color: "#06263E", size: 22 },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-collapse",
            { attrs: { visible: _vm.configAccordion["finance"].isVisible } },
            [
              _c("div", { staticClass: "sub-title" }, [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.FormMSG(9000, "Bank information")) +
                    "\n\t\t\t"
                ),
              ]),
              _c(
                "div",
                { staticClass: "content-card-collapsible" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          attrs: {
                            sm: "12",
                            md: "12",
                            lg: _vm.isDeterProject ? "4" : "6",
                            xl: _vm.isDeterProject ? "4" : "6",
                          },
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              class: { "is-warning": _vm.userBankWarning.iban },
                              attrs: {
                                "label-for": "iban",
                                label: _vm.FormMSG(4900, "IBAN"),
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  id: "iban",
                                  placeholder: this.FormMSG(
                                    5000,
                                    "Enter your bank account number"
                                  ),
                                },
                                on: { input: _vm.handleSupplierIbanChange },
                                model: {
                                  value: _vm.profile.iban,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.profile, "iban", $$v)
                                  },
                                  expression: "profile.iban",
                                },
                              }),
                              _vm.userBankWarning.iban
                                ? _c(
                                    "div",
                                    { staticClass: "warning-feedback" },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              97986,
                                              "IBAN seems not valid, please make sure it's corrrect"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "6", xl: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              class: { "is-warning": _vm.userBankWarning.bic },
                              attrs: {
                                "label-for": "bic",
                                label: _vm.FormMSG(5100, "BIC"),
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  id: "bic",
                                  placeholder: _vm.FormMSG(
                                    5200,
                                    "Enter your bank BIC"
                                  ),
                                },
                                on: { input: _vm.handleSupplierBicChange },
                                model: {
                                  value: _vm.profile.bic,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.profile, "bic", $$v)
                                  },
                                  expression: "profile.bic",
                                },
                              }),
                              _vm.userBankWarning.bic
                                ? _c(
                                    "div",
                                    { staticClass: "warning-feedback" },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              909097986,
                                              "BIC seems not valid, please make sure it's corrrect"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "6", xl: "6" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", { attrs: { for: "bic" } }, [
                                _vm._v(
                                  _vm._s(
                                    this.FormMSG(
                                      1245,
                                      "Bank account owner name"
                                    )
                                  )
                                ),
                              ]),
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  id: "bic",
                                  placeholder: this.FormMSG(
                                    1246,
                                    "Enter your bank account owner name"
                                  ),
                                },
                                model: {
                                  value: _vm.profile.bankAccountOwnerName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.profile,
                                      "bankAccountOwnerName",
                                      $$v
                                    )
                                  },
                                  expression: "profile.bankAccountOwnerName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "6", xl: "6" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "label",
                                { attrs: { for: "bankDetailDocument" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.FormMSG(1247, "Bank detail document")
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "b-row",
                                { staticClass: "ml-0" },
                                [
                                  _vm.checkFile(_vm.profile.bankDetailXid)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-center align-items-center cursor-pointer",
                                          on: {
                                            click: function ($event) {
                                              _vm.onOpenUrl(
                                                _vm.getImage(
                                                  _vm.profile.bankDetailXid,
                                                  "bankDetailXid"
                                                )
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "file-earmark-pdf-fill",
                                              "font-scale": 1.6,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "b-img",
                                        _vm._b(
                                          {
                                            staticClass: "cursor-pointer",
                                            attrs: {
                                              rounded: "",
                                              alt: "Bank detail document",
                                              src: _vm.getImage(
                                                _vm.profile.bankDetailXid,
                                                "bankDetailXid"
                                              ),
                                              title: _vm.FormMSG(
                                                254,
                                                "View Image"
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showImage(
                                                  _vm.profile.bankDetailXid
                                                )
                                              },
                                            },
                                          },
                                          "b-img",
                                          _vm.imgPropSignatureId,
                                          false
                                        )
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "divider" }),
              _c("div", { staticClass: "sub-title" }, [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.FormMSG(26100, "Taxes")) +
                    "\n\t\t\t"
                ),
              ]),
              _c(
                "div",
                { staticClass: "content-card-collapsible" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(26101, "Taxe") } },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    directives: [
                                      {
                                        name: "limit-100",
                                        rawName: "v-limit-100",
                                      },
                                    ],
                                    attrs: {
                                      type: "number",
                                      step: "1",
                                      min: "0",
                                      max: "100",
                                    },
                                    model: {
                                      value: _vm.profile.taxesPercentage,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.profile,
                                          "taxesPercentage",
                                          $$v
                                        )
                                      },
                                      expression: "profile.taxesPercentage",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticStyle: {
                                            "background-color": "#00a09c",
                                          },
                                        },
                                        [
                                          _c(_vm.getIconLucide("Percent"), {
                                            tag: "component",
                                            attrs: {
                                              color: "#ffffff",
                                              size: 16,
                                              "stroke-width": 3.5,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", { attrs: { for: "bic" } }, [
                                _vm._v(
                                  _vm._s(
                                    this.FormMSG(
                                      1248,
                                      "Tax declaration country"
                                    )
                                  )
                                ),
                              ]),
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  id: "taxCountry",
                                  placeholder: this.FormMSG(
                                    1249,
                                    "Enter your tax declaration country"
                                  ),
                                },
                                model: {
                                  value: _vm.profile.taxCountry,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.profile, "taxCountry", $$v)
                                  },
                                  expression: "profile.taxCountry",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "card-collapsible mt-3" },
        [
          _c(
            "div",
            {
              staticClass:
                "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
              on: {
                click: function ($event) {
                  return _vm.handleClickHeader("address")
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "title w-90" },
                [
                  _c("b-icon", {
                    staticStyle: { color: "rgba(6, 38, 62, 0.84)" },
                    attrs: { icon: "geo-fill" },
                  }),
                  _c("span", { staticClass: "ml-3" }, [
                    _vm._v(_vm._s(_vm.FormMSG(43, "Address"))),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-content-end w-10" },
                [
                  !_vm.configAccordion["address"].isVisible
                    ? _c(_vm.getIconLucide("ChevronDown"), {
                        tag: "component",
                        attrs: { color: "#06263E", size: 22 },
                      })
                    : _vm._e(),
                  _vm.configAccordion["address"].isVisible
                    ? _c(_vm.getIconLucide("ChevronUp"), {
                        tag: "component",
                        attrs: { color: "#06263E", size: 22 },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _vm.profile.address
            ? _c(
                "b-collapse",
                {
                  attrs: { visible: _vm.configAccordion["address"].isVisible },
                },
                [
                  _c("div", { staticClass: "sub-title" }, [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.FormMSG(1250, "Legal address")) +
                        "\n\t\t\t"
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "content-card-collapsible" },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "6", xl: "6" } },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c("label", { attrs: { for: "country" } }, [
                                    _vm._v(
                                      _vm._s(_vm.FormMSG(1061010, "Country"))
                                    ),
                                  ]),
                                  _c("v-select", {
                                    attrs: {
                                      clearable: false,
                                      options: _vm.countries,
                                      label: "text",
                                      reduce: (option) => option.value,
                                    },
                                    on: { input: _vm.getCountry },
                                    model: {
                                      value: _vm.countryKey,
                                      callback: function ($$v) {
                                        _vm.countryKey = $$v
                                      },
                                      expression: "countryKey",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "6", xl: "6" } },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c("google-auto-complete", {
                                    ref: "google-search-address",
                                    attrs: {
                                      label: _vm.FormMSG(
                                        11402,
                                        "Internet search"
                                      ),
                                      country: _vm.countryCode,
                                    },
                                    on: {
                                      placechanged: (item) =>
                                        _vm.handleAutoComplete(
                                          item,
                                          "legalAddress"
                                        ),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "10", md: "10", lg: "6", xl: "6" } },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c("label", { attrs: { for: "address" } }, [
                                    _vm._v(
                                      _vm._s(this.FormMSG(513100, "Street"))
                                    ),
                                  ]),
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "text",
                                      id: "street",
                                      placeholder: _vm.FormMSG(
                                        1103,
                                        "Enter your street"
                                      ),
                                    },
                                    model: {
                                      value: _vm.profile.address.street,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.profile.address,
                                          "street",
                                          $$v
                                        )
                                      },
                                      expression: "profile.address.street",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "2", md: "2", lg: "3", xl: "3" } },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c("label", { attrs: { for: "number" } }, [
                                    _vm._v(
                                      _vm._s(this.FormMSG(155101, "Number"))
                                    ),
                                  ]),
                                  _c("b-form-input", {
                                    attrs: { type: "text", id: "number" },
                                    model: {
                                      value: _vm.profile.address.number,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.profile.address,
                                          "number",
                                          $$v
                                        )
                                      },
                                      expression: "profile.address.number",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c("label", { attrs: { for: "box" } }, [
                                    _vm._v(_vm._s(this.FormMSG(102123, "Box"))),
                                  ]),
                                  _c("b-form-input", {
                                    attrs: { type: "text", id: "box" },
                                    model: {
                                      value: _vm.profile.address.box,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.profile.address,
                                          "box",
                                          $$v
                                        )
                                      },
                                      expression: "profile.address.box",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c("label", { attrs: { for: "zip" } }, [
                                    _vm._v(
                                      _vm._s(
                                        this.FormMSG(104120, "Postal Code")
                                      )
                                    ),
                                  ]),
                                  _c("b-form-input", {
                                    attrs: { type: "text", id: "zip" },
                                    model: {
                                      value: _vm.profile.address.zip,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.profile.address,
                                          "zip",
                                          $$v
                                        )
                                      },
                                      expression: "profile.address.zip",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c("label", { attrs: { for: "city" } }, [
                                    _vm._v(_vm._s(this.FormMSG(10103, "City"))),
                                  ]),
                                  _c("b-form-input", {
                                    attrs: { type: "text", id: "city" },
                                    model: {
                                      value: _vm.profile.address.city,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.profile.address,
                                          "city",
                                          $$v
                                        )
                                      },
                                      expression: "profile.address.city",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c("label", { attrs: { for: "state" } }, [
                                    _vm._v(
                                      _vm._s(_vm.FormMSG(110205, "State"))
                                    ),
                                  ]),
                                  _c("b-form-input", {
                                    attrs: { type: "text", id: "state" },
                                    model: {
                                      value: _vm.profile.address.state,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.profile.address,
                                          "state",
                                          $$v
                                        )
                                      },
                                      expression: "profile.address.state",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      model: {
                        value: _vm.isShowPostalAddress,
                        callback: function ($$v) {
                          _vm.isShowPostalAddress = $$v
                        },
                        expression: "isShowPostalAddress",
                      },
                    },
                    [
                      _c("div", { staticClass: "sub-title" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(1312, "Postal address")) +
                            "\n\t\t\t\t"
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "content-card-collapsible" },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: "6",
                                    xl: "6",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c(
                                        "label",
                                        { attrs: { for: "country" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(1061010, "Country")
                                            )
                                          ),
                                        ]
                                      ),
                                      _c("v-select", {
                                        attrs: {
                                          clearable: false,
                                          options: _vm.countries,
                                          label: "text",
                                          reduce: (option) => option.value,
                                        },
                                        on: {
                                          input: _vm.getCountryPostalAddress,
                                        },
                                        model: {
                                          value: _vm.countryKeyPostalAddress,
                                          callback: function ($$v) {
                                            _vm.countryKeyPostalAddress = $$v
                                          },
                                          expression: "countryKeyPostalAddress",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: "6",
                                    xl: "6",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c("google-auto-complete", {
                                        ref: "google-search-address",
                                        attrs: {
                                          label: _vm.FormMSG(
                                            11402,
                                            "Internet search"
                                          ),
                                          country: _vm.countryCodePostalAddress,
                                        },
                                        on: {
                                          placechanged: (item) =>
                                            _vm.handleAutoComplete(
                                              item,
                                              "postalAddress"
                                            ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "10",
                                    md: "10",
                                    lg: "6",
                                    xl: "6",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c(
                                        "label",
                                        { attrs: { for: "address" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.FormMSG(513100, "Street")
                                            )
                                          ),
                                        ]
                                      ),
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "text",
                                          id: "street",
                                          placeholder: _vm.FormMSG(
                                            1103,
                                            "Enter your street"
                                          ),
                                        },
                                        model: {
                                          value:
                                            _vm.profile.postalAddress.street,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profile.postalAddress,
                                              "street",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "profile.postalAddress.street",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: { sm: "2", md: "2", lg: "3", xl: "3" },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c(
                                        "label",
                                        { attrs: { for: "number" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.FormMSG(155101, "Number")
                                            )
                                          ),
                                        ]
                                      ),
                                      _c("b-form-input", {
                                        attrs: { type: "text", id: "number" },
                                        model: {
                                          value:
                                            _vm.profile.postalAddress.number,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profile.postalAddress,
                                              "number",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "profile.postalAddress.number",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: "3",
                                    xl: "3",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c("label", { attrs: { for: "box" } }, [
                                        _vm._v(
                                          _vm._s(this.FormMSG(102123, "Box"))
                                        ),
                                      ]),
                                      _c("b-form-input", {
                                        attrs: { type: "text", id: "box" },
                                        model: {
                                          value: _vm.profile.postalAddress.box,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profile.postalAddress,
                                              "box",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "profile.postalAddress.box",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: "3",
                                    xl: "3",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c("label", { attrs: { for: "zip" } }, [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(104120, "Postal Code")
                                          )
                                        ),
                                      ]),
                                      _c("b-form-input", {
                                        attrs: { type: "text", id: "zip" },
                                        model: {
                                          value: _vm.profile.postalAddress.zip,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profile.postalAddress,
                                              "zip",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "profile.postalAddress.zip",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: "3",
                                    xl: "3",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c("label", { attrs: { for: "city" } }, [
                                        _vm._v(
                                          _vm._s(this.FormMSG(10103, "City"))
                                        ),
                                      ]),
                                      _c("b-form-input", {
                                        attrs: { type: "text", id: "city" },
                                        model: {
                                          value: _vm.profile.postalAddress.city,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profile.postalAddress,
                                              "city",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "profile.postalAddress.city",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: "3",
                                    xl: "3",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c("label", { attrs: { for: "state" } }, [
                                        _vm._v(
                                          _vm._s(_vm.FormMSG(110205, "State"))
                                        ),
                                      ]),
                                      _c("b-form-input", {
                                        attrs: { type: "text", id: "state" },
                                        model: {
                                          value:
                                            _vm.profile.postalAddress.state,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profile.postalAddress,
                                              "state",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "profile.postalAddress.state",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("b-row", [
                    _c(
                      "div",
                      { staticStyle: { "margin-left": "28px" } },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass:
                              "d-flex flex-row justify-content-center gap-2 align-items-center w-auto h-auto",
                            staticStyle: {
                              height: "40px",
                              "border-radius": "0.5rem",
                              "background-color": "#fff",
                              cursor: "pointer",
                            },
                            attrs: {
                              size: "sm",
                              variant: _vm.isShowPostalAddress
                                ? "outline-danger"
                                : "outline-secondary",
                            },
                            on: {
                              click: function ($event) {
                                _vm.isShowPostalAddress =
                                  !_vm.isShowPostalAddress
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-row align-items-center justify-content-center btn-address",
                                staticStyle: { color: "#06263e" },
                              },
                              [
                                _vm.isShowPostalAddress
                                  ? _c(
                                      "span",
                                      [
                                        _c(
                                          _vm.getLucideIcon(
                                            _vm.ICONS.X_CLEAR.name
                                          ),
                                          {
                                            tag: "component",
                                            attrs: {
                                              color: _vm.ICONS.X_CLEAR.color,
                                              size: 22,
                                            },
                                          }
                                        ),
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t" +
                                            _vm._s(_vm.FormMSG(1313, "Close")) +
                                            "\n\t\t\t\t\t\t\t"
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "span",
                                      [
                                        _c(_vm.getLucideIcon("PlusCircle"), {
                                          tag: "component",
                                          attrs: { color: "#47C7BF", size: 22 },
                                        }),
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(
                                                1251,
                                                "Add postal address (if different)"
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t"
                                        ),
                                      ],
                                      1
                                    ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "card-collapsible mt-3" },
        [
          _c(
            "div",
            {
              staticClass:
                "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
              on: {
                click: function ($event) {
                  return _vm.handleClickHeader("health")
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "title w-90" },
                [
                  _c(_vm.getLucideIcon("HeartPulse"), {
                    tag: "component",
                    attrs: { color: "rgba(6, 38, 62, 0.84)", size: 22 },
                  }),
                  _c("span", { staticClass: "ml-3" }, [
                    _vm._v(_vm._s(_vm.FormMSG(1252, "Health"))),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-content-end w-10" },
                [
                  !_vm.configAccordion["health"].isVisible
                    ? _c(_vm.getIconLucide("ChevronDown"), {
                        tag: "component",
                        attrs: { color: "#06263E", size: 22 },
                      })
                    : _vm._e(),
                  _vm.configAccordion["health"].isVisible
                    ? _c(_vm.getIconLucide("ChevronUp"), {
                        tag: "component",
                        attrs: { color: "#06263E", size: 22 },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-collapse",
            { attrs: { visible: _vm.configAccordion["health"].isVisible } },
            [
              _c(
                "div",
                { staticClass: "content-card-collapsible" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "4", xl: "4" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(
                                  54,
                                  "Social Security Number"
                                ),
                              },
                            },
                            [
                              _c("b-form-input", {
                                class: {
                                  "is-warning": _vm.isSocSecNumberWarning,
                                },
                                attrs: {
                                  placeholder: _vm.FormMSG(
                                    154,
                                    "Enter your Social Security Number"
                                  ),
                                },
                                on: {
                                  input: _vm.handleSocSecNumberChange,
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                  },
                                },
                                model: {
                                  value: _vm.profile.socSecNumber,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.profile, "socSecNumber", $$v)
                                  },
                                  expression: "profile.socSecNumber",
                                },
                              }),
                              _vm.isSocSecNumberWarning
                                ? _c(
                                    "div",
                                    { staticClass: "warning-feedback" },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              8979997,
                                              "The social security number seems not valid"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "4", xl: "4" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "label",
                                { attrs: { for: "socialSecurityCardXid" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.FormMSG(1255, "Social security card")
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "b-row",
                                { staticClass: "ml-0" },
                                [
                                  _vm.checkFile(
                                    _vm.profile.socialSecurityCardXid
                                  )
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-center align-items-center cursor-pointer",
                                          on: {
                                            click: function ($event) {
                                              _vm.onOpenUrl(
                                                _vm.getImage(
                                                  _vm.profile
                                                    .socialSecurityCardXid,
                                                  "socialSecurityCardXid"
                                                )
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "file-earmark-pdf-fill",
                                              "font-scale": "2",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "b-img",
                                        _vm._b(
                                          {
                                            staticClass: "cursor-pointer",
                                            attrs: {
                                              rounded: "",
                                              alt: "socialSecurityCardXid",
                                              src: _vm.getImage(
                                                _vm.profile
                                                  .socialSecurityCardXid,
                                                "socialSecurityCardXid"
                                              ),
                                              title: _vm.FormMSG(
                                                254,
                                                "View Image"
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showImage(
                                                  _vm.profile
                                                    .socialSecurityCardXid
                                                )
                                              },
                                            },
                                          },
                                          "b-img",
                                          _vm.imgPropSignatureId,
                                          false
                                        )
                                      ),
                                  _vm.profile.socialSecurityCardXid &&
                                  _vm.profile.socialSecurityCardXid.length > 0
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn-transparent pl-0 ml-2",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              $event.stopPropagation()
                                              return _vm.deleteImg(
                                                "socialSecurityCardXid"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(_vm.getIconLucide("Trash2"), {
                                            tag: "component",
                                            attrs: {
                                              size: 24,
                                              "stroke-width": 1.5,
                                              color: "#ea4e2c",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "4", xl: "4" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "label",
                                { attrs: { for: "inscuranceCertificate" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.FormMSG(1256, "Insurance certificate")
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "b-row",
                                { staticClass: "ml-0" },
                                [
                                  _vm.checkFile(
                                    _vm.profile.insuranceCertificateXid
                                  )
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-center align-items-center cursor-pointer",
                                          on: {
                                            click: function ($event) {
                                              _vm.onOpenUrl(
                                                _vm.getImage(
                                                  _vm.profile
                                                    .insuranceCertificateXid,
                                                  "insuranceCertificateXid"
                                                )
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "file-earmark-pdf-fill",
                                              "font-scale": "2",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "b-img",
                                        _vm._b(
                                          {
                                            staticClass: "cursor-pointer",
                                            attrs: {
                                              rounded: "",
                                              alt: "inscuranceCertificate",
                                              src: _vm.getImage(
                                                _vm.profile
                                                  .insuranceCertificateXid,
                                                "insuranceCertificateXid"
                                              ),
                                              title: _vm.FormMSG(
                                                254,
                                                "View Image"
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showImage(
                                                  _vm.profile
                                                    .insuranceCertificateXid
                                                )
                                              },
                                            },
                                          },
                                          "b-img",
                                          _vm.imgPropSignatureId,
                                          false
                                        )
                                      ),
                                  _vm.profile.insuranceCertificateXid &&
                                  _vm.profile.insuranceCertificateXid.length > 0
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn-transparent pl-0 ml-2",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              $event.stopPropagation()
                                              return _vm.deleteImg(
                                                "insuranceCertificateXid"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(_vm.getIconLucide("Trash2"), {
                                            tag: "component",
                                            attrs: {
                                              size: 24,
                                              "stroke-width": 1.5,
                                              color: "#ea4e2c",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "4", xl: "4" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "label",
                                { attrs: { for: "socialSecurityCenter" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      this.FormMSG(
                                        1700,
                                        "Social security center"
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  id: "socialSecurityCenter",
                                  placeholder: this.FormMSG(
                                    1258,
                                    "Enter your social security center"
                                  ),
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                  },
                                },
                                model: {
                                  value: _vm.profile.socialSecurityCenter,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.profile,
                                      "socialSecurityCenter",
                                      $$v
                                    )
                                  },
                                  expression: "profile.socialSecurityCenter",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "8", xl: "8" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(
                                  1259,
                                  "Social security center address"
                                ),
                              },
                            },
                            [
                              _c("v-address", {
                                attrs: {
                                  params: {
                                    title: "Social security center address",
                                  },
                                  "address-id":
                                    _vm.profile.socialSecurityCenterAddressId,
                                },
                                on: {
                                  change: _vm.handleDynamicAddressChange,
                                  delete: _vm.handleDynamicAddressDelete,
                                  "address-maplink-change":
                                    _vm.handleAddressMapLinkChange,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", [
                                _vm._v(
                                  _vm._s(
                                    _vm.FormMSG(1260, "Last medical visit date")
                                  )
                                ),
                              ]),
                              _c("b-form-input", {
                                attrs: {
                                  type: "date",
                                  value: _vm.displayDateLastMedicalVisit,
                                  placeholder: _vm.FormMSG(1295, "DD/MM/YYYY"),
                                },
                                on: {
                                  input: (e) =>
                                    _vm.updateDate(e, "lastMedicalVisit"),
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", [
                                _vm._v(
                                  _vm._s(
                                    _vm.FormMSG(1262, "Medical visit validity")
                                  )
                                ),
                              ]),
                              _c("b-form-input", {
                                attrs: {
                                  type: "date",
                                  value: _vm.displayDateMedicalVisitValidity,
                                  placeholder: _vm.FormMSG(1295, "DD/MM/YYYY"),
                                },
                                on: {
                                  input: (e) =>
                                    _vm.updateDate(e, "medicalVisitValidity"),
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "label",
                                { attrs: { for: "medicalVisitDocument" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.FormMSG(
                                        1264,
                                        "Medical visit document"
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "b-row",
                                { staticClass: "ml-0" },
                                [
                                  _vm.checkFile(
                                    _vm.profile.medicalVisitDocumentXid
                                  )
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-center align-items-center cursor-pointer",
                                          on: {
                                            click: function ($event) {
                                              _vm.onOpenUrl(
                                                _vm.getImage(
                                                  _vm.profile
                                                    .medicalVisitDocumentXid,
                                                  "medicalVisitDocumentXid"
                                                )
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "file-earmark-pdf-fill",
                                              "font-scale": "2",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "b-img",
                                        _vm._b(
                                          {
                                            staticClass: "cursor-pointer",
                                            attrs: {
                                              rounded: "",
                                              alt: "medicalVisitDocument",
                                              src: _vm.getImage(
                                                _vm.profile
                                                  .medicalVisitDocumentXid,
                                                "medicalVisitDocumentXid"
                                              ),
                                              title: _vm.FormMSG(
                                                254,
                                                "View Image"
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showImage(
                                                  _vm.profile
                                                    .medicalVisitDocumentXid
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.profile
                                                  .medicalVisitDocumentXid,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.profile,
                                                  "medicalVisitDocumentXid",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "profile.medicalVisitDocumentXid",
                                            },
                                          },
                                          "b-img",
                                          _vm.imgPropSignatureId,
                                          false
                                        )
                                      ),
                                  _vm.profile.medicalVisitDocumentXid &&
                                  _vm.profile.medicalVisitDocumentXid.length > 0
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn-transparent pl-0 ml-2",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              $event.stopPropagation()
                                              return _vm.deleteImg(
                                                "medicalVisitDocumentXid"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(_vm.getIconLucide("Trash2"), {
                                            tag: "component",
                                            attrs: {
                                              size: 24,
                                              "stroke-width": 1.5,
                                              color: "#ea4e2c",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t \n\t\t\t\t\t\t\t\t"
                                  ),
                                  _vm.isDeterAction &&
                                  _vm.profile.medicalVisitDocumentXid.length ===
                                    0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center",
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName:
                                                    "v-b-tooltip.hover.bottom.html",
                                                  value: `Medical visit document empty`,
                                                  expression:
                                                    "`Medical visit document empty`",
                                                  modifiers: {
                                                    hover: true,
                                                    bottom: true,
                                                    html: true,
                                                  },
                                                },
                                              ],
                                              staticClass:
                                                "btn bg-transparent border-0",
                                              attrs: { size: "sm" },
                                            },
                                            [
                                              _c(
                                                _vm.getIconLucide(
                                                  _vm.ICONS.ALERT_TRIANGLE.name
                                                ),
                                                {
                                                  tag: "component",
                                                  attrs: {
                                                    color: "#ffc107",
                                                    size: 30,
                                                  },
                                                }
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "d-flex justify-content-start",
                          attrs: { sm: "12", md: "12", lg: "3", xl: "3" },
                        },
                        [
                          _c(
                            "b-form-group",
                            { staticClass: "text-start" },
                            [
                              _c("label", [
                                _vm._v(
                                  _vm._s(
                                    _vm.FormMSG(
                                      1265,
                                      "Worker with a disability"
                                    )
                                  )
                                ),
                              ]),
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "d-flex justify-content-start",
                                  attrs: { size: "lg", switch: "" },
                                  model: {
                                    value: _vm.profile.disabled,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.profile, "disabled", $$v)
                                    },
                                    expression: "profile.disabled",
                                  },
                                },
                                [
                                  _c("span", { staticClass: "text-redired" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.profile.disabled
                                          ? _vm.FormMSG(1276, "Yes")
                                          : _vm.FormMSG(1277, "No")
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.profile.disabled
                        ? _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.FormMSG(
                                          1266,
                                          "Disability notification date"
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "date",
                                      value:
                                        _vm.displayDateDisabilityNotification,
                                      placeholder: _vm.FormMSG(
                                        1295,
                                        "DD/MM/YYYY"
                                      ),
                                    },
                                    on: {
                                      input: (e) =>
                                        _vm.updateDate(
                                          e,
                                          "disabilityNotification"
                                        ),
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.profile.disabled
                        ? _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c("label", { attrs: { for: "type" } }, [
                                    _vm._v(_vm._s(this.FormMSG(1268, "Type"))),
                                  ]),
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "text",
                                      id: "type",
                                      placeholder: this.FormMSG(
                                        1269,
                                        "Enter type"
                                      ),
                                    },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                      },
                                    },
                                    model: {
                                      value: _vm.profile.disabilityType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.profile,
                                          "disabilityType",
                                          $$v
                                        )
                                      },
                                      expression: "profile.disabilityType",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.profile.disabled
                        ? _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c("label", { attrs: { for: "status" } }, [
                                    _vm._v(
                                      _vm._s(this.FormMSG(1270, "Status"))
                                    ),
                                  ]),
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "text",
                                      id: "status",
                                      placeholder: this.FormMSG(
                                        1271,
                                        "Enter status"
                                      ),
                                    },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                      },
                                    },
                                    model: {
                                      value: _vm.profile.disabilityStatus,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.profile,
                                          "disabilityStatus",
                                          $$v
                                        )
                                      },
                                      expression: "profile.disabilityStatus",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _vm.profile.disabled
                        ? _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(1272, "Invalidity"),
                                  },
                                },
                                [
                                  _c(
                                    "b-form-select",
                                    {
                                      model: {
                                        value: _vm.profile.invalidity,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.profile,
                                            "invalidity",
                                            $$v
                                          )
                                        },
                                        expression: "profile.invalidity",
                                      },
                                    },
                                    _vm._l(
                                      _vm.menuInvalidity,
                                      function (gender, index) {
                                        return _c(
                                          "b-form-select-option",
                                          {
                                            key: index,
                                            attrs: { value: gender.value },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(gender.text) +
                                                "\n\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.profile.disabled
                        ? _c(
                            "b-col",
                            {
                              staticClass: "d-flex align-items-center mt-2",
                              attrs: { sm: "12", md: "12", lg: "3", xl: "3" },
                            },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "d-flex align-items-center",
                                  attrs: { size: "lg", value: true },
                                  model: {
                                    value: _vm.profile.rqth,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.profile, "rqth", $$v)
                                    },
                                    expression: "profile.rqth",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "mt-1" }, [
                                    _vm._v(_vm._s(_vm.FormMSG(1273, "RQTH"))),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.profile.disabled
                        ? _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(
                                      1274,
                                      "Invalidity percentage"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "limit-100",
                                            rawName: "v-limit-100",
                                          },
                                        ],
                                        attrs: {
                                          type: "number",
                                          step: "1",
                                          min: "0",
                                          max: "100",
                                        },
                                        on: {
                                          keydown: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            $event.preventDefault()
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.profile.invalidityPourcentage,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profile,
                                              "invalidityPourcentage",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "profile.invalidityPourcentage",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-input-group-text",
                                            {
                                              staticStyle: {
                                                "background-color": "#00a09c",
                                              },
                                            },
                                            [
                                              _c(_vm.getIconLucide("Percent"), {
                                                tag: "component",
                                                attrs: {
                                                  color: "#ffffff",
                                                  size: 16,
                                                  "stroke-width": 3.5,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", [
                                _vm._v(
                                  _vm._s(_vm.FormMSG(25, "Have Allergies?"))
                                ),
                              ]),
                              _c("b-form-checkbox", {
                                attrs: { size: "lg", switch: "" },
                                on: { change: _vm.handleChangeHaveAllergies },
                                model: {
                                  value: _vm.haveAllergies,
                                  callback: function ($$v) {
                                    _vm.haveAllergies = $$v
                                  },
                                  expression: "haveAllergies",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", [
                                _vm._v(
                                  _vm._s(_vm.FormMSG(26, "Allergies details"))
                                ),
                              ]),
                              _c("b-form-textarea", {
                                attrs: {
                                  type: "textarea",
                                  rows: "2",
                                  placeholder: _vm.FormMSG(
                                    6,
                                    "Enter allergies details..."
                                  ),
                                  autocomplete: "off",
                                  disabled: _vm.haveAllergies === false,
                                },
                                model: {
                                  value: _vm.profile.allergies,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.profile, "allergies", $$v)
                                  },
                                  expression: "profile.allergies",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "card-collapsible mt-3" },
        [
          _c(
            "div",
            {
              staticClass:
                "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
              on: {
                click: function ($event) {
                  return _vm.handleClickHeader("family")
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "title w-90" },
                [
                  _c("b-icon", {
                    staticStyle: { color: "rgba(6, 38, 62, 0.84)" },
                    attrs: { icon: "people-fill" },
                  }),
                  _c("span", { staticClass: "ml-3" }, [
                    _vm._v(_vm._s(_vm.FormMSG(52, "Family"))),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-content-end w-10" },
                [
                  !_vm.configAccordion["family"].isVisible
                    ? _c(_vm.getIconLucide("ChevronDown"), {
                        tag: "component",
                        attrs: { color: "#06263E", size: 22 },
                      })
                    : _vm._e(),
                  _vm.configAccordion["family"].isVisible
                    ? _c(_vm.getIconLucide("ChevronUp"), {
                        tag: "component",
                        attrs: { color: "#06263E", size: 22 },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-collapse",
            { attrs: { visible: _vm.configAccordion["family"].isVisible } },
            [
              _c("div", { staticClass: "sub-title" }, [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.FormMSG(53, "Family composition")) +
                    "\n\t\t\t"
                ),
              ]),
              _c(
                "div",
                { staticClass: "content-card-collapsible" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "4", xl: "4" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(54, "Civil status"),
                                "label-for": "optionsForCivilStatus",
                              },
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  options: _vm.optionsForCivilStatus,
                                  id: "optionsForCivilStatus",
                                },
                                model: {
                                  value: _vm.profile.civilStatus,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.profile, "civilStatus", $$v)
                                  },
                                  expression: "profile.civilStatus",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.profile.civilStatus === 3
                        ? _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "4", xl: "4" } },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.FormMSG(55, "Spouse first name")
                                      )
                                    ),
                                  ]),
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.FormMSG(
                                        56,
                                        "Enter your Spouse first name "
                                      ),
                                    },
                                    model: {
                                      value: _vm.profile.spouseFirstName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.profile,
                                          "spouseFirstName",
                                          $$v
                                        )
                                      },
                                      expression: "profile.spouseFirstName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.profile.civilStatus === 3
                        ? _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "4", xl: "4" } },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(_vm.FormMSG(57, "Spouse name"))
                                    ),
                                  ]),
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.FormMSG(
                                        58,
                                        "Enter your Spouse name "
                                      ),
                                    },
                                    model: {
                                      value: _vm.profile.spouseName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.profile, "spouseName", $$v)
                                      },
                                      expression: "profile.spouseName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.profile.civilStatus === 3
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "4", xl: "4" } },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.FormMSG(59, "Spouse birth date")
                                      )
                                    ),
                                  ]),
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "date",
                                      value: _vm.displaySpouseBirthDate,
                                      placeholder: _vm.FormMSG(
                                        60,
                                        "Enter your Spouse birth date "
                                      ),
                                    },
                                    on: { input: _vm.updateSpouseBirthDate },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "4", xl: "4" } },
                            [
                              _c(
                                "b-form-group",
                                { staticClass: "text-left" },
                                [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.FormMSG(61, "Spouse has salary")
                                      )
                                    ),
                                  ]),
                                  _c("b-form-checkbox", {
                                    attrs: { size: "lg", switch: "" },
                                    model: {
                                      value: _vm.profile.spouseHasSalary,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.profile,
                                          "spouseHasSalary",
                                          $$v
                                        )
                                      },
                                      expression: "profile.spouseHasSalary",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "4", xl: "4" } },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.FormMSG(62, "Spouse profession")
                                      )
                                    ),
                                  ]),
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.FormMSG(
                                        63,
                                        "Enter your Spouse profession "
                                      ),
                                    },
                                    model: {
                                      value: _vm.profile.spouseProfession,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.profile,
                                          "spouseProfession",
                                          $$v
                                        )
                                      },
                                      expression: "profile.spouseProfession",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(
                                  64,
                                  "Number of dependent child"
                                ),
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: { type: "number", placeholder: "0" },
                                model: {
                                  value: _vm.profile.numberOfDependentChild,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.profile,
                                      "numberOfDependentChild",
                                      $$v
                                    )
                                  },
                                  expression: "profile.numberOfDependentChild",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(
                                  65,
                                  "Number of disabled child"
                                ),
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: { type: "number", placeholder: "0" },
                                model: {
                                  value: _vm.profile.numberOfDisabledChild,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.profile,
                                      "numberOfDisabledChild",
                                      $$v
                                    )
                                  },
                                  expression: "profile.numberOfDisabledChild",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(
                                  66,
                                  "Number of dependent person"
                                ),
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: { type: "number", placeholder: "0" },
                                model: {
                                  value: _vm.profile.numberOfDependentPerson,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.profile,
                                      "numberOfDependentPerson",
                                      $$v
                                    )
                                  },
                                  expression: "profile.numberOfDependentPerson",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(
                                  67,
                                  "Number of disabled person"
                                ),
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: { type: "number", placeholder: "0" },
                                model: {
                                  value: _vm.profile.numberOfDisabledPerson,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.profile,
                                      "numberOfDisabledPerson",
                                      $$v
                                    )
                                  },
                                  expression: "profile.numberOfDisabledPerson",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "divider" }),
              _c("div", { staticClass: "sub-title" }, [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.FormMSG(68, "Emergency contact")) +
                    "\n\t\t\t"
                ),
              ]),
              _c(
                "div",
                { staticClass: "content-card-collapsible" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "6", xl: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(69, "Person to contact"),
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.FormMSG(
                                    70,
                                    "Enter person to contact"
                                  ),
                                },
                                model: {
                                  value: _vm.profile.personToContact,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.profile,
                                      "personToContact",
                                      $$v
                                    )
                                  },
                                  expression: "profile.personToContact",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "6", xl: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(
                                  71,
                                  "Person to contact phone"
                                ),
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.FormMSG(
                                    72,
                                    "Enter person to contact phone"
                                  ),
                                },
                                model: {
                                  value: _vm.profile.personToContactPhone,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.profile,
                                      "personToContactPhone",
                                      $$v
                                    )
                                  },
                                  expression: "profile.personToContactPhone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm.profile.vehicule
        ? _c(
            "div",
            { staticClass: "card-collapsible mt-3" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
                  on: {
                    click: function ($event) {
                      return _vm.handleClickHeader("personal-vehicle")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "title w-90" }, [
                    _c("span", [
                      _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            width: "1em",
                            height: "1.15em",
                            fill: "rgba(6, 38, 62, 0.84)",
                            viewBox: "0 0 16 16",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM3 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM2.906 5.189a.51.51 0 0 0 .497.731c.91-.073 3.35-.17 4.597-.17 1.247 0 3.688.097 4.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 11.691 3H4.309a.5.5 0 0 0-.447.276L2.906 5.19Z",
                            },
                          }),
                        ]
                      ),
                    ]),
                    _c("span", { staticClass: "ml-3" }, [
                      _vm._v(_vm._s(_vm.FormMSG(1226, "Personal vehicle"))),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-end w-10" },
                    [
                      !_vm.configAccordion["personal-vehicle"].isVisible
                        ? _c(_vm.getIconLucide("ChevronDown"), {
                            tag: "component",
                            attrs: { color: "#06263E", size: 22 },
                          })
                        : _vm._e(),
                      _vm.configAccordion["personal-vehicle"].isVisible
                        ? _c(_vm.getIconLucide("ChevronUp"), {
                            tag: "component",
                            attrs: { color: "#06263E", size: 22 },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "b-collapse",
                {
                  attrs: {
                    visible: _vm.configAccordion["personal-vehicle"].isVisible,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "content-card-collapsible" },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: _vm.FormMSG(1228, "Name") } },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.FormMSG(
                                        1229,
                                        "Vehicle name ..."
                                      ),
                                    },
                                    model: {
                                      value: _vm.profile.vehicule.name,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.profile.vehicule,
                                          "name",
                                          $$v
                                        )
                                      },
                                      expression: "profile.vehicule.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: { label: _vm.FormMSG(1230, "Brand") },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.FormMSG(
                                        1231,
                                        "Vehicle brand ..."
                                      ),
                                    },
                                    model: {
                                      value: _vm.profile.vehicule.brand,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.profile.vehicule,
                                          "brand",
                                          $$v
                                        )
                                      },
                                      expression: "profile.vehicule.brand",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: { label: _vm.FormMSG(1232, "Model") },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.FormMSG(
                                        1233,
                                        "Vehicle model ..."
                                      ),
                                    },
                                    model: {
                                      value: _vm.profile.vehicule.model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.profile.vehicule,
                                          "model",
                                          $$v
                                        )
                                      },
                                      expression: "profile.vehicule.model",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(1234, "Number plate"),
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.FormMSG(
                                        1235,
                                        "Your number plate ..."
                                      ),
                                    },
                                    model: {
                                      value: _vm.profile.vehicule.numberPlate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.profile.vehicule,
                                          "numberPlate",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "profile.vehicule.numberPlate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "4", xl: "4" } },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: _vm.FormMSG(1236, "Type") } },
                                [
                                  _c("b-form-select", {
                                    attrs: { options: _vm.vehicleTypes },
                                    model: {
                                      value: _vm.profile.vehicule.type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.profile.vehicule,
                                          "type",
                                          $$v
                                        )
                                      },
                                      expression: "profile.vehicule.type",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "4", xl: "4" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: { label: _vm.FormMSG(1237, "Energy") },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: { options: _vm.vehicleEnergies },
                                    model: {
                                      value: _vm.profile.vehicule.energy,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.profile.vehicule,
                                          "energy",
                                          $$v
                                        )
                                      },
                                      expression: "profile.vehicule.energy",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "4", xl: "4" } },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(_vm.FormMSG(1238, "Kg CO2 per")) +
                                        " " +
                                        _vm._s(_vm.distanceUnit)
                                    ),
                                  ]),
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "number",
                                      step: "0.01",
                                      min: "0",
                                      placeholder: "0",
                                    },
                                    model: {
                                      value: _vm.profile.vehicule.kgCoTwoPerKm,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.profile.vehicule,
                                          "kgCoTwoPerKm",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "profile.vehicule.kgCoTwoPerKm",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isPme && _vm.profile.vehicule
        ? _c(
            "div",
            { staticClass: "card-collapsible mt-3" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
                  on: {
                    click: function ($event) {
                      return _vm.handleClickHeader("personal-vehicle")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "title w-90" }, [
                    _c("span", [
                      _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            width: "1em",
                            height: "1.15em",
                            fill: "rgba(6, 38, 62, 0.84)",
                            viewBox: "0 0 16 16",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM3 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM2.906 5.189a.51.51 0 0 0 .497.731c.91-.073 3.35-.17 4.597-.17 1.247 0 3.688.097 4.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 11.691 3H4.309a.5.5 0 0 0-.447.276L2.906 5.19Z",
                            },
                          }),
                        ]
                      ),
                    ]),
                    _c("span", { staticClass: "ml-3" }, [
                      _vm._v(_vm._s(_vm.FormMSG(1226, "Personal vehicle"))),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-end w-10" },
                    [
                      !_vm.configAccordion["personal-vehicle"].isVisible
                        ? _c(_vm.getIconLucide("ChevronDown"), {
                            tag: "component",
                            attrs: { color: "#06263E", size: 22 },
                          })
                        : _vm._e(),
                      _vm.configAccordion["personal-vehicle"].isVisible
                        ? _c(_vm.getIconLucide("ChevronUp"), {
                            tag: "component",
                            attrs: { color: "#06263E", size: 22 },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "b-collapse",
                {
                  attrs: {
                    visible: _vm.configAccordion["personal-vehicle"].isVisible,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "content-card-collapsible" },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: _vm.FormMSG(1228, "Name") } },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.FormMSG(
                                        1229,
                                        "Vehicle name ..."
                                      ),
                                    },
                                    model: {
                                      value: _vm.profile.vehicule.name,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.profile.vehicule,
                                          "name",
                                          $$v
                                        )
                                      },
                                      expression: "profile.vehicule.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: { label: _vm.FormMSG(1230, "Brand") },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.FormMSG(
                                        1231,
                                        "Vehicle brand ..."
                                      ),
                                    },
                                    model: {
                                      value: _vm.profile.vehicule.brand,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.profile.vehicule,
                                          "brand",
                                          $$v
                                        )
                                      },
                                      expression: "profile.vehicule.brand",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: { label: _vm.FormMSG(1232, "Model") },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.FormMSG(
                                        1233,
                                        "Vehicle model ..."
                                      ),
                                    },
                                    model: {
                                      value: _vm.profile.vehicule.model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.profile.vehicule,
                                          "model",
                                          $$v
                                        )
                                      },
                                      expression: "profile.vehicule.model",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(1234, "Number plate"),
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.FormMSG(
                                        1235,
                                        "Your number plate ..."
                                      ),
                                    },
                                    model: {
                                      value: _vm.profile.vehicule.numberPlate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.profile.vehicule,
                                          "numberPlate",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "profile.vehicule.numberPlate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "4", xl: "4" } },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: _vm.FormMSG(1236, "Type") } },
                                [
                                  _c("b-form-select", {
                                    attrs: { options: _vm.vehicleTypes },
                                    model: {
                                      value: _vm.profile.vehicule.type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.profile.vehicule,
                                          "type",
                                          $$v
                                        )
                                      },
                                      expression: "profile.vehicule.type",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "4", xl: "4" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: { label: _vm.FormMSG(1237, "Energy") },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: { options: _vm.vehicleEnergies },
                                    model: {
                                      value: _vm.profile.vehicule.energy,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.profile.vehicule,
                                          "energy",
                                          $$v
                                        )
                                      },
                                      expression: "profile.vehicule.energy",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "4", xl: "4" } },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(_vm.FormMSG(1238, "Kg CO2 per")) +
                                        " " +
                                        _vm._s(_vm.distanceUnit)
                                    ),
                                  ]),
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "number",
                                      step: "0.01",
                                      min: "0",
                                      placeholder: "0",
                                    },
                                    model: {
                                      value: _vm.profile.vehicule.kgCoTwoPerKm,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.profile.vehicule,
                                          "kgCoTwoPerKm",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "profile.vehicule.kgCoTwoPerKm",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "card-collapsible mt-3" },
        [
          _c(
            "div",
            {
              staticClass:
                "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
              on: {
                click: function ($event) {
                  return _vm.handleClickHeader("other")
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "title w-90" },
                [
                  _c("b-icon", {
                    staticStyle: { color: "rgba(6, 38, 62, 0.84)" },
                    attrs: { icon: "list" },
                  }),
                  _c("span", { staticClass: "ml-3" }, [
                    _vm._v(_vm._s(_vm.FormMSG(73, "Other"))),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-content-end w-10" },
                [
                  !_vm.configAccordion["other"].isVisible
                    ? _c(_vm.getIconLucide("ChevronDown"), {
                        tag: "component",
                        attrs: { color: "#06263E", size: 22 },
                      })
                    : _vm._e(),
                  _vm.configAccordion["other"].isVisible
                    ? _c(_vm.getIconLucide("ChevronUp"), {
                        tag: "component",
                        attrs: { color: "#06263E", size: 22 },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-collapse",
            { attrs: { visible: _vm.configAccordion["other"].isVisible } },
            [
              _c("div", { staticClass: "sub-title" }, [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.FormMSG(17, "Privacy options")) +
                    "\n\t\t\t"
                ),
              ]),
              _c(
                "div",
                { staticClass: "content-card-collapsible" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "pj-cb pb-1",
                              attrs: { size: "lg" },
                              model: {
                                value: _vm.profile.showMyEmailInCrew,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.profile,
                                    "showMyEmailInCrew",
                                    $$v
                                  )
                                },
                                expression: "profile.showMyEmailInCrew",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.FormMSG(18, "Show my email in crew list")
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "pj-cb pb-1",
                              attrs: { size: "lg" },
                              model: {
                                value: _vm.profile.showMyPhoneInCrew,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.profile,
                                    "showMyPhoneInCrew",
                                    $$v
                                  )
                                },
                                expression: "profile.showMyPhoneInCrew",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.FormMSG(19, "Show my phone in crew list")
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "divider" }),
              _c("div", { staticClass: "sub-title" }, [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.FormMSG(1280, "Loyalty cards")) +
                    "\n\t\t\t"
                ),
              ]),
              _c(
                "div",
                { staticClass: "content-card-collapsible" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("div", [
                                        _c("span", [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  1281,
                                                  "SNCF loyalty card"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t\t"
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "text-fin" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    1282,
                                                    "(Type + number)"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.FormMSG(
                                    1283,
                                    "Enter your sncf loyalty card"
                                  ),
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                  },
                                },
                                model: {
                                  value: _vm.profile.trainLoyaltyCard,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.profile,
                                      "trainLoyaltyCard",
                                      $$v
                                    )
                                  },
                                  expression: "profile.trainLoyaltyCard",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("div", [
                                        _c("span", [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  1284,
                                                  "SNCF discount card"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t\t"
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "text-fin" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    1282,
                                                    "(Type + number)"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.FormMSG(
                                    1285,
                                    "Enter your sncf discount card"
                                  ),
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                  },
                                },
                                model: {
                                  value: _vm.profile.trainDiscountCard,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.profile,
                                      "trainDiscountCard",
                                      $$v
                                    )
                                  },
                                  expression: "profile.trainDiscountCard",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("div", [
                                        _c("span", [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  1286,
                                                  "Flying blue card"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t\t"
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "text-fin" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(1287, "(Number)")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.FormMSG(
                                    1288,
                                    "Enter your flying blue card"
                                  ),
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                  },
                                },
                                model: {
                                  value: _vm.profile.flyingLoyaltyCard,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.profile,
                                      "flyingLoyaltyCard",
                                      $$v
                                    )
                                  },
                                  expression: "profile.flyingLoyaltyCard",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("div", [
                                        _c("span", [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  1289,
                                                  "Other airline card"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t\t"
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "text-fin" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(1287, "(Number)")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.FormMSG(
                                    1288,
                                    "Enter your other airline card"
                                  ),
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                  },
                                },
                                model: {
                                  value: _vm.profile.otherAirlineCard,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.profile,
                                      "otherAirlineCard",
                                      $$v
                                    )
                                  },
                                  expression: "profile.otherAirlineCard",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "divider" }),
              _c("div", { staticClass: "sub-title" }, [
                _vm._v(
                  "\n\t\t\t\t" + _vm._s(_vm.FormMSG(1290, "Foreign worker"))
                ),
                _c("span", { staticClass: "text-fin" }, [
                  _vm._v(
                    _vm._s(
                      _vm.FormMSG(
                        1291,
                        "(to fill only if you're a foraign worker)"
                      )
                    )
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "content-card-collapsible" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(
                                  1292,
                                  "Residence permit number"
                                ),
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.FormMSG(
                                    1293,
                                    "Enter your residence permit number"
                                  ),
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                  },
                                },
                                model: {
                                  value: _vm.profile.residencePermitNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.profile,
                                      "residencePermitNumber",
                                      $$v
                                    )
                                  },
                                  expression: "profile.residencePermitNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(
                                  1294,
                                  "Residence permit validity end"
                                ),
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "date",
                                  value:
                                    _vm.displayDateResidencePermitEndValidity,
                                  placeholder: _vm.FormMSG(1295, "DD/MM/YYYY"),
                                },
                                on: {
                                  input: (e) =>
                                    _vm.updateDate(
                                      e,
                                      "residencePermitEndValidity"
                                    ),
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "label",
                                { attrs: { for: "residentPermitXid" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.FormMSG(1296, "Resident permit"))
                                  ),
                                ]
                              ),
                              _c(
                                "b-row",
                                { staticClass: "ml-0" },
                                [
                                  _vm.checkFile(_vm.profile.residentPermitXid)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-center align-items-center cursor-pointer",
                                          on: {
                                            click: function ($event) {
                                              _vm.onOpenUrl(
                                                _vm.getImage(
                                                  _vm.profile.residentPermitXid,
                                                  "residentPermitXid"
                                                )
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "file-earmark-pdf-fill",
                                              "font-scale": "2",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "b-img",
                                        _vm._b(
                                          {
                                            staticClass: "cursor-pointer",
                                            attrs: {
                                              rounded: "",
                                              alt: "residentPermitXid",
                                              src: _vm.getImage(
                                                _vm.profile.residentPermitXid,
                                                "residentPermitXid"
                                              ),
                                              title: _vm.FormMSG(
                                                254,
                                                "View Image"
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showImage(
                                                  _vm.profile.residentPermitXid
                                                )
                                              },
                                            },
                                          },
                                          "b-img",
                                          _vm.imgPropSignatureId,
                                          false
                                        )
                                      ),
                                  _vm.profile.residentPermitXid &&
                                  _vm.profile.residentPermitXid.length > 0
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn-transparent pl-0 ml-2",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              $event.stopPropagation()
                                              return _vm.deleteImg(
                                                "residentPermitXid"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(_vm.getIconLucide("Trash2"), {
                                            tag: "component",
                                            attrs: {
                                              size: 24,
                                              "stroke-width": 1.5,
                                              color: "#ea4e2c",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", { attrs: { for: "workCardXid" } }, [
                                _vm._v(_vm._s(_vm.FormMSG(1297, "Work card"))),
                              ]),
                              _c(
                                "b-row",
                                { staticClass: "ml-0" },
                                [
                                  _vm.checkFile(_vm.profile.workCardXid)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-center align-items-center cursor-pointer",
                                          on: {
                                            click: function ($event) {
                                              _vm.onOpenUrl(
                                                _vm.getImage(
                                                  _vm.profile.workCardXid,
                                                  "workCardXid"
                                                )
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "file-earmark-pdf-fill",
                                              "font-scale": "2",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "b-img",
                                        _vm._b(
                                          {
                                            staticClass: "cursor-pointer",
                                            attrs: {
                                              rounded: "",
                                              alt: "workCardXid",
                                              src: _vm.getImage(
                                                _vm.profile.workCardXid,
                                                "workCardXid"
                                              ),
                                              title: _vm.FormMSG(
                                                254,
                                                "View Image"
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showImage(
                                                  _vm.profile.workCardXid
                                                )
                                              },
                                            },
                                          },
                                          "b-img",
                                          _vm.imgPropSignatureId,
                                          false
                                        )
                                      ),
                                  _vm.profile.workCardXid &&
                                  _vm.profile.workCardXid.length > 0
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn-transparent pl-0 ml-2",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              $event.stopPropagation()
                                              return _vm.deleteImg(
                                                "workCardXid"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(_vm.getIconLucide("Trash2"), {
                                            tag: "component",
                                            attrs: {
                                              size: 24,
                                              "stroke-width": 1.5,
                                              color: "#ea4e2c",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "divider" }),
              _c("div", { staticClass: "sub-title" }, [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.FormMSG(24, "Miscellanous")) +
                    "\n\t\t\t"
                ),
              ]),
              _c(
                "div",
                { staticClass: "content-card-collapsible" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: { label: _vm.FormMSG(1298, "Pseudonym") },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.FormMSG(
                                    1299,
                                    "Enter your pseudonym"
                                  ),
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                  },
                                },
                                model: {
                                  value: _vm.profile.pseudonym,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.profile, "pseudonym", $$v)
                                  },
                                  expression: "profile.pseudonym",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "4", xl: "4" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(
                                  1300,
                                  "Generic mention professional association"
                                ),
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.FormMSG(
                                    1301,
                                    "Enter your generic mention ..."
                                  ),
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                  },
                                },
                                model: {
                                  value: _vm.profile.professionalAssociation,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.profile,
                                      "professionalAssociation",
                                      $$v
                                    )
                                  },
                                  expression: "profile.professionalAssociation",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "divider" }),
              _c("div", { staticClass: "sub-title" }, [
                _vm._v(_vm._s(_vm.FormMSG(1705, "Retirement"))),
              ]),
              _c(
                "div",
                { staticClass: "content-card-collapsible" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "d-flex justify-content-start",
                          attrs: { sm: "12", md: "12", lg: "3", xl: "3" },
                        },
                        [
                          _c(
                            "b-form-group",
                            { staticClass: "text-center" },
                            [
                              _c("label", [
                                _vm._v(_vm._s(_vm.FormMSG(1275, "Retired ?"))),
                              ]),
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "d-flex justify-content-start",
                                  attrs: {
                                    size: "lg",
                                    value: true,
                                    switch: "",
                                  },
                                  model: {
                                    value: _vm.profile.retired,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.profile, "retired", $$v)
                                    },
                                    expression: "profile.retired",
                                  },
                                },
                                [
                                  _c("span", { staticClass: "text-redired" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.profile.retired
                                          ? _vm.FormMSG(1276, "Yes")
                                          : _vm.FormMSG(1277, "No")
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.profile.retired
                        ? _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.FormMSG(1278, "Retirement date")
                                      )
                                    ),
                                  ]),
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "date",
                                      value: _vm.displayDateRetirementDate,
                                      placeholder: _vm.FormMSG(
                                        1295,
                                        "DD/MM/YYYY"
                                      ),
                                    },
                                    on: {
                                      input: (e) =>
                                        _vm.updateDate(e, "retirementDate"),
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "divider" }),
              _c("div", { staticClass: "sub-title" }, [
                _vm._v(_vm._s(_vm.FormMSG(1706, "Study"))),
              ]),
              _c(
                "div",
                { staticClass: "content-card-collapsible" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(26002, "Study level"),
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.FormMSG(
                                    20066,
                                    "Enter your study level ..."
                                  ),
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                  },
                                },
                                model: {
                                  value: _vm.profile.otherStudyLevel,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.profile,
                                      "otherStudyLevel",
                                      $$v
                                    )
                                  },
                                  expression: "profile.otherStudyLevel",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "divider" }),
              _c("div", { staticClass: "sub-title" }, [
                _vm._v(_vm._s(_vm.FormMSG(1707, "Clothes"))),
              ]),
              _c(
                "div",
                { staticClass: "content-card-collapsible" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(20603, "Size") } },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.FormMSG(
                                    26007,
                                    "Enter your size ..."
                                  ),
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                  },
                                },
                                model: {
                                  value: _vm.profile.size,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.profile, "size", $$v)
                                  },
                                  expression: "profile.size",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: { label: _vm.FormMSG(26400, "Pant size") },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.FormMSG(
                                    20608,
                                    "Enter your pant size ..."
                                  ),
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                  },
                                },
                                model: {
                                  value: _vm.profile.pantSize,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.profile, "pantSize", $$v)
                                  },
                                  expression: "profile.pantSize",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(26500, "Jacket size"),
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.FormMSG(
                                    260090,
                                    "Enter your jacket size ..."
                                  ),
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                  },
                                },
                                model: {
                                  value: _vm.profile.jacketSize,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.profile, "jacketSize", $$v)
                                  },
                                  expression: "profile.jacketSize",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: { label: _vm.FormMSG(20007, "Shoe size") },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.FormMSG(
                                    208000,
                                    "Enter your shoe size ..."
                                  ),
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                  },
                                },
                                model: {
                                  value: _vm.profile.shoeSize,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.profile, "shoeSize", $$v)
                                  },
                                  expression: "profile.shoeSize",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(2101019, "Teeshirt size"),
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.FormMSG(
                                    301100,
                                    "Enter your teeshirt size ..."
                                  ),
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                  },
                                },
                                model: {
                                  value: _vm.profile.tShirtSize,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.profile, "tShirtSize", $$v)
                                  },
                                  expression: "profile.tShirtSize",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm.isFilmSingleUserFree || _vm.isFilmSingleUser
        ? _c(
            "div",
            { staticClass: "card-collapsible mt-3" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
                  on: {
                    click: function ($event) {
                      return _vm.handleClickHeader("password")
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "title w-90" },
                    [
                      _c("b-icon", {
                        staticStyle: { color: "rgba(6, 38, 62, 0.84)" },
                        attrs: { icon: "key-fill" },
                      }),
                      _c("span", { staticClass: "ml-3" }, [
                        _vm._v(_vm._s(_vm.FormMSG(87, "Password"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-end w-10" },
                    [
                      !_vm.configAccordion["password"].isVisible
                        ? _c(_vm.getIconLucide("ChevronDown"), {
                            tag: "component",
                            attrs: { color: "#06263E", size: 22 },
                          })
                        : _vm._e(),
                      _vm.configAccordion["password"].isVisible
                        ? _c(_vm.getIconLucide("ChevronUp"), {
                            tag: "component",
                            attrs: { color: "#06263E", size: 22 },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "b-collapse",
                {
                  attrs: { visible: _vm.configAccordion["password"].isVisible },
                },
                [
                  _c(
                    "div",
                    { staticClass: "content-card-collapsible" },
                    [
                      _c("p", { staticClass: "text-muted" }, [
                        _vm._v(
                          "\n\t\t\t\t\t* " +
                            _vm._s(
                              _vm.FormMSG(
                                88,
                                "Make sure it's at least 15 characters OR at least 8 characters including a number and a lowercase letter."
                              )
                            ) +
                            "\n\t\t\t\t"
                        ),
                      ]),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "outline-primary",
                            disabled: _vm.statusSendCodeValidation.global,
                          },
                          on: { click: _vm.handleChangePassword },
                        },
                        [
                          !_vm.statusSendCodeValidation.progress &&
                          !_vm.statusSendCodeValidation.success
                            ? _c("span", [
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(_vm.FormMSG(89, "Change Password")) +
                                    "\n\t\t\t\t\t"
                                ),
                              ])
                            : _vm._e(),
                          _vm.statusSendCodeValidation.progress &&
                          !_vm.statusSendCodeValidation.success
                            ? _c(
                                "span",
                                [
                                  _c("b-spinner", {
                                    attrs: { label: "Loading...", small: "" },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.FormMSG(90, "Send code validation")
                                      ) +
                                      "\n\t\t\t\t\t"
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.statusSendCodeValidation.progress &&
                          _vm.statusSendCodeValidation.success
                            ? _c("span", [
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(91, "Code validation sended")
                                    ) +
                                    "\n\t\t\t\t\t"
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isDeterProject === true
        ? _c(
            "div",
            { staticClass: "card-collapsible mt-3" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
                  on: {
                    click: function ($event) {
                      return _vm.handleClickHeader("deter")
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "title w-90" },
                    [
                      _c(_vm.getLucideIcon("UserCog"), {
                        tag: "component",
                        attrs: { size: 20 },
                      }),
                      _c("span", { staticClass: "ml-3" }, [
                        _vm._v(_vm._s(_vm.FormMSG(131, "Team session"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-end w-10" },
                    [
                      !_vm.configAccordion["deter"].isVisible
                        ? _c(_vm.getIconLucide("ChevronDown"), {
                            tag: "component",
                            attrs: { color: "#06263E", size: 22 },
                          })
                        : _vm._e(),
                      _vm.configAccordion["deter"].isVisible
                        ? _c(_vm.getIconLucide("ChevronUp"), {
                            tag: "component",
                            attrs: { color: "#06263E", size: 22 },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "b-collapse",
                { attrs: { visible: _vm.configAccordion["deter"].isVisible } },
                [
                  _c(
                    "div",
                    { staticClass: "content-card-collapsible" },
                    [
                      _c(
                        "b-table-simple",
                        { attrs: { id: "deterSessionTeamTable" } },
                        [
                          _c(
                            "b-thead",
                            { attrs: { "head-variant": "dark" } },
                            [
                              _c(
                                "b-tr",
                                [
                                  _c("b-th"),
                                  _c("b-th", [
                                    _vm._v(_vm._s(_vm.FormMSG(465, "Team A"))),
                                  ]),
                                  _c("b-th", [
                                    _vm._v(_vm._s(_vm.FormMSG(466, "Team B"))),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-tbody",
                            [
                              _c(
                                "b-tr",
                                [
                                  _c("b-th", [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.FormMSG(457, "Session 1")) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]),
                                  _c(
                                    "b-td",
                                    [
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          size: "lg",
                                          value: true,
                                          "unchecked-value": false,
                                        },
                                        model: {
                                          value: _vm.profile.session1A,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profile,
                                              "session1A",
                                              $$v
                                            )
                                          },
                                          expression: "profile.session1A",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-td",
                                    [
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          size: "lg",
                                          value: true,
                                          "unchecked-value": false,
                                        },
                                        model: {
                                          value: _vm.profile.session1B,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profile,
                                              "session1B",
                                              $$v
                                            )
                                          },
                                          expression: "profile.session1B",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-tr",
                                [
                                  _c("b-th", [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.FormMSG(458, "Session 2")) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]),
                                  _c(
                                    "b-td",
                                    [
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          size: "lg",
                                          value: true,
                                          "unchecked-value": false,
                                        },
                                        model: {
                                          value: _vm.profile.session2A,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profile,
                                              "session2A",
                                              $$v
                                            )
                                          },
                                          expression: "profile.session2A",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-td",
                                    [
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          size: "lg",
                                          value: true,
                                          "unchecked-value": false,
                                        },
                                        model: {
                                          value: _vm.profile.session2B,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profile,
                                              "session2B",
                                              $$v
                                            )
                                          },
                                          expression: "profile.session2B",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-tr",
                                [
                                  _c("b-th", [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.FormMSG(459, "Session 3")) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]),
                                  _c(
                                    "b-td",
                                    [
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          size: "lg",
                                          value: true,
                                          "unchecked-value": false,
                                        },
                                        model: {
                                          value: _vm.profile.session3A,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profile,
                                              "session3A",
                                              $$v
                                            )
                                          },
                                          expression: "profile.session3A",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-td",
                                    [
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          size: "lg",
                                          value: true,
                                          "unchecked-value": false,
                                        },
                                        model: {
                                          value: _vm.profile.session3B,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profile,
                                              "session3B",
                                              $$v
                                            )
                                          },
                                          expression: "profile.session3B",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-tr",
                                [
                                  _c("b-th", [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.FormMSG(460, "Session 4")) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]),
                                  _c(
                                    "b-td",
                                    [
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          size: "lg",
                                          value: true,
                                          "unchecked-value": false,
                                        },
                                        model: {
                                          value: _vm.profile.session4A,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profile,
                                              "session4A",
                                              $$v
                                            )
                                          },
                                          expression: "profile.session4A",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-td",
                                    [
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          size: "lg",
                                          value: true,
                                          "unchecked-value": false,
                                        },
                                        model: {
                                          value: _vm.profile.session4B,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profile,
                                              "session4B",
                                              $$v
                                            )
                                          },
                                          expression: "profile.session4B",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-tr",
                                [
                                  _c("b-th", [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.FormMSG(461, "Session 5")) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]),
                                  _c(
                                    "b-td",
                                    [
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          size: "lg",
                                          value: true,
                                          "unchecked-value": false,
                                        },
                                        model: {
                                          value: _vm.profile.session5A,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profile,
                                              "session5A",
                                              $$v
                                            )
                                          },
                                          expression: "profile.session5A",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-td",
                                    [
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          size: "lg",
                                          value: true,
                                          "unchecked-value": false,
                                        },
                                        model: {
                                          value: _vm.profile.session5B,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profile,
                                              "session5B",
                                              $$v
                                            )
                                          },
                                          expression: "profile.session5B",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-tr",
                                [
                                  _c("b-th", [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.FormMSG(462, "Session 6")) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]),
                                  _c(
                                    "b-td",
                                    [
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          size: "lg",
                                          value: true,
                                          "unchecked-value": false,
                                        },
                                        model: {
                                          value: _vm.profile.session6A,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profile,
                                              "session6A",
                                              $$v
                                            )
                                          },
                                          expression: "profile.session6A",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-td",
                                    [
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          size: "lg",
                                          value: true,
                                          "unchecked-value": false,
                                        },
                                        model: {
                                          value: _vm.profile.session6B,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profile,
                                              "session6B",
                                              $$v
                                            )
                                          },
                                          expression: "profile.session6B",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-tr",
                                [
                                  _c("b-th", [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.FormMSG(463, "Session 7")) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]),
                                  _c(
                                    "b-td",
                                    [
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          size: "lg",
                                          value: true,
                                          "unchecked-value": false,
                                        },
                                        model: {
                                          value: _vm.profile.session7A,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profile,
                                              "session7A",
                                              $$v
                                            )
                                          },
                                          expression: "profile.session7A",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-td",
                                    [
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          size: "lg",
                                          value: true,
                                          "unchecked-value": false,
                                        },
                                        model: {
                                          value: _vm.profile.session7B,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profile,
                                              "session7B",
                                              $$v
                                            )
                                          },
                                          expression: "profile.session7B",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-tr",
                                [
                                  _c("b-th", [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.FormMSG(464, "Session 8")) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]),
                                  _c(
                                    "b-td",
                                    [
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          size: "lg",
                                          value: true,
                                          "unchecked-value": false,
                                        },
                                        model: {
                                          value: _vm.profile.session8A,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profile,
                                              "session8A",
                                              $$v
                                            )
                                          },
                                          expression: "profile.session8A",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-td",
                                    [
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          size: "lg",
                                          value: true,
                                          "unchecked-value": false,
                                        },
                                        model: {
                                          value: _vm.profile.session8B,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.profile,
                                              "session8B",
                                              $$v
                                            )
                                          },
                                          expression: "profile.session8B",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "card-collapsible mt-3" }, [
        _c(
          "div",
          {
            staticClass:
              "header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer",
            on: {
              click: function ($event) {
                return _vm.handleClickHeader("authorisation")
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "title w-90" },
              [
                _c("b-icon", {
                  staticStyle: { color: "rgba(6, 38, 62, 0.84)" },
                  attrs: { icon: "shield-lock-fill" },
                }),
                _c("span", { staticClass: "ml-3" }, [
                  _vm._v(_vm._s(_vm.FormMSG(133, "Authorisation"))),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "cursor-pointer d-flex justify-content-end w-10" },
              [
                !_vm.configAccordion["authorisation"].isVisible
                  ? _c(_vm.getLucideIcon("ChevronDown"), {
                      tag: "component",
                      attrs: { color: "#06263E", size: 22 },
                    })
                  : _vm._e(),
                _vm.configAccordion["authorisation"].isVisible
                  ? _c(_vm.getLucideIcon("ChevronUp"), {
                      tag: "component",
                      attrs: { color: "#06263E", size: 22 },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ]),
      _c(
        "b-collapse",
        { attrs: { visible: _vm.configAccordion["authorisation"].isVisible } },
        [
          _c("div", { staticClass: "content-card-collapsible" }, [
            _c("div", [
              _c(
                "div",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { sm: "12", md: "12" } }, [
                        _c(
                          "fieldset",
                          { staticClass: "card-inside" },
                          [
                            _c("legend", { staticClass: "card-inside" }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.FormMSG(301, "Application rights")
                                  ) +
                                  "\n\t\t\t\t\t\t\t\t"
                              ),
                            ]),
                            _c(
                              "b-row",
                              { staticStyle: { "padding-left": "30px" } },
                              [
                                _c(
                                  "b-col",
                                  { staticClass: "mb-3", attrs: { md: "4" } },
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        staticClass: "pj-cb pb-1",
                                        attrs: {
                                          size: "lg",
                                          value: true,
                                          "unchecked-value": false,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.handleAccessLevel(
                                              "canManageCrewList",
                                              $event,
                                              _vm.roleUserData,
                                              _vm.screenAccess
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.roleUserData.canManageCrewList,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.roleUserData,
                                              "canManageCrewList",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "roleUserData.canManageCrewList",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(
                                                2970,
                                                "Manage crew list"
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              { staticStyle: { "padding-left": "30px" } },
                              [
                                _vm.useDoc
                                  ? _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-3",
                                        attrs: { md: "4" },
                                      },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb pb-1",
                                            attrs: {
                                              size: "lg",
                                              value: true,
                                              "unchecked-value": false,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleAccessLevel(
                                                  "canManageDocuments",
                                                  $event,
                                                  _vm.roleUserData,
                                                  _vm.screenAccess
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.roleUserData
                                                  .canManageDocuments,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.roleUserData,
                                                  "canManageDocuments",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "roleUserData.canManageDocuments",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    2971,
                                                    "Manage documents"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.useDoc
                                  ? _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-3",
                                        attrs: { md: "4" },
                                      },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb pb-1",
                                            attrs: {
                                              size: "lg",
                                              value: true,
                                              "unchecked-value": false,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleAccessLevel(
                                                  "canManageDocumentPackage",
                                                  $event,
                                                  _vm.roleUserData,
                                                  _vm.screenAccess
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.roleUserData
                                                  .canManageDocumentPackage,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.roleUserData,
                                                  "canManageDocumentPackage",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "roleUserData.canManageDocumentPackage",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    2440,
                                                    "Manage document package"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.useCallSheet
                                  ? _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-3",
                                        attrs: { md: "4" },
                                      },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb pb-1",
                                            attrs: { size: "lg" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleAccessLevel(
                                                  "canManageCallSheets",
                                                  $event,
                                                  _vm.roleUserData,
                                                  _vm.screenAccess
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.roleUserData
                                                  .canManageCallSheets,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.roleUserData,
                                                  "canManageCallSheets",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "roleUserData.canManageCallSheets",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    2450,
                                                    "Manage call sheets"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              { staticStyle: { "padding-left": "30px" } },
                              [
                                _vm.useAcc
                                  ? _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-3",
                                        attrs: { md: "4" },
                                      },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb pb-1",
                                            attrs: {
                                              size: "lg",
                                              value: true,
                                              "unchecked-value": false,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleAccessLevel(
                                                  "canManageAccommodations",
                                                  $event,
                                                  _vm.roleUserData,
                                                  _vm.screenAccess
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.roleUserData
                                                  .canManageAccommodations,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.roleUserData,
                                                  "canManageAccommodations",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "roleUserData.canManageAccommodations",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    2973,
                                                    "Manage accomodations"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.useLoc
                                  ? _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-3",
                                        attrs: { md: "4" },
                                      },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb pb-1",
                                            attrs: {
                                              size: "lg",
                                              value: true,
                                              "unchecked-value": false,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleAccessLevel(
                                                  "canManageLocations",
                                                  $event,
                                                  _vm.roleUserData,
                                                  _vm.screenAccess
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.roleUserData
                                                  .canManageLocations,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.roleUserData,
                                                  "canManageLocations",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "roleUserData.canManageLocations",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    2449,
                                                    "Manage locations"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.useTrans
                                  ? _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-3",
                                        attrs: { md: "4" },
                                      },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb pb-1",
                                            attrs: {
                                              size: "lg",
                                              value: true,
                                              "unchecked-value": false,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleAccessLevel(
                                                  "canManageTransport",
                                                  $event,
                                                  _vm.roleUserData,
                                                  _vm.screenAccess
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.roleUserData
                                                  .canManageTransport,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.roleUserData,
                                                  "canManageTransport",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "roleUserData.canManageTransport",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    20449,
                                                    "Manage transport"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              { staticStyle: { "padding-left": "30px" } },
                              [
                                _vm.useImportExport
                                  ? _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-3",
                                        attrs: { md: "4" },
                                      },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb pb-1",
                                            attrs: { size: "lg" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleAccessLevel(
                                                  "canExportImport",
                                                  $event,
                                                  _vm.roleUserData,
                                                  _vm.screenAccess
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.roleUserData
                                                  .canExportImport,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.roleUserData,
                                                  "canExportImport",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "roleUserData.canExportImport",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    2455,
                                                    "Can import/export"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.useBudget
                                  ? _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-3",
                                        attrs: { md: "4" },
                                      },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb pb-1",
                                            attrs: { size: "lg" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleAccessLevel(
                                                  "canViewDepartmentBudget",
                                                  $event,
                                                  _vm.roleUserData,
                                                  _vm.screenAccess
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.roleUserData
                                                  .canViewDepartmentBudget,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.roleUserData,
                                                  "canViewDepartmentBudget",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "roleUserData.canViewDepartmentBudget",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    2690,
                                                    "View department budget"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.useBudget
                                  ? _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-3",
                                        attrs: { md: "4" },
                                      },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb pb-1",
                                            attrs: {
                                              size: "lg",
                                              value: true,
                                              "unchecked-value": false,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleAccessLevel(
                                                  "canViewGlobalBudget",
                                                  $event,
                                                  _vm.roleUserData,
                                                  _vm.screenAccess
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.roleUserData
                                                  .canViewGlobalBudget,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.roleUserData,
                                                  "canViewGlobalBudget",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "roleUserData.canViewGlobalBudget",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    24430,
                                                    "View global budget"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              { staticStyle: { "padding-left": "30px" } },
                              [
                                _vm.useGreenTable
                                  ? _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-3",
                                        attrs: { md: "4" },
                                      },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb pb-1",
                                            attrs: { size: "lg" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleAccessLevel(
                                                  "canViewGreenTable",
                                                  $event,
                                                  _vm.roleUserData,
                                                  _vm.screenAccess
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.roleUserData
                                                  .canViewGreenTable,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.roleUserData,
                                                  "canViewGreenTable",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "roleUserData.canViewGreenTable",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    245640,
                                                    "View Green Table"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.useReporting
                                  ? _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-3",
                                        attrs: { md: "4" },
                                      },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb pb-1",
                                            attrs: { size: "lg" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleAccessLevel(
                                                  _vm.canAccessGreenReporting,
                                                  $event,
                                                  _vm.roleUserData,
                                                  _vm.screenAccess
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.roleUserData
                                                  .canAccessGreenReporting,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.roleUserData,
                                                  "canAccessGreenReporting",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "roleUserData.canAccessGreenReporting",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    24560,
                                                    "View Green reporting screens"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.useCarbonRemoval
                                  ? _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-3",
                                        attrs: { md: "4" },
                                      },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb pb-1",
                                            attrs: {
                                              size: "lg",
                                              value: true,
                                              "unchecked-value": false,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleAccessLevel(
                                                  "canManageCarbonRemoval",
                                                  $event,
                                                  _vm.roleUserData,
                                                  _vm.screenAccess
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.roleUserData
                                                  .canManageCarbonRemoval,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.roleUserData,
                                                  "canManageCarbonRemoval",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "roleUserData.canManageCarbonRemoval",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    24480,
                                                    "Manage carbon removal"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              { staticStyle: { "padding-left": "30px" } },
                              [
                                _vm.useWaste
                                  ? _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-3",
                                        attrs: { md: "4" },
                                      },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb pb-1",
                                            attrs: { size: "lg" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleAccessLevel(
                                                  "canViewWasteScreens",
                                                  $event,
                                                  _vm.roleUserData,
                                                  _vm.screenAccess
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.roleUserData
                                                  .canViewWasteScreens,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.roleUserData,
                                                  "canViewWasteScreens",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "roleUserData.canViewWasteScreens",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    244005,
                                                    "View Waste screens"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.useTipOfDay
                                  ? _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-3",
                                        attrs: { md: "4" },
                                      },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb pb-1",
                                            attrs: { size: "lg" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleAccessLevel(
                                                  "canManageTipOfTheDay",
                                                  $event,
                                                  _vm.roleUserData,
                                                  _vm.screenAccess
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.roleUserData
                                                  .canManageTipOfTheDay,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.roleUserData,
                                                  "canManageTipOfTheDay",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "roleUserData.canManageTipOfTheDay",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    24405,
                                                    "Manage tip of the day"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              { staticStyle: { "padding-left": "30px" } },
                              [
                                _c(
                                  "b-col",
                                  { staticClass: "mb-3", attrs: { md: "4" } },
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        staticClass: "pj-cb pb-1 mb-3",
                                        attrs: { size: "lg" },
                                        on: {
                                          change: function ($event) {
                                            return _vm.handleAccessLevel(
                                              _vm.canAccessConfig,
                                              $event,
                                              _vm.roleUserData,
                                              _vm.screenAccess
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.roleUserData.canAccessConfig,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.roleUserData,
                                              "canAccessConfig",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "roleUserData.canAccessConfig",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(
                                                246,
                                                "Can access config"
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.needValidation
                          ? _c("fieldset", { staticClass: "card-inside" }, [
                              _c("legend", { staticClass: "card-inside" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(302, "Validation rights")
                                    ) +
                                    "\n\t\t\t\t\t\t\t\t"
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "lh-22" },
                                [
                                  _c("h6", [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(
                                            303,
                                            "Validation level for PO, Expenses and Timehseets"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _c("b-form-select", {
                                    attrs: {
                                      options: _vm.optionsForValidationLevel,
                                      id: "valLevel",
                                      size: "md",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.manageValidationChange(
                                          _vm.roleUserData
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.roleUserData.validationLevel,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.roleUserData,
                                          "validationLevel",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "roleUserData.validationLevel",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm.useDoc
                          ? _c("fieldset", { staticClass: "card-inside" }, [
                              _c("legend", { staticClass: "card-inside" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(
                                        305,
                                        "Onboarding validation role"
                                      )
                                    ) +
                                    "\n\t\t\t\t\t\t\t\t"
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "lh-22" },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "4" } },
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              staticClass: "pj-cb mb-3 pb-1",
                                              attrs: { size: "lg" },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.handleAccessLevel(
                                                    _vm.gateKeeper,
                                                    $event,
                                                    _vm.roleUserData,
                                                    _vm.isRole
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.roleUserData.gateKeeper,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.roleUserData,
                                                    "gateKeeper",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "roleUserData.gateKeeper",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      266,
                                                      "Gate Keeper"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "4" } },
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              staticClass: "pj-cb mb-3 pb-1",
                                              attrs: { size: "lg" },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.handleAccessLevel(
                                                    _vm.productionAccountant,
                                                    $event,
                                                    _vm.roleUserData,
                                                    _vm.isRole
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.roleUserData
                                                    .productionAccountant,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.roleUserData,
                                                    "productionAccountant",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "roleUserData.productionAccountant",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      261,
                                                      "Production Account"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "4" } },
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              staticClass: "pj-cb mb-3 pb-1",
                                              attrs: { size: "lg" },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.handleAccessLevel(
                                                    _vm.upm,
                                                    $event,
                                                    _vm.roleUserData,
                                                    _vm.isRole
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.roleUserData.upm,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.roleUserData,
                                                    "upm",
                                                    $$v
                                                  )
                                                },
                                                expression: "roleUserData.upm",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(262, "UPM")
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "4" } },
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              staticClass: "pj-cb mb-3 pb-1",
                                              attrs: { size: "lg" },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.handleAccessLevel(
                                                    _vm.lineProducer,
                                                    $event,
                                                    _vm.roleUserData,
                                                    _vm.isRole
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.roleUserData.lineProducer,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.roleUserData,
                                                    "lineProducer",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "roleUserData.lineProducer",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      263,
                                                      "Line Producer"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "4" } },
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              staticClass: "pj-cb mb-3 pb-1",
                                              attrs: { size: "lg" },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.handleAccessLevel(
                                                    _vm.studio,
                                                    $event,
                                                    _vm.roleUserData,
                                                    _vm.isRole
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.roleUserData.studio,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.roleUserData,
                                                    "studio",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "roleUserData.studio",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(265, "Studio")
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "4" } },
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              staticClass: "pj-cb mb-3 pb-1",
                                              attrs: { size: "lg" },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.handleAccessLevel(
                                                    _vm.canValidateI9,
                                                    $event,
                                                    _vm.roleUserData,
                                                    _vm.screenAccess
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.roleUserData
                                                    .canValidateI9,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.roleUserData,
                                                    "canValidateI9",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "roleUserData.canValidateI9",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      270,
                                                      "Verify I-9"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm.usePoAndExp
                          ? _c("fieldset", { staticClass: "card-inside" }, [
                              _c("legend", { staticClass: "card-inside" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(355, "Editing authorization")
                                    ) +
                                    "\n\t\t\t\t\t\t\t\t"
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "lh-22" },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _vm.useExp
                                        ? _c(
                                            "b-col",
                                            { attrs: { md: "4" } },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  staticClass:
                                                    "pj-cb mb-3 pb-1",
                                                  attrs: { size: "lg" },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.handleAccessLevel(
                                                        _vm.canEditExpense,
                                                        $event,
                                                        _vm.roleUserData,
                                                        _vm.screenAccess
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.roleUserData
                                                        .canEditExpense,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.roleUserData,
                                                        "canEditExpense",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "roleUserData.canEditExpense",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          250,
                                                          "Can edit Expenses"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.usePo
                                        ? _c(
                                            "b-col",
                                            { attrs: { md: "4" } },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  staticClass:
                                                    "pj-cb mb-3 pb-1",
                                                  attrs: { size: "lg" },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.handleAccessLevel(
                                                        _vm.canEditPo,
                                                        $event,
                                                        _vm.roleUserData,
                                                        _vm.screenAccess
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.roleUserData
                                                        .canEditPo,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.roleUserData,
                                                        "canEditPo",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "roleUserData.canEditPo",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          255,
                                                          "Can edit PO"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm.useFinance
                          ? _c("fieldset", { staticClass: "card-inside" }, [
                              _c("legend", { staticClass: "card-inside" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(
                                        360,
                                        "Accounting/Budget situation"
                                      )
                                    ) +
                                    "\n\t\t\t\t\t\t\t\t"
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "lh-22" },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "4" } },
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              staticClass: "pj-cb mb-3 pb-1",
                                              attrs: { size: "lg" },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.handleAccessLevel(
                                                    _vm.canLockBudget,
                                                    $event,
                                                    _vm.roleUserData,
                                                    _vm.screenAccess
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.roleUserData
                                                    .canLockBudget,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.roleUserData,
                                                    "canLockBudget",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "roleUserData.canLockBudget",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      361,
                                                      "Can lock/unlock initial budget"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "4" } },
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              staticClass: "pj-cb mb-3 pb-1",
                                              attrs: { size: "lg" },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.handleAccessLevel(
                                                    _vm.canModifyEstimatedFinalCost,
                                                    $event,
                                                    _vm.roleUserData,
                                                    _vm.screenAccess
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.roleUserData
                                                    .canModifyEstimatedFinalCost,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.roleUserData,
                                                    "canModifyEstimatedFinalCost",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "roleUserData.canModifyEstimatedFinalCost",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      362,
                                                      "Can modify EFC"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "4" } },
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              staticClass: "pj-cb mb-3 pb-1",
                                              attrs: { size: "lg" },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.handleAccessLevel(
                                                    _vm.canEditAndAddCode,
                                                    $event,
                                                    _vm.roleUserData,
                                                    _vm.screenAccess
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.roleUserData
                                                    .canEditAndAddCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.roleUserData,
                                                    "canEditAndAddCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "roleUserData.canEditAndAddCode",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      363,
                                                      "Can edit/add codes"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm.showEntryForCrew
                          ? _c("fieldset", { staticClass: "card-inside" }, [
                              _c("legend", { staticClass: "card-inside" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(356, "Entry for someone else")
                                    ) +
                                    "\n\t\t\t\t\t\t\t\t"
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "lh-22" },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _vm.showEntryForCrewTimesheet
                                        ? _c(
                                            "b-col",
                                            { attrs: { md: "4" } },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  staticClass:
                                                    "pj-cb mb-3 pb-1",
                                                  attrs: { size: "lg" },
                                                  on: {
                                                    change:
                                                      _vm.handleUserEntryLevelChange,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.userEntryLevel
                                                        .timeSheets,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.userEntryLevel,
                                                        "timeSheets",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "userEntryLevel.timeSheets",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          251,
                                                          "Timesheets"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.showEntryForCrewExpenses
                                        ? _c(
                                            "b-col",
                                            { attrs: { md: "4" } },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  staticClass:
                                                    "pj-cb mb-3 pb-1",
                                                  attrs: { size: "lg" },
                                                  on: {
                                                    change:
                                                      _vm.handleUserEntryLevelChange,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.userEntryLevel
                                                        .expenses,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.userEntryLevel,
                                                        "expenses",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "userEntryLevel.expenses",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          252,
                                                          "Expenses"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.showEntryForCrewPo
                                        ? _c(
                                            "b-col",
                                            { attrs: { md: "4" } },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  staticClass:
                                                    "pj-cb mb-3 pb-1",
                                                  attrs: { size: "lg" },
                                                  on: {
                                                    change:
                                                      _vm.handleUserEntryLevelChange,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.userEntryLevel.po,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.userEntryLevel,
                                                        "po",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "userEntryLevel.po",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(253, "PO")
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "h6",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.userEntryLevel.po ||
                                            _vm.userEntryLevel.expenses ||
                                            _vm.userEntryLevel.timeSheets,
                                          expression:
                                            "userEntryLevel.po || userEntryLevel.expenses || userEntryLevel.timeSheets",
                                        },
                                      ],
                                    },
                                    [
                                      _c("b", [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(
                                                357,
                                                "Department selection"
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c("v-select", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.userEntryLevel.po ||
                                          _vm.userEntryLevel.expenses ||
                                          _vm.userEntryLevel.timeSheets,
                                        expression:
                                          "userEntryLevel.po || userEntryLevel.expenses || userEntryLevel.timeSheets",
                                      },
                                    ],
                                    staticClass: "style-chooser",
                                    attrs: {
                                      multiple: "",
                                      placeholder: "Select a department",
                                      label: "text",
                                      options: _vm.allDepartements,
                                    },
                                    on: { input: _vm.checkDepartment },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "option",
                                          fn: function (option) {
                                            return [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  staticClass:
                                                    "pj-cb mb-3 pb-1",
                                                  attrs: {
                                                    disabled: "",
                                                    size: "lg",
                                                  },
                                                  model: {
                                                    value: option.selected,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        option,
                                                        "selected",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "option.selected",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(option.text) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      791507781
                                    ),
                                    model: {
                                      value: _vm.selectedDepartment,
                                      callback: function ($$v) {
                                        _vm.selectedDepartment = $$v
                                      },
                                      expression: "selectedDepartment",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _c("fieldset", { staticClass: "card-inside" }, [
                          _c("legend", { staticClass: "card-inside" }, [
                            _vm._v(_vm._s(_vm.FormMSG(1200, "Planning"))),
                          ]),
                          _c(
                            "div",
                            { staticClass: "lh-22" },
                            [
                              _c("h6", [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.FormMSG(1201, "Validation Fimalac")
                                    )
                                  ),
                                ]),
                              ]),
                              _c("b-form-select", {
                                attrs: {
                                  options: _vm.optionsForValidationLevelFimamac,
                                  id: "valLevel-fimalac",
                                  size: "md",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.manageValidationChangeFimalac(
                                      _vm.roleUserData
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.validationFimalac,
                                  callback: function ($$v) {
                                    _vm.validationFimalac = $$v
                                  },
                                  expression: "validationFimalac",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _c("NewCapture", {
        key: _vm.refreshTokenCaptureItem,
        attrs: {
          parentType: "user",
          parentId: _vm.profile.id,
          "auto-crop-capture": "",
          "cam-mode-first": "",
          "only-one-crop-mode": "",
        },
        on: { change: _vm.handleImgsTaked },
        model: {
          value: _vm.capturePict,
          callback: function ($$v) {
            _vm.capturePict = $$v
          },
          expression: "capturePict",
        },
      }),
      _c(
        "b-modal",
        {
          ref: "my-modal",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            title: "Crop Picture",
            size: "cropSize",
            "ok-variant": "success",
            "ok-title": "Confirm Save",
            "cancel-title": "Cancel",
            "modal-class": "mui-animation",
            fade: false,
          },
          on: { ok: _vm.endCrop, cancel: _vm.endCropCancel },
          model: {
            value: _vm.cropPict,
            callback: function ($$v) {
              _vm.cropPict = $$v
            },
            expression: "cropPict",
          },
        },
        [_c("CropPicture", { ref: "myCropPicture" })],
        1
      ),
      _c("change-mail-modal", {
        attrs: { open: _vm.showChangeMailModal, profile: _vm.profile },
        on: {
          "modal:close": function ($event) {
            _vm.showChangeMailModal = false
          },
          "change-mail-modal:save": _vm.handleMailModalSave,
        },
      }),
      _c("change-phone-modal", {
        attrs: { open: _vm.showChangePhoneModal, profile: _vm.profile },
        on: {
          "modal:close": function ($event) {
            _vm.showChangePhoneModal = false
          },
          "change-phone-modal:save": _vm.handlePhoneModalSave,
        },
      }),
      _c("validation-code-modal", {
        attrs: {
          open: _vm.showValidationCodeModal,
          "email-for-validation": _vm.profile.email,
        },
        on: {
          "validation-code-modal:save": _vm.handleValidationCodeModalSave,
          "modal:close": _vm.handleValidationCodeModalClose,
        },
      }),
      _c(
        "b-modal",
        {
          staticClass: "modal-success",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            title: _vm.FormMSG(105, "Picture"),
            "ok-variant": "success",
            "ok-only": "",
            "modal-class": "mui-animation",
            fade: false,
          },
          on: {
            ok: function ($event) {
              _vm.openPictureModal = false
            },
          },
          model: {
            value: _vm.openPictureModal,
            callback: function ($$v) {
              _vm.openPictureModal = $$v
            },
            expression: "openPictureModal",
          },
        },
        [
          _c(
            "div",
            [_c("b-img", { attrs: { src: _vm.imgToShow, "fluid-grow": "" } })],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }