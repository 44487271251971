<style lang="scss" scoped>
.profile-picture-preview {
	margin-top: 0.8rem;
	width: 175px;
	height: 175px;
	object-fit: cover;
	border-radius: 50%;
}
</style>
<template>
	<div class="profile">
		<!-- Personal data -->
		<div class="card-collapsible">
			<div
				class="header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer"
				@click="handleClickHeader('personnal-data')"
			>
				<div class="title w-90">
					<b-icon icon="person-check-fill" style="color: rgba(6, 38, 62, 0.84)" /><span class="ml-3">{{ FormMSG(1, 'Personal data') }}</span>
				</div>
				<div class="d-flex justify-content-end w-10">
					<component v-if="!configAccordion['personnal-data'].isVisible" :is="getIconLucide('ChevronDown')" color="#06263E" :size="22" />
					<component v-if="configAccordion['personnal-data'].isVisible" :is="getIconLucide('ChevronUp')" color="#06263E" :size="22" />
				</div>
			</div>
			<b-collapse :visible="configAccordion['personnal-data'].isVisible">
				<div class="content-card-collapsible">
					<b-row>
						<b-col sm="8" md="8" lg="9" xl="9">
							<b-row>
								<b-col sm="12" md="12" lg="4" xl="4">
									<b-form-group>
										<label for="reference"> {{ FormMSG(2, 'Reference') }} </label>
										<b-form-input
											v-model="profile.reference"
											type="text"
											id="reference"
											:placeholder="FormMSG(3, 'Enter your personal number.')"
											:disabled="isFilmSingleUserFree || isFilmSingleUser"
										/>
									</b-form-group>
								</b-col>
								<b-col sm="12" md="12" lg="4" xl="4">
									<b-form-group :label="FormMSG(4, 'Email')">
										<b-input-group>
											<b-form-input
												:value="profile.email"
												type="text"
												id="email"
												:placeholder="FormMSG(5, 'Enter your email')"
												disabled
											/>
											<b-input-group-append v-if="isFilmSingleUserFree || isFilmSingleUser">
												<b-input-group-text class="cursor-pointer" @click="showChangeMailModal = true">
													<component :is="getIconLucide('Pencil')" color="#007A77" :size="16" />
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col sm="12" md="12" lg="4" xl="4">
									<b-form-group :label="FormMSG(6, 'Phone')">
										<b-input-group>
											<b-form-input
												:value="profile.phone"
												type="text"
												id="phone"
												disabled
												:placeholder="FormMSG(7, 'Enter your phone number')"
											/>
											<b-input-group-append v-if="isFilmSingleUserFree || isFilmSingleUser">
												<b-input-group-text class="cursor-pointer" @click="showChangePhoneModal = true">
													<component :is="getIconLucide('Pencil')" color="#007A77" :size="16" />
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col sm="12" md="12" lg="3" xl="3">
									<b-form-group>
										<label for="firstname">{{ FormMSG(8, 'First Name') }}</label>
										<b-form-input
											v-model="profile.firstName"
											type="text"
											id="firstname"
											:placeholder="FormMSG(9, 'Enter your first name.')"
										></b-form-input>
									</b-form-group>
								</b-col>
								<b-col sm="12" md="12" lg="3" xl="3">
									<b-form-group>
										<label for="name">{{ FormMSG(10, 'Last Name') }} </label>
										<b-form-input
											v-model="profile.name"
											type="text"
											id="name"
											:placeholder="FormMSG(11, 'Enter your name.')"
										></b-form-input>
									</b-form-group>
								</b-col>
								<b-col sm="12" md="12" lg="3" xl="3">
									<b-form-group>
										<label for="maidenName">{{ FormMSG(1239, ' Maiden name (if different)') }}</label>
										<b-form-input v-model="profile.maidenName" type="text" id="maidenName"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col sm="12" md="12" lg="3" xl="3" class="d-flex justify-content-start">
									<b-form-group :label="FormMSG(86, 'Color')">
										<div class="w-100 d-flex justify-content-start">
											<verte menuPosition="top" v-model="profile.color" picker="square" model="hex" menu-position="left" />
										</div>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col sm="12" md="12" lg="3" xl="3">
									<b-form-group>
										<label for="nationality">{{ FormMSG(12, 'Nationality') }}</label>
										<b-form-input
											v-model="profile.nationality"
											type="text"
											id="nationality"
											:placeholder="FormMSG(13, 'Enter your nationality')"
										></b-form-input>
									</b-form-group>
								</b-col>
								<b-col sm="12" md="12" lg="3" xl="3">
									<b-form-group :label="FormMSG(14, 'Langue')" label-for="optionsForLanguages">
										<b-form-select v-model="profile.language" :options="optionsForLanguages" id="optionsForLanguages"></b-form-select>
									</b-form-group>
								</b-col>
								<b-col sm="12" md="12" lg="3" xl="3">
									<b-form-group :label="FormMSG(20, 'Gender')">
										<b-form-select v-model="profile.gender">
											<b-form-select-option v-for="(gender, index) in menuGenders" :key="index" :value="gender.value">
												{{ gender.text }}
											</b-form-select-option>
										</b-form-select>
									</b-form-group>
								</b-col>
								<b-col sm="12" md="12" lg="3" xl="3">
									<b-form-group :label="FormMSG(1240, 'Title')">
										<b-form-select v-model="profile.title">
											<b-form-select-option v-for="(title, index) in menuTitle" :key="index" :value="title.value">
												{{ title.text }}
											</b-form-select-option>
										</b-form-select>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col sm="12" md="12" lg="3" xl="3">
									<b-form-group :label="FormMSG(15, 'Birthdate')" label-for="birthDate">
										<b-form-input :value="displayBirthDate" @input="updateBirthDate" type="date" id="birthDate" />
									</b-form-group>
								</b-col>
								<b-col sm="12" md="12" lg="3" xl="3">
									<b-form-group :label="FormMSG(16, 'Place of birth')" label-for="placeOfBirth">
										<b-form-input
											v-model="profile.placeOfBirth"
											id="placeOfBirth"
											type="text"
											:placeholder="FormMSG(17, 'Place of birth')"
											autocomplete="off"
										/>
									</b-form-group>
								</b-col>
								<b-col sm="12" md="12" lg="3" xl="3">
									<b-form-group :label="FormMSG(18, 'Place of birth ZIP')" label-for="placeOfBirthZip">
										<b-form-input
											v-model="profile.placeOfBirthZip"
											id="placeOfBirthZip"
											type="text"
											:placeholder="FormMSG(19, 'Place of birth zip')"
										/>
									</b-form-group>
								</b-col>
								<b-col sm="12" md="12" lg="3" xl="3">
									<b-form-group :label="FormMSG(1241, 'Country of birth')">
										<b-form-input v-model="profile.birthCountry" :placeholder="FormMSG(1315, 'Enter your country of ...')" />
									</b-form-group>
								</b-col>
							</b-row>
							<b-row v-if="isDeterProject === true">
								<b-col sm="12" md="12" lg="3" xl="3">
									<b-form-group :label="FormMSG(12410, 'Custom function name')">
										<b-form-input v-model="profile.customFunctionName" :placeholder="profile.functionName" />
									</b-form-group>
								</b-col>
							</b-row>
						</b-col>
						<b-col class="text-center" sm="4" md="4" lg="3" xl="3">
							<div class="profile-picture-block">
								<b-form-group v-model="profile.picture" v-lazy-container="{ selector: 'img' }">
									<img
										:data-src="imageName"
										data-error="img/brand/avatar.jpg"
										data-loading="img/brand/tenor.gif"
										class="profile-picture-preview"
										alt="pict"
									/>
								</b-form-group>
								<b-form-group v-if="isFilmSingleUserFree || isFilmSingleUser">
									<b-button v-if="!$isPwa()" variant="outline-secondary" size="md" @click="startCapture">
										<i class="icon-camera"></i>
										{{ FormMSG(21, 'Change your picture') }}
									</b-button>
									<MobileProfilePicCaptureBtn
										v-if="$isPwa()"
										:label-button="FormMSG(22, 'Change your picture')"
										parent-type="user"
										:parentId="profile.id"
										@change="handleImgsTaked"
									/>
								</b-form-group>
							</div>
							<ShareInformation
								v-if="isFilmSingle"
								:btn-label="FormMSG(23, 'Share my Information')"
								:title-modal="FormMSG(24, 'Send your personal informations sheet')"
								:msg-success="FormMSG(25, 'Profile successfully sent')"
								show-check-memo-deal
								send-a-copy
							/>
						</b-col>
					</b-row>
				</div>
			</b-collapse>
		</div>
		<!-- Identification-->
		<div class="card-collapsible mt-3">
			<div class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center" @click="handleClickHeader('identification')">
				<div class="title w-90">
					<b-icon icon="person-lines-fill" style="color: rgba(6, 38, 62, 0.84)" /><span class="ml-3">{{ FormMSG(26, 'Identification') }}</span>
				</div>
				<div class="d-flex justify-content-end w-10">
					<component v-if="!configAccordion['identification'].isVisible" :is="getIconLucide('ChevronDown')" color="#06263E" :size="22" />
					<component v-if="configAccordion['identification'].isVisible" :is="getIconLucide('ChevronUp')" color="#06263E" :size="22" />
				</div>
			</div>
			<b-collapse :visible="configAccordion['identification'].isVisible">
				<div class="sub-title">
					{{ FormMSG(27, 'Identification Numbers') }}
				</div>
				<div class="content-card-collapsible">
					<b-row>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label for="nationalRegistryNumber">{{ FormMSG(30, 'National Registration Number') }}</label>
								<b-form-input
									v-model="profile.nationalRegistryNumber"
									type="text"
									id="nationalRegistryNumber"
									:placeholder="FormMSG(31, 'Enter your National Registration Number ')"
								></b-form-input>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label for="companyNumber">{{ FormMSG(32, 'Company Number') }}</label>
								<b-form-input
									v-model="profile.companyNumber"
									type="text"
									id="companyNumber"
									:placeholder="FormMSG(33, 'Enter your Company official Number ')"
								></b-form-input>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label for="conge_spectacles_id">{{ FormMSG(1242, '"Congés spectacles" ID') }}</label>
								<b-form-input
									v-model="profile.congesSpectacle"
									type="text"
									id="conge_spectacles_id"
									:placeholder="FormMSG(1243, 'Enter your conges spectacles id')"
								/>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label for="signatureId">{{ FormMSG(106, 'Signature') }}</label>
								<b-row class="ml-0">
									<div
										@click="onOpenUrl(getImage(profile.signatureId, 'signatureId'))"
										class="d-flex justify-content-center align-items-center cursor-pointer"
										v-if="checkFile(profile.signatureId)"
									>
										<b-icon icon="file-earmark-pdf-fill" :font-scale="1.6" />
									</div>
									<b-img
										v-else
										v-bind="imgPropSignatureId"
										rounded="circle"
										alt="Signature"
										class="cursor-pointer"
										:src="getImage(profile.signatureId, 'signatureId')"
										@click="showImage(profile.signatureId)"
									></b-img>
								</b-row>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label for="idNumber">{{ FormMSG(99, 'ID Name') }}</label>
								<b-form-input
									v-model="profile.idName"
									type="text"
									id="idNumber"
									:placeholder="FormMSG(100, 'Enter your ID Name ...')"
								></b-form-input>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label for="idNumber">{{ FormMSG(34, 'ID Number') }}</label>
								<b-form-input
									v-model="profile.idNumber"
									type="text"
									id="idNumber"
									:placeholder="FormMSG(35, 'Enter your ID Number ...')"
								></b-form-input>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label for="pictureId">{{ FormMSG(101, 'ID picture - Front') }}</label>
								<b-row class="ml-0">
									<div
										@click="onOpenUrl(getImage(profile.pictureId, 'pictureId'))"
										class="d-flex justify-content-center align-items-center cursor-pointer"
										v-if="checkFile(profile.pictureId)"
									>
										<b-icon icon="file-earmark-pdf-fill" :font-scale="1.6" />
									</div>
									<b-img
										v-else
										v-bind="imgPropPictureId"
										rounded="circle"
										alt="ID picture - front"
										class="cursor-pointer"
										:src="getImage(profile.pictureId, 'pictureId')"
										@click="showImage(profile.pictureId)"
									/>
								</b-row>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label for="pictureIdVerso">{{ FormMSG(102, 'ID picture - Back') }}</label>
								<b-row class="ml-0">
									<div
										@click="onOpenUrl(getImage(profile.pictureIdVerso, 'pictureIdVerso'))"
										class="d-flex justify-content-center align-items-center cursor-pointer"
										v-if="checkFile(profile.pictureIdVerso)"
									>
										<b-icon icon="file-earmark-pdf-fill" :font-scale="1.6" />
									</div>
									<b-img
										v-else
										v-bind="imgPropPictureIdVerso"
										rounded="circle"
										alt="ID picture - back"
										class="cursor-pointer"
										:src="getImage(profile.pictureIdVerso, 'pictureIdVerso')"
										@click="showImage(profile.pictureIdVerso)"
									/>
								</b-row>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label>{{ FormMSG(36, 'Driver licence') }}</label>
								<b-form-input v-model="profile.driverLicense" type="text" :placeholder="FormMSG(37, 'Enter your driver license')" />
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label>{{ FormMSG(1314, 'Driver licence Number') }}</label>
								<b-form-input
									v-model="profile.driverLicenseNumber"
									type="text"
									:placeholder="FormMSG(1316, 'Enter your driver license number')"
									@keydown.enter.prevent
								/>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label for="idNumber">{{ FormMSG(103, 'Driver license picture - Front') }}</label>
								<b-row class="ml-0">
									<div
										@click="onOpenUrl(getImage(profile.pictureDriverLicense, 'pictureDriverLicense'))"
										class="d-flex justify-content-center align-items-center cursor-pointer"
										v-if="checkFile(profile.pictureDriverLicense)"
									>
										<b-icon icon="file-earmark-pdf-fill" :font-scale="1.6" />
									</div>
									<b-img
										v-else
										v-bind="imgPropPictureDriverLicense"
										rounded
										alt="Driver license picture - Front"
										class="cursor-pointer"
										:src="getImage(profile.pictureDriverLicense, 'pictureDriverLicense')"
										@click="showImage(profile.pictureDriverLicense)"
									/>
								</b-row>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label for="idNumber">{{ FormMSG(104, 'Driver license picture - Back') }}</label>
								<b-row class="ml-0">
									<div
										@click="onOpenUrl(getImage(profile.pictureDriverLicenseVerso, 'pictureDriverLicenseVerso'))"
										class="d-flex justify-content-center align-items-center cursor-pointer"
										v-if="checkFile(profile.pictureDriverLicenseVerso)"
									>
										<b-icon icon="file-earmark-pdf-fill" :font-scale="1.6" />
									</div>
									<b-img
										v-else
										v-bind="imgPropPictureDriverLicenseVerso"
										rounded
										alt="Driver license picture - Front"
										class="cursor-pointer"
										:src="getImage(profile.pictureDriverLicenseVerso, 'pictureDriverLicenseVerso')"
										@click="showImage(profile.pictureDriverLicenseVerso)"
									/>
								</b-row>
							</b-form-group>
						</b-col>
					</b-row>
				</div>
			</b-collapse>
		</div>
		<div class="card-collapsible mt-3">
			<div class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center" @click="handleClickHeader('finance')">
				<div class="title w-90">
					<component :is="getLucideIcon('Wallet')" color="rgba(6, 38, 62, 0.84)" :size="22" />
					<span class="ml-3">{{ FormMSG(1244, 'Finance') }}</span>
				</div>
				<div class="d-flex justify-content-end w-10">
					<component v-if="!configAccordion['finance'].isVisible" :is="getIconLucide('ChevronDown')" color="#06263E" :size="22" />
					<component v-if="configAccordion['finance'].isVisible" :is="getIconLucide('ChevronUp')" color="#06263E" :size="22" />
				</div>
			</div>
			<b-collapse :visible="configAccordion['finance'].isVisible">
				<div class="sub-title">
					{{ FormMSG(9000, 'Bank information') }}
				</div>
				<div class="content-card-collapsible">
					<b-row>
						<b-col sm="12" md="12" :lg="isDeterProject ? '4' : '6'" :xl="isDeterProject ? '4' : '6'">
							<b-form-group label-for="iban" :label="FormMSG(4900, 'IBAN')" :class="{ 'is-warning': userBankWarning.iban }">
								<b-form-input
									v-model="profile.iban"
									type="text"
									id="iban"
									:placeholder="this.FormMSG(5000, 'Enter your bank account number')"
									@input="handleSupplierIbanChange"
								/>
								<div v-if="userBankWarning.iban" class="warning-feedback">
									{{ FormMSG(97986, "IBAN seems not valid, please make sure it's corrrect") }}
								</div>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="6" xl="6">
							<b-form-group label-for="bic" :label="FormMSG(5100, 'BIC')" :class="{ 'is-warning': userBankWarning.bic }">
								<b-form-input
									v-model="profile.bic"
									type="text"
									id="bic"
									:placeholder="FormMSG(5200, 'Enter your bank BIC')"
									@input="handleSupplierBicChange"
								/>
								<div v-if="userBankWarning.bic" class="warning-feedback">
									{{ FormMSG(909097986, "BIC seems not valid, please make sure it's corrrect") }}
								</div>
							</b-form-group>
						</b-col>
						<!-- <b-col v-if="isDeterProject" sm="12" md="12" lg="4" xl="4">
							<b-form-group>
								<label for="rib">{{ FormMSG(15010, 'Rib') }}</label>
								<b-row class="ml-0">
									<b-img
										v-bind="imgPropPictureRib"
										rounded
										alt="rib"
										class="cursor-pointer"
										:src="getImage(profile.pictureRib, 'pictureRib')"
										@click="showImage(profile.pictureRib)"
										:title="FormMSG(254140, 'View Image')"
									></b-img>
								</b-row>
							</b-form-group>
						</b-col> -->
					</b-row>
					<b-row>
						<b-col sm="12" md="12" lg="6" xl="6">
							<b-form-group>
								<label for="bic">{{ this.FormMSG(1245, 'Bank account owner name') }}</label>
								<b-form-input
									v-model="profile.bankAccountOwnerName"
									type="text"
									id="bic"
									:placeholder="this.FormMSG(1246, 'Enter your bank account owner name')"
								></b-form-input>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="6" xl="6">
							<b-form-group>
								<label for="bankDetailDocument">{{ FormMSG(1247, 'Bank detail document') }}</label>
								<b-row class="ml-0">
									<div
										@click="onOpenUrl(getImage(profile.bankDetailXid, 'bankDetailXid'))"
										class="d-flex justify-content-center align-items-center cursor-pointer"
										v-if="checkFile(profile.bankDetailXid)"
									>
										<b-icon icon="file-earmark-pdf-fill" :font-scale="1.6" />
									</div>
									<b-img
										v-else
										v-bind="imgPropSignatureId"
										rounded
										alt="Bank detail document"
										class="cursor-pointer"
										:src="getImage(profile.bankDetailXid, 'bankDetailXid')"
										@click="showImage(profile.bankDetailXid)"
										:title="FormMSG(254, 'View Image')"
									></b-img>
								</b-row>
							</b-form-group>
						</b-col>
					</b-row>
				</div>
				<div class="divider"></div>
				<div class="sub-title">
					{{ FormMSG(26100, 'Taxes') }}
				</div>
				<div class="content-card-collapsible">
					<b-row>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group :label="FormMSG(26101, 'Taxe')">
								<b-input-group>
									<b-form-input v-model="profile.taxesPercentage" type="number" step="1" min="0" max="100" v-limit-100 />
									<b-input-group-append>
										<b-input-group-text style="background-color: #00a09c">
											<component :is="getIconLucide('Percent')" color="#ffffff" :size="16" :stroke-width="3.5" />
										</b-input-group-text>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label for="bic">{{ this.FormMSG(1248, 'Tax declaration country') }}</label>
								<b-form-input
									v-model="profile.taxCountry"
									type="text"
									id="taxCountry"
									:placeholder="this.FormMSG(1249, 'Enter your tax declaration country')"
								></b-form-input>
							</b-form-group>
						</b-col>
					</b-row>
				</div>
			</b-collapse>
		</div>
		<!-- Old Address-->
		<!-- <div class="card-collapsible mt-3">
			<div class="header-card-collapsible d-flex flex-row align-items-center cursor-pointer" @click="handleClickHeader('address')">
				<div class="title w-90">
					<b-icon icon="geo-fill" style="color: rgba(6, 38, 62, 0.84)" /><span class="ml-3">{{ FormMSG(43, 'Address') }}</span>
				</div>
				<div class="d-flex justify-content-end w-10">
					<component v-if="!configAccordion['address'].isVisible" :is="getIconLucide('ChevronDown')" color="#06263E" :size="22" />
					<component v-if="configAccordion['address'].isVisible" :is="getIconLucide('ChevronUp')" color="#06263E" :size="22" />
				</div>
			</div>
			<b-collapse :visible="configAccordion['address'].isVisible" v-if="profile.address">
				<div class="content-card-collapsible">
					<b-row>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label for="country">{{ FormMSG(44, 'Country') }}</label>
								<b-form-input v-model="profile.address.country" type="text" id="country" :disabled="disabled"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label for="state">{{ FormMSG(45, 'State') }}</label>
								<b-form-input v-model="profile.address.state" type="text" id="state"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label for="city">{{ FormMSG(46, 'City') }}</label>
								<b-form-input v-model="profile.address.city" type="text" id="city"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label for="zip">{{ FormMSG(47, 'Postal Code') }}</label>
								<b-form-input v-model="profile.address.zip" type="text" id="zip"></b-form-input>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col sm="12" md="12" lg="6" xl="6">
							<b-form-group>
								<label for="address">{{ FormMSG(48, 'Street') }}</label>
								<b-form-input
									v-model="profile.address.street"
									type="text"
									id="street"
									:placeholder="FormMSG(49, 'Enter your street')"
								></b-form-input>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label for="number">{{ FormMSG(50, 'Number') }}</label>
								<b-form-input v-model="profile.address.number" type="text" id="number"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label for="box">{{ FormMSG(51, 'Box') }}</label>
								<b-form-input v-model="profile.address.box" type="text" id="box"></b-form-input>
							</b-form-group>
						</b-col>
					</b-row>
				</div>
			</b-collapse>
		</div> -->
		<!-- New Address -->
		<div class="card-collapsible mt-3">
			<div class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center" @click="handleClickHeader('address')">
				<div class="title w-90">
					<b-icon icon="geo-fill" style="color: rgba(6, 38, 62, 0.84)" /><span class="ml-3">{{ FormMSG(43, 'Address') }}</span>
				</div>
				<div class="d-flex justify-content-end w-10">
					<component v-if="!configAccordion['address'].isVisible" :is="getIconLucide('ChevronDown')" color="#06263E" :size="22" />
					<component v-if="configAccordion['address'].isVisible" :is="getIconLucide('ChevronUp')" color="#06263E" :size="22" />
				</div>
			</div>
			<b-collapse :visible="configAccordion['address'].isVisible" v-if="profile.address">
				<div class="sub-title">
					{{ FormMSG(1250, 'Legal address') }}
				</div>
				<div class="content-card-collapsible">
					<b-row>
						<b-col sm="12" md="12" lg="6" xl="6">
							<b-form-group>
								<label for="country">{{ FormMSG(1061010, 'Country') }}</label>
								<v-select
									:clearable="false"
									v-model="countryKey"
									:options="countries"
									label="text"
									:reduce="(option) => option.value"
									@input="getCountry"
								/>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="6" xl="6">
							<b-form-group>
								<google-auto-complete
									ref="google-search-address"
									:label="FormMSG(11402, 'Internet search')"
									:country="countryCode"
									@placechanged="(item) => handleAutoComplete(item, 'legalAddress')"
								/>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col sm="10" md="10" lg="6" xl="6">
							<b-form-group>
								<label for="address">{{ this.FormMSG(513100, 'Street') }}</label>
								<b-form-input
									v-model="profile.address.street"
									type="text"
									id="street"
									:placeholder="FormMSG(1103, 'Enter your street')"
								></b-form-input>
							</b-form-group>
						</b-col>
						<b-col sm="2" md="2" lg="3" xl="3">
							<b-form-group>
								<label for="number">{{ this.FormMSG(155101, 'Number') }}</label>
								<b-form-input v-model="profile.address.number" type="text" id="number"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label for="box">{{ this.FormMSG(102123, 'Box') }}</label>
								<b-form-input v-model="profile.address.box" type="text" id="box"></b-form-input>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label for="zip">{{ this.FormMSG(104120, 'Postal Code') }}</label>
								<b-form-input v-model="profile.address.zip" type="text" id="zip"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label for="city">{{ this.FormMSG(10103, 'City') }}</label>
								<b-form-input v-model="profile.address.city" type="text" id="city"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label for="state">{{ FormMSG(110205, 'State') }}</label>
								<b-form-input v-model="profile.address.state" type="text" id="state"></b-form-input>
							</b-form-group>
						</b-col>
					</b-row>
				</div>
				<b-collapse v-model="isShowPostalAddress">
					<div class="sub-title">
						{{ FormMSG(1312, 'Postal address') }}
					</div>
					<div class="content-card-collapsible">
						<b-row>
							<b-col sm="12" md="12" lg="6" xl="6">
								<b-form-group>
									<label for="country">{{ FormMSG(1061010, 'Country') }}</label>
									<v-select
										:clearable="false"
										v-model="countryKeyPostalAddress"
										:options="countries"
										label="text"
										:reduce="(option) => option.value"
										@input="getCountryPostalAddress"
									/>
								</b-form-group>
							</b-col>
							<b-col sm="12" md="12" lg="6" xl="6">
								<b-form-group>
									<google-auto-complete
										ref="google-search-address"
										:label="FormMSG(11402, 'Internet search')"
										:country="countryCodePostalAddress"
										@placechanged="(item) => handleAutoComplete(item, 'postalAddress')"
									/>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col sm="10" md="10" lg="6" xl="6">
								<b-form-group>
									<label for="address">{{ this.FormMSG(513100, 'Street') }}</label>
									<b-form-input
										v-model="profile.postalAddress.street"
										type="text"
										id="street"
										:placeholder="FormMSG(1103, 'Enter your street')"
									></b-form-input>
								</b-form-group>
							</b-col>
							<b-col sm="2" md="2" lg="3" xl="3">
								<b-form-group>
									<label for="number">{{ this.FormMSG(155101, 'Number') }}</label>
									<b-form-input v-model="profile.postalAddress.number" type="text" id="number"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col sm="12" md="12" lg="3" xl="3">
								<b-form-group>
									<label for="box">{{ this.FormMSG(102123, 'Box') }}</label>
									<b-form-input v-model="profile.postalAddress.box" type="text" id="box"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col sm="12" md="12" lg="3" xl="3">
								<b-form-group>
									<label for="zip">{{ this.FormMSG(104120, 'Postal Code') }}</label>
									<b-form-input v-model="profile.postalAddress.zip" type="text" id="zip"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col sm="12" md="12" lg="3" xl="3">
								<b-form-group>
									<label for="city">{{ this.FormMSG(10103, 'City') }}</label>
									<b-form-input v-model="profile.postalAddress.city" type="text" id="city"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col sm="12" md="12" lg="3" xl="3">
								<b-form-group>
									<label for="state">{{ FormMSG(110205, 'State') }}</label>
									<b-form-input v-model="profile.postalAddress.state" type="text" id="state"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>
					</div>
				</b-collapse>
				<b-row>
					<div style="margin-left: 28px">
						<b-button
							size="sm"
							:variant="isShowPostalAddress ? 'outline-danger' : 'outline-secondary'"
							class="d-flex flex-row justify-content-center gap-2 align-items-center w-auto h-auto"
							style="height: 40px; border-radius: 0.5rem; background-color: #fff; cursor: pointer"
							@click="isShowPostalAddress = !isShowPostalAddress"
						>
							<div class="d-flex flex-row align-items-center justify-content-center btn-address" style="color: #06263e">
								<span v-if="isShowPostalAddress">
									<component :is="getLucideIcon(ICONS.X_CLEAR.name)" :color="ICONS.X_CLEAR.color" :size="22" />
									{{ FormMSG(1313, 'Close') }}
								</span>
								<span v-else>
									<component :is="getLucideIcon('PlusCircle')" color="#47C7BF" :size="22" />
									{{ FormMSG(1251, 'Add postal address (if different)') }}
								</span>
							</div>
						</b-button>
					</div>
				</b-row>
			</b-collapse>
		</div>
		<!-- Health -->
		<div class="card-collapsible mt-3">
			<div class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center" @click="handleClickHeader('health')">
				<div class="title w-90">
					<component :is="getLucideIcon('HeartPulse')" color="rgba(6, 38, 62, 0.84)" :size="22" />
					<span class="ml-3">{{ FormMSG(1252, 'Health') }}</span>
				</div>
				<div class="d-flex justify-content-end w-10">
					<component v-if="!configAccordion['health'].isVisible" :is="getIconLucide('ChevronDown')" color="#06263E" :size="22" />
					<component v-if="configAccordion['health'].isVisible" :is="getIconLucide('ChevronUp')" color="#06263E" :size="22" />
				</div>
			</div>
			<b-collapse :visible="configAccordion['health'].isVisible">
				<div class="content-card-collapsible">
					<b-row>
						<b-col sm="12" md="12" lg="4" xl="4">
							<b-form-group :label="FormMSG(54, 'Social Security Number')">
								<b-form-input
									v-model="profile.socSecNumber"
									:class="{ 'is-warning': isSocSecNumberWarning }"
									:placeholder="FormMSG(154, 'Enter your Social Security Number')"
									@input="handleSocSecNumberChange"
									@keydown.enter.prevent
								/>
								<div v-if="isSocSecNumberWarning" class="warning-feedback">
									{{ FormMSG(8979997, 'The social security number seems not valid') }}
								</div>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="4" xl="4">
							<b-form-group>
								<label for="socialSecurityCardXid">{{ FormMSG(1255, 'Social security card') }}</label>
								<b-row class="ml-0">
									<div
										@click="onOpenUrl(getImage(profile.socialSecurityCardXid, 'socialSecurityCardXid'))"
										class="d-flex justify-content-center align-items-center cursor-pointer"
										v-if="checkFile(profile.socialSecurityCardXid)"
									>
										<b-icon icon="file-earmark-pdf-fill" font-scale="2" />
									</div>
									<b-img
										v-else
										v-bind="imgPropSignatureId"
										rounded
										alt="socialSecurityCardXid"
										class="cursor-pointer"
										:src="getImage(profile.socialSecurityCardXid, 'socialSecurityCardXid')"
										@click="showImage(profile.socialSecurityCardXid)"
										:title="FormMSG(254, 'View Image')"
									></b-img>
									<button
										class="btn-transparent pl-0 ml-2"
										@click.prevent.stop="deleteImg('socialSecurityCardXid')"
										v-if="profile.socialSecurityCardXid && profile.socialSecurityCardXid.length > 0"
									>
										<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
									</button>
								</b-row>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="4" xl="4">
							<b-form-group>
								<label for="inscuranceCertificate">{{ FormMSG(1256, 'Insurance certificate') }}</label>
								<b-row class="ml-0">
									<div
										@click="onOpenUrl(getImage(profile.insuranceCertificateXid, 'insuranceCertificateXid'))"
										class="d-flex justify-content-center align-items-center cursor-pointer"
										v-if="checkFile(profile.insuranceCertificateXid)"
									>
										<b-icon icon="file-earmark-pdf-fill" font-scale="2" />
									</div>
									<b-img
										v-else
										v-bind="imgPropSignatureId"
										rounded
										alt="inscuranceCertificate"
										class="cursor-pointer"
										:src="getImage(profile.insuranceCertificateXid, 'insuranceCertificateXid')"
										@click="showImage(profile.insuranceCertificateXid)"
										:title="FormMSG(254, 'View Image')"
									></b-img>
									<button
										class="btn-transparent pl-0 ml-2"
										@click.prevent.stop="deleteImg('insuranceCertificateXid')"
										v-if="profile.insuranceCertificateXid && profile.insuranceCertificateXid.length > 0"
									>
										<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
									</button>
								</b-row>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col sm="12" md="12" lg="4" xl="4">
							<b-form-group>
								<label for="socialSecurityCenter">{{ this.FormMSG(1700, 'Social security center') }}</label>
								<b-form-input
									v-model="profile.socialSecurityCenter"
									type="text"
									id="socialSecurityCenter"
									:placeholder="this.FormMSG(1258, 'Enter your social security center')"
									@keydown.enter.prevent
								></b-form-input>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="8" xl="8">
							<b-form-group :label="FormMSG(1259, 'Social security center address')">
								<v-address
									:params="{ title: 'Social security center address' }"
									:address-id="profile.socialSecurityCenterAddressId"
									@change="handleDynamicAddressChange"
									@delete="handleDynamicAddressDelete"
									@address-maplink-change="handleAddressMapLinkChange"
								/>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label>{{ FormMSG(1260, 'Last medical visit date') }}</label>
								<b-form-input
									type="date"
									:value="displayDateLastMedicalVisit"
									:placeholder="FormMSG(1295, 'DD/MM/YYYY')"
									@input="(e) => updateDate(e, 'lastMedicalVisit')"
									@keydown.enter.prevent
								/>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label>{{ FormMSG(1262, 'Medical visit validity') }}</label>
								<b-form-input
									type="date"
									:value="displayDateMedicalVisitValidity"
									:placeholder="FormMSG(1295, 'DD/MM/YYYY')"
									@input="(e) => updateDate(e, 'medicalVisitValidity')"
									@keydown.enter.prevent
								/>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label for="medicalVisitDocument">{{ FormMSG(1264, 'Medical visit document') }}</label>
								<b-row class="ml-0">
									<div
										@click="onOpenUrl(getImage(profile.medicalVisitDocumentXid, 'medicalVisitDocumentXid'))"
										class="d-flex justify-content-center align-items-center cursor-pointer"
										v-if="checkFile(profile.medicalVisitDocumentXid)"
									>
										<b-icon icon="file-earmark-pdf-fill" font-scale="2" />
									</div>
									<b-img
										v-else
										v-bind="imgPropSignatureId"
										v-model="profile.medicalVisitDocumentXid"
										rounded
										alt="medicalVisitDocument"
										class="cursor-pointer"
										:src="getImage(profile.medicalVisitDocumentXid, 'medicalVisitDocumentXid')"
										@click="showImage(profile.medicalVisitDocumentXid)"
										:title="FormMSG(254, 'View Image')"
									></b-img>
									<button
										class="btn-transparent pl-0 ml-2"
										@click.prevent.stop="deleteImg('medicalVisitDocumentXid')"
										v-if="profile.medicalVisitDocumentXid && profile.medicalVisitDocumentXid.length > 0"
									>
										<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
									</button>
									&nbsp;
									<div class="d-flex align-items-center" v-if="isDeterAction && profile.medicalVisitDocumentXid.length === 0">
										<b-button size="sm" class="btn bg-transparent border-0" v-b-tooltip.hover.bottom.html="`Medical visit document empty`">
											<component :is="getIconLucide(ICONS.ALERT_TRIANGLE.name)" color="#ffc107" :size="30" />
										</b-button>
									</div>
								</b-row>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col sm="12" md="12" lg="3" xl="3" class="d-flex justify-content-start">
							<b-form-group class="text-start">
								<label>{{ FormMSG(1265, 'Worker with a disability') }}</label>
								<b-form-checkbox class="d-flex justify-content-start" size="lg" v-model="profile.disabled" switch>
									<span class="text-redired">{{ profile.disabled ? FormMSG(1276, 'Yes') : FormMSG(1277, 'No') }}</span>
								</b-form-checkbox>
							</b-form-group>
						</b-col>
						<b-col v-if="profile.disabled" sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label>{{ FormMSG(1266, 'Disability notification date') }}</label>
								<b-form-input
									type="date"
									:value="displayDateDisabilityNotification"
									:placeholder="FormMSG(1295, 'DD/MM/YYYY')"
									@input="(e) => updateDate(e, 'disabilityNotification')"
									@keydown.enter.prevent
								/>
							</b-form-group>
						</b-col>
						<b-col v-if="profile.disabled" sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label for="type">{{ this.FormMSG(1268, 'Type') }}</label>
								<b-form-input
									v-model="profile.disabilityType"
									type="text"
									id="type"
									:placeholder="this.FormMSG(1269, 'Enter type')"
									@keydown.enter.prevent
								></b-form-input>
							</b-form-group>
						</b-col>
						<b-col v-if="profile.disabled" sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label for="status">{{ this.FormMSG(1270, 'Status') }}</label>
								<b-form-input
									v-model="profile.disabilityStatus"
									type="text"
									id="status"
									:placeholder="this.FormMSG(1271, 'Enter status')"
									@keydown.enter.prevent
								></b-form-input>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col v-if="profile.disabled" sm="12" md="12" lg="3" xl="3">
							<b-form-group :label="FormMSG(1272, 'Invalidity')">
								<b-form-select v-model="profile.invalidity">
									<b-form-select-option v-for="(gender, index) in menuInvalidity" :key="index" :value="gender.value">
										{{ gender.text }}
									</b-form-select-option>
								</b-form-select>
							</b-form-group>
						</b-col>
						<b-col v-if="profile.disabled" sm="12" md="12" lg="3" xl="3" class="d-flex align-items-center mt-2">
							<b-form-checkbox v-model="profile.rqth" size="lg" :value="true" class="d-flex align-items-center">
								<div class="mt-1">{{ FormMSG(1273, 'RQTH') }}</div>
							</b-form-checkbox>
						</b-col>
						<b-col v-if="profile.disabled" sm="12" md="12" lg="3" xl="3">
							<b-form-group :label="FormMSG(1274, 'Invalidity percentage')">
								<b-input-group>
									<b-form-input
										v-model="profile.invalidityPourcentage"
										type="number"
										step="1"
										min="0"
										max="100"
										v-limit-100
										@keydown.enter.prevent
									/>
									<b-input-group-append>
										<b-input-group-text style="background-color: #00a09c">
											<component :is="getIconLucide('Percent')" color="#ffffff" :size="16" :stroke-width="3.5" />
										</b-input-group-text>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label>{{ FormMSG(25, 'Have Allergies?') }}</label>
								<b-form-checkbox size="lg" v-model="haveAllergies" @change="handleChangeHaveAllergies" switch />
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label>{{ FormMSG(26, 'Allergies details') }}</label>
								<b-form-textarea
									v-model="profile.allergies"
									type="textarea"
									rows="2"
									:placeholder="FormMSG(6, 'Enter allergies details...')"
									autocomplete="off"
									:disabled="haveAllergies === false"
								/>
							</b-form-group>
						</b-col>
					</b-row>
				</div>
			</b-collapse>
		</div>
		<!-- Family-->
		<div class="card-collapsible mt-3">
			<div class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center" @click="handleClickHeader('family')">
				<div class="title w-90">
					<b-icon icon="people-fill" style="color: rgba(6, 38, 62, 0.84)" /><span class="ml-3">{{ FormMSG(52, 'Family') }}</span>
				</div>
				<div class="d-flex justify-content-end w-10">
					<component v-if="!configAccordion['family'].isVisible" :is="getIconLucide('ChevronDown')" color="#06263E" :size="22" />
					<component v-if="configAccordion['family'].isVisible" :is="getIconLucide('ChevronUp')" color="#06263E" :size="22" />
				</div>
			</div>
			<b-collapse :visible="configAccordion['family'].isVisible">
				<div class="sub-title">
					{{ FormMSG(53, 'Family composition') }}
				</div>
				<div class="content-card-collapsible">
					<b-row>
						<b-col sm="12" md="12" lg="4" xl="4">
							<b-form-group :label="FormMSG(54, 'Civil status')" label-for="optionsForCivilStatus">
								<b-form-select v-model="profile.civilStatus" :options="optionsForCivilStatus" id="optionsForCivilStatus"></b-form-select>
							</b-form-group>
						</b-col>
						<b-col v-if="profile.civilStatus === 3" sm="12" md="12" lg="4" xl="4">
							<b-form-group>
								<label>{{ FormMSG(55, 'Spouse first name') }}</label>
								<b-form-input v-model="profile.spouseFirstName" type="text" :placeholder="FormMSG(56, 'Enter your Spouse first name ')" />
							</b-form-group>
						</b-col>
						<b-col v-if="profile.civilStatus === 3" sm="12" md="12" lg="4" xl="4">
							<b-form-group>
								<label>{{ FormMSG(57, 'Spouse name') }}</label>
								<b-form-input v-model="profile.spouseName" type="text" :placeholder="FormMSG(58, 'Enter your Spouse name ')" />
							</b-form-group>
						</b-col>
					</b-row>
					<b-row v-if="profile.civilStatus === 3">
						<b-col sm="12" md="12" lg="4" xl="4">
							<b-form-group>
								<label>{{ FormMSG(59, 'Spouse birth date') }}</label>
								<b-form-input
									type="date"
									:value="displaySpouseBirthDate"
									:placeholder="FormMSG(60, 'Enter your Spouse birth date ')"
									@input="updateSpouseBirthDate"
								/>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="4" xl="4">
							<b-form-group class="text-left">
								<label>{{ FormMSG(61, 'Spouse has salary') }}</label>
								<b-form-checkbox size="lg" v-model="profile.spouseHasSalary" switch />
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="4" xl="4">
							<b-form-group>
								<label>{{ FormMSG(62, 'Spouse profession') }}</label>
								<b-form-input v-model="profile.spouseProfession" type="text" :placeholder="FormMSG(63, 'Enter your Spouse profession ')" />
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group :label="FormMSG(64, 'Number of dependent child')">
								<b-form-input v-model="profile.numberOfDependentChild" type="number" placeholder="0" />
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group :label="FormMSG(65, 'Number of disabled child')">
								<b-form-input v-model="profile.numberOfDisabledChild" type="number" placeholder="0" />
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group :label="FormMSG(66, 'Number of dependent person')">
								<b-form-input v-model="profile.numberOfDependentPerson" type="number" placeholder="0" />
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group :label="FormMSG(67, 'Number of disabled person')">
								<b-form-input v-model="profile.numberOfDisabledPerson" type="number" placeholder="0" />
							</b-form-group>
						</b-col>
					</b-row>
				</div>
				<div class="divider" />
				<div class="sub-title">
					{{ FormMSG(68, 'Emergency contact') }}
				</div>
				<div class="content-card-collapsible">
					<b-row>
						<b-col sm="12" md="12" lg="6" xl="6">
							<b-form-group :label="FormMSG(69, 'Person to contact')">
								<b-form-input v-model="profile.personToContact" type="text" :placeholder="FormMSG(70, 'Enter person to contact')" />
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="6" xl="6">
							<b-form-group :label="FormMSG(71, 'Person to contact phone')">
								<b-form-input v-model="profile.personToContactPhone" type="text" :placeholder="FormMSG(72, 'Enter person to contact phone')" />
							</b-form-group>
						</b-col>
					</b-row>
				</div>
			</b-collapse>
		</div>
		<!-- Personal Vehicle -->
		<div class="card-collapsible mt-3" v-if="profile.vehicule">
			<div class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center" @click="handleClickHeader('personal-vehicle')">
				<div class="title w-90">
					<!-- <b-icon icon="car-front-fill" style="color: rgba(6, 38, 62, 0.84)" /> -->
					<!-- <i class="bi bi-car-front"></i> -->
					<span>
						<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1.15em" fill="rgba(6, 38, 62, 0.84)" viewBox="0 0 16 16">
							<path
								d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM3 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM2.906 5.189a.51.51 0 0 0 .497.731c.91-.073 3.35-.17 4.597-.17 1.247 0 3.688.097 4.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 11.691 3H4.309a.5.5 0 0 0-.447.276L2.906 5.19Z"
							/>
						</svg>
					</span>
					<span class="ml-3">{{ FormMSG(1226, 'Personal vehicle') }}</span>
				</div>
				<div class="d-flex justify-content-end w-10">
					<component v-if="!configAccordion['personal-vehicle'].isVisible" :is="getIconLucide('ChevronDown')" color="#06263E" :size="22" />
					<component v-if="configAccordion['personal-vehicle'].isVisible" :is="getIconLucide('ChevronUp')" color="#06263E" :size="22" />
				</div>
			</div>
			<b-collapse :visible="configAccordion['personal-vehicle'].isVisible">
				<div class="content-card-collapsible">
					<b-row>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group :label="FormMSG(1228, 'Name')">
								<b-form-input v-model="profile.vehicule.name" type="text" :placeholder="FormMSG(1229, 'Vehicle name ...')" />
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group :label="FormMSG(1230, 'Brand')">
								<b-form-input v-model="profile.vehicule.brand" type="text" :placeholder="FormMSG(1231, 'Vehicle brand ...')" />
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group :label="FormMSG(1232, 'Model')">
								<b-form-input v-model="profile.vehicule.model" type="text" :placeholder="FormMSG(1233, 'Vehicle model ...')" />
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group :label="FormMSG(1234, 'Number plate')">
								<b-form-input v-model="profile.vehicule.numberPlate" type="text" :placeholder="FormMSG(1235, 'Your number plate ...')" />
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col sm="12" md="12" lg="4" xl="4">
							<b-form-group :label="FormMSG(1236, 'Type')">
								<b-form-select v-model="profile.vehicule.type" :options="vehicleTypes" />
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="4" xl="4">
							<b-form-group :label="FormMSG(1237, 'Energy')">
								<b-form-select v-model="profile.vehicule.energy" :options="vehicleEnergies" />
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="4" xl="4">
							<b-form-group>
								<label>{{ FormMSG(1238, 'Kg CO2 per') }} {{ distanceUnit }}</label>
								<b-form-input v-model="profile.vehicule.kgCoTwoPerKm" type="number" step="0.01" min="0" placeholder="0" />
							</b-form-group>
						</b-col>
					</b-row>
				</div>
			</b-collapse>
		</div>

		<!-- Personal vehicle -->
		<div class="card-collapsible mt-3" v-if="isPme && profile.vehicule">
			<div class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center" @click="handleClickHeader('personal-vehicle')">
				<div class="title w-90">
					<!-- <b-icon icon="car-front-fill" style="color: rgba(6, 38, 62, 0.84)" /> -->
					<!-- <i class="bi bi-car-front"></i> -->
					<span>
						<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1.15em" fill="rgba(6, 38, 62, 0.84)" viewBox="0 0 16 16">
							<path
								d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM3 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM2.906 5.189a.51.51 0 0 0 .497.731c.91-.073 3.35-.17 4.597-.17 1.247 0 3.688.097 4.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 11.691 3H4.309a.5.5 0 0 0-.447.276L2.906 5.19Z"
							/>
						</svg>
					</span>
					<span class="ml-3">{{ FormMSG(1226, 'Personal vehicle') }}</span>
				</div>
				<div class="d-flex justify-content-end w-10">
					<component v-if="!configAccordion['personal-vehicle'].isVisible" :is="getIconLucide('ChevronDown')" color="#06263E" :size="22" />
					<component v-if="configAccordion['personal-vehicle'].isVisible" :is="getIconLucide('ChevronUp')" color="#06263E" :size="22" />
				</div>
			</div>
			<b-collapse :visible="configAccordion['personal-vehicle'].isVisible">
				<div class="content-card-collapsible">
					<b-row>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group :label="FormMSG(1228, 'Name')">
								<b-form-input v-model="profile.vehicule.name" type="text" :placeholder="FormMSG(1229, 'Vehicle name ...')" />
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group :label="FormMSG(1230, 'Brand')">
								<b-form-input v-model="profile.vehicule.brand" type="text" :placeholder="FormMSG(1231, 'Vehicle brand ...')" />
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group :label="FormMSG(1232, 'Model')">
								<b-form-input v-model="profile.vehicule.model" type="text" :placeholder="FormMSG(1233, 'Vehicle model ...')" />
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group :label="FormMSG(1234, 'Number plate')">
								<b-form-input v-model="profile.vehicule.numberPlate" type="text" :placeholder="FormMSG(1235, 'Your number plate ...')" />
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col sm="12" md="12" lg="4" xl="4">
							<b-form-group :label="FormMSG(1236, 'Type')">
								<b-form-select v-model="profile.vehicule.type" :options="vehicleTypes" />
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="4" xl="4">
							<b-form-group :label="FormMSG(1237, 'Energy')">
								<b-form-select v-model="profile.vehicule.energy" :options="vehicleEnergies" />
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="4" xl="4">
							<b-form-group>
								<label>{{ FormMSG(1238, 'Kg CO2 per') }} {{ distanceUnit }}</label>
								<b-form-input v-model="profile.vehicule.kgCoTwoPerKm" type="number" step="0.01" min="0" placeholder="0" />
							</b-form-group>
						</b-col>
					</b-row>
				</div>
			</b-collapse>
		</div>
		<!-- Other-->
		<div class="card-collapsible mt-3">
			<div class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center" @click="handleClickHeader('other')">
				<div class="title w-90">
					<b-icon icon="list" style="color: rgba(6, 38, 62, 0.84)" /><span class="ml-3">{{ FormMSG(73, 'Other') }}</span>
				</div>
				<div class="d-flex justify-content-end w-10">
					<component v-if="!configAccordion['other'].isVisible" :is="getIconLucide('ChevronDown')" color="#06263E" :size="22" />
					<component v-if="configAccordion['other'].isVisible" :is="getIconLucide('ChevronUp')" color="#06263E" :size="22" />
				</div>
			</div>
			<b-collapse :visible="configAccordion['other'].isVisible">
				<div class="sub-title">
					{{ FormMSG(17, 'Privacy options') }}
				</div>
				<div class="content-card-collapsible">
					<b-row>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-checkbox size="lg" class="pj-cb pb-1" v-model="profile.showMyEmailInCrew">{{
								FormMSG(18, 'Show my email in crew list')
							}}</b-form-checkbox>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-checkbox size="lg" class="pj-cb pb-1" v-model="profile.showMyPhoneInCrew">{{
								FormMSG(19, 'Show my phone in crew list')
							}}</b-form-checkbox>
						</b-col>
					</b-row>
				</div>
				<div class="divider"></div>
				<div class="sub-title">
					{{ FormMSG(1280, 'Loyalty cards') }}
				</div>
				<div class="content-card-collapsible">
					<b-row>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<template #label>
									<div>
										<span>
											{{ FormMSG(1281, 'SNCF loyalty card') }}
											<span class="text-fin">{{ FormMSG(1282, '(Type + number)') }}</span>
										</span>
									</div>
								</template>
								<b-form-input
									v-model="profile.trainLoyaltyCard"
									type="text"
									:placeholder="FormMSG(1283, 'Enter your sncf loyalty card')"
									@keydown.enter.prevent
								/>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<template #label>
									<div>
										<span>
											{{ FormMSG(1284, 'SNCF discount card') }}
											<span class="text-fin">{{ FormMSG(1282, '(Type + number)') }}</span>
										</span>
									</div>
								</template>
								<b-form-input
									v-model="profile.trainDiscountCard"
									type="text"
									:placeholder="FormMSG(1285, 'Enter your sncf discount card')"
									@keydown.enter.prevent
								/>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<template #label>
									<div>
										<span>
											{{ FormMSG(1286, 'Flying blue card') }}
											<span class="text-fin">{{ FormMSG(1287, '(Number)') }}</span>
										</span>
									</div>
								</template>
								<b-form-input
									v-model="profile.flyingLoyaltyCard"
									type="text"
									:placeholder="FormMSG(1288, 'Enter your flying blue card')"
									@keydown.enter.prevent
								/>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<template #label>
									<div>
										<span>
											{{ FormMSG(1289, 'Other airline card') }}
											<span class="text-fin">{{ FormMSG(1287, '(Number)') }}</span>
										</span>
									</div>
								</template>
								<b-form-input
									v-model="profile.otherAirlineCard"
									type="text"
									:placeholder="FormMSG(1288, 'Enter your other airline card')"
									@keydown.enter.prevent
								/>
							</b-form-group>
						</b-col>
					</b-row>
				</div>
				<div class="divider"></div>
				<div class="sub-title">
					{{ FormMSG(1290, 'Foreign worker') }}<span class="text-fin">{{ FormMSG(1291, "(to fill only if you're a foraign worker)") }}</span>
				</div>
				<div class="content-card-collapsible">
					<b-row>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group :label="FormMSG(1292, 'Residence permit number')">
								<b-form-input
									v-model="profile.residencePermitNumber"
									type="text"
									:placeholder="FormMSG(1293, 'Enter your residence permit number')"
									@keydown.enter.prevent
								/>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group :label="FormMSG(1294, 'Residence permit validity end')">
								<b-form-input
									type="date"
									:value="displayDateResidencePermitEndValidity"
									:placeholder="FormMSG(1295, 'DD/MM/YYYY')"
									@input="(e) => updateDate(e, 'residencePermitEndValidity')"
									@keydown.enter.prevent
								/>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label for="residentPermitXid">{{ FormMSG(1296, 'Resident permit') }}</label>
								<b-row class="ml-0">
									<div
										@click="onOpenUrl(getImage(profile.residentPermitXid, 'residentPermitXid'))"
										class="d-flex justify-content-center align-items-center cursor-pointer"
										v-if="checkFile(profile.residentPermitXid)"
									>
										<b-icon icon="file-earmark-pdf-fill" font-scale="2" />
									</div>
									<b-img
										v-else
										v-bind="imgPropSignatureId"
										rounded
										alt="residentPermitXid"
										class="cursor-pointer"
										:src="getImage(profile.residentPermitXid, 'residentPermitXid')"
										@click="showImage(profile.residentPermitXid)"
										:title="FormMSG(254, 'View Image')"
									></b-img>
									<button
										class="btn-transparent pl-0 ml-2"
										@click.prevent.stop="deleteImg('residentPermitXid')"
										v-if="profile.residentPermitXid && profile.residentPermitXid.length > 0"
									>
										<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
									</button>
								</b-row>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label for="workCardXid">{{ FormMSG(1297, 'Work card') }}</label>
								<b-row class="ml-0">
									<div
										@click="onOpenUrl(getImage(profile.workCardXid, 'workCardXid'))"
										class="d-flex justify-content-center align-items-center cursor-pointer"
										v-if="checkFile(profile.workCardXid)"
									>
										<b-icon icon="file-earmark-pdf-fill" font-scale="2" />
									</div>
									<b-img
										v-else
										v-bind="imgPropSignatureId"
										rounded
										alt="workCardXid"
										class="cursor-pointer"
										:src="getImage(profile.workCardXid, 'workCardXid')"
										@click="showImage(profile.workCardXid)"
										:title="FormMSG(254, 'View Image')"
									></b-img>
									<button
										class="btn-transparent pl-0 ml-2"
										@click.prevent.stop="deleteImg('workCardXid')"
										v-if="profile.workCardXid && profile.workCardXid.length > 0"
									>
										<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
									</button>
								</b-row>
							</b-form-group>
						</b-col>
					</b-row>
				</div>
				<div class="divider"></div>
				<div class="sub-title">
					{{ FormMSG(24, 'Miscellanous') }}
				</div>
				<div class="content-card-collapsible">
					<b-row>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group :label="FormMSG(1298, 'Pseudonym')">
								<b-form-input
									v-model="profile.pseudonym"
									type="text"
									:placeholder="FormMSG(1299, 'Enter your pseudonym')"
									@keydown.enter.prevent
								/>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="4" xl="4">
							<b-form-group :label="FormMSG(1300, 'Generic mention professional association')">
								<b-form-input
									v-model="profile.professionalAssociation"
									type="text"
									:placeholder="FormMSG(1301, 'Enter your generic mention ...')"
									@keydown.enter.prevent
								/>
							</b-form-group>
						</b-col>
					</b-row>
				</div>
				<div class="divider"></div>
				<div class="sub-title">{{ FormMSG(1705, 'Retirement') }}</div>
				<div class="content-card-collapsible">
					<b-row>
						<b-col sm="12" md="12" lg="3" xl="3" class="d-flex justify-content-start">
							<b-form-group class="text-center">
								<label>{{ FormMSG(1275, 'Retired ?') }}</label>
								<b-form-checkbox class="d-flex justify-content-start" size="lg" v-model="profile.retired" :value="true" switch>
									<span class="text-redired">{{ profile.retired ? FormMSG(1276, 'Yes') : FormMSG(1277, 'No') }}</span>
								</b-form-checkbox>
							</b-form-group>
						</b-col>
						<b-col v-if="profile.retired" sm="12" md="12" lg="3" xl="3">
							<b-form-group>
								<label>{{ FormMSG(1278, 'Retirement date') }}</label>
								<b-form-input
									type="date"
									:value="displayDateRetirementDate"
									:placeholder="FormMSG(1295, 'DD/MM/YYYY')"
									@input="(e) => updateDate(e, 'retirementDate')"
									@keydown.enter.prevent
								/>
							</b-form-group>
						</b-col>
					</b-row>
				</div>
				<div class="divider"></div>
				<div class="sub-title">{{ FormMSG(1706, 'Study') }}</div>
				<div class="content-card-collapsible">
					<b-row>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group :label="FormMSG(26002, 'Study level')">
								<b-form-input
									v-model="profile.otherStudyLevel"
									type="text"
									:placeholder="FormMSG(20066, 'Enter your study level ...')"
									@keydown.enter.prevent
								/>
							</b-form-group>
						</b-col>
					</b-row>
				</div>
				<div class="divider"></div>
				<div class="sub-title">{{ FormMSG(1707, 'Clothes') }}</div>
				<div class="content-card-collapsible">
					<b-row>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group :label="FormMSG(20603, 'Size')">
								<b-form-input v-model="profile.size" type="text" :placeholder="FormMSG(26007, 'Enter your size ...')" @keydown.enter.prevent />
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group :label="FormMSG(26400, 'Pant size')">
								<b-form-input
									v-model="profile.pantSize"
									type="text"
									:placeholder="FormMSG(20608, 'Enter your pant size ...')"
									@keydown.enter.prevent
								/>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group :label="FormMSG(26500, 'Jacket size')">
								<b-form-input
									v-model="profile.jacketSize"
									type="text"
									:placeholder="FormMSG(260090, 'Enter your jacket size ...')"
									@keydown.enter.prevent
								/>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group :label="FormMSG(20007, 'Shoe size')">
								<b-form-input
									v-model="profile.shoeSize"
									type="text"
									:placeholder="FormMSG(208000, 'Enter your shoe size ...')"
									@keydown.enter.prevent
								/>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="12" lg="3" xl="3">
							<b-form-group :label="FormMSG(2101019, 'Teeshirt size')">
								<b-form-input
									v-model="profile.tShirtSize"
									type="text"
									:placeholder="FormMSG(301100, 'Enter your teeshirt size ...')"
									@keydown.enter.prevent
								/>
							</b-form-group>
						</b-col>
					</b-row>
				</div>
			</b-collapse>
		</div>
		<!-- Password-->
		<div class="card-collapsible mt-3" v-if="isFilmSingleUserFree || isFilmSingleUser">
			<div class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center" @click="handleClickHeader('password')">
				<div class="title w-90">
					<b-icon icon="key-fill" style="color: rgba(6, 38, 62, 0.84)" /><span class="ml-3">{{ FormMSG(87, 'Password') }}</span>
				</div>
				<div class="d-flex justify-content-end w-10">
					<component v-if="!configAccordion['password'].isVisible" :is="getIconLucide('ChevronDown')" color="#06263E" :size="22" />
					<component v-if="configAccordion['password'].isVisible" :is="getIconLucide('ChevronUp')" color="#06263E" :size="22" />
				</div>
			</div>
			<b-collapse :visible="configAccordion['password'].isVisible">
				<div class="content-card-collapsible">
					<p class="text-muted">
						* {{ FormMSG(88, "Make sure it's at least 15 characters OR at least 8 characters including a number and a lowercase letter.") }}
					</p>
					<b-button variant="outline-primary" :disabled="statusSendCodeValidation.global" @click="handleChangePassword">
						<span v-if="!statusSendCodeValidation.progress && !statusSendCodeValidation.success">
							{{ FormMSG(89, 'Change Password') }}
						</span>
						<span v-if="statusSendCodeValidation.progress && !statusSendCodeValidation.success">
							<b-spinner label="Loading..." small /> {{ FormMSG(90, 'Send code validation') }}
						</span>
						<span v-if="!statusSendCodeValidation.progress && statusSendCodeValidation.success">
							{{ FormMSG(91, 'Code validation sended') }}
						</span>
					</b-button>
				</div>
			</b-collapse>
		</div>
		<!-- TEAM SESSION -->
		<div v-if="isDeterProject === true" class="card-collapsible mt-3">
			<div class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center" @click="handleClickHeader('deter')">
				<div class="title w-90">
					<component :is="getLucideIcon('UserCog')" :size="20" /><span class="ml-3">{{ FormMSG(131, 'Team session') }}</span>
				</div>
				<div class="d-flex justify-content-end w-10">
					<component v-if="!configAccordion['deter'].isVisible" :is="getIconLucide('ChevronDown')" color="#06263E" :size="22" />
					<component v-if="configAccordion['deter'].isVisible" :is="getIconLucide('ChevronUp')" color="#06263E" :size="22" />
				</div>
			</div>
			<b-collapse :visible="configAccordion['deter'].isVisible">
				<div class="content-card-collapsible">
					<b-table-simple id="deterSessionTeamTable">
						<b-thead head-variant="dark">
							<b-tr>
								<b-th></b-th>
								<b-th>{{ FormMSG(465, 'Team A') }}</b-th>
								<b-th>{{ FormMSG(466, 'Team B') }}</b-th>
							</b-tr>
						</b-thead>
						<b-tbody>
							<b-tr>
								<b-th>
									{{ FormMSG(457, 'Session 1') }}
								</b-th>
								<b-td>
									<b-form-checkbox v-model="profile.session1A" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
								<b-td>
									<b-form-checkbox v-model="profile.session1B" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
							</b-tr>
							<b-tr>
								<b-th>
									{{ FormMSG(458, 'Session 2') }}
								</b-th>
								<b-td>
									<b-form-checkbox v-model="profile.session2A" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
								<b-td>
									<b-form-checkbox v-model="profile.session2B" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
							</b-tr>
							<b-tr>
								<b-th>
									{{ FormMSG(459, 'Session 3') }}
								</b-th>
								<b-td>
									<b-form-checkbox v-model="profile.session3A" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
								<b-td>
									<b-form-checkbox v-model="profile.session3B" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
							</b-tr>
							<b-tr>
								<b-th>
									{{ FormMSG(460, 'Session 4') }}
								</b-th>
								<b-td>
									<b-form-checkbox v-model="profile.session4A" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
								<b-td>
									<b-form-checkbox v-model="profile.session4B" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
							</b-tr>
							<b-tr>
								<b-th>
									{{ FormMSG(461, 'Session 5') }}
								</b-th>
								<b-td>
									<b-form-checkbox v-model="profile.session5A" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
								<b-td>
									<b-form-checkbox v-model="profile.session5B" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
							</b-tr>
							<b-tr>
								<b-th>
									{{ FormMSG(462, 'Session 6') }}
								</b-th>
								<b-td>
									<b-form-checkbox v-model="profile.session6A" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
								<b-td>
									<b-form-checkbox v-model="profile.session6B" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
							</b-tr>
							<b-tr>
								<b-th>
									{{ FormMSG(463, 'Session 7') }}
								</b-th>
								<b-td>
									<b-form-checkbox v-model="profile.session7A" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
								<b-td>
									<b-form-checkbox v-model="profile.session7B" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
							</b-tr>
							<b-tr>
								<b-th>
									{{ FormMSG(464, 'Session 8') }}
								</b-th>
								<b-td>
									<b-form-checkbox v-model="profile.session8A" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
								<b-td>
									<b-form-checkbox v-model="profile.session8B" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
					<!-- <b-table-simple id="deterSessionTeamTable">
						<b-thead head-variant="dark">
							<b-tr>
								<b-th></b-th>
								<b-th>{{ FormMSG(457, 'Session 1') }}</b-th>
								<b-th>{{ FormMSG(458, 'Session 2') }}</b-th>
								<b-th>{{ FormMSG(459, 'Session 3') }}</b-th>
								<b-th>{{ FormMSG(460, 'Session 4') }}</b-th>
								<b-th>{{ FormMSG(461, 'Session 5') }}</b-th>
								<b-th>{{ FormMSG(462, 'Session 6') }}</b-th>
								<b-th>{{ FormMSG(463, 'Session 7') }}</b-th>
								<b-th>{{ FormMSG(464, 'Session 8') }}</b-th>
							</b-tr>
						</b-thead>
						<b-tbody>
							<b-tr>
								<b-th>{{ FormMSG(465, 'Team A') }}</b-th>
								<b-td>
									<b-form-checkbox v-model="profile.session1A" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
								<b-td>
									<b-form-checkbox v-model="profile.session2A" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
								<b-td>
									<b-form-checkbox v-model="profile.session3A" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
								<b-td>
									<b-form-checkbox v-model="profile.session4A" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
								<b-td>
									<b-form-checkbox v-model="profile.session5A" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
								<b-td>
									<b-form-checkbox v-model="profile.session6A" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
								<b-td>
									<b-form-checkbox v-model="profile.session7A" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
								<b-td>
									<b-form-checkbox v-model="profile.session8A" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
							</b-tr>
							<b-tr>
								<b-th>{{ FormMSG(466, 'Team B') }}</b-th>
								<b-td>
									<b-form-checkbox v-model="profile.session1B" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
								<b-td>
									<b-form-checkbox v-model="profile.session2B" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
								<b-td>
									<b-form-checkbox v-model="profile.session3B" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
								<b-td>
									<b-form-checkbox v-model="profile.session4B" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
								<b-td>
									<b-form-checkbox v-model="profile.session5B" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
								<b-td>
									<b-form-checkbox v-model="profile.session6B" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
								<b-td>
									<b-form-checkbox v-model="profile.session7B" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
								<b-td>
									<b-form-checkbox v-model="profile.session8B" size="lg" :value="true" :unchecked-value="false" />
								</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple> -->
				</div>
			</b-collapse>
		</div>

		<!-- AUTHORIZATION -->
		<div class="card-collapsible mt-3">
			<div
				class="header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer"
				@click="handleClickHeader('authorisation')"
			>
				<div class="title w-90">
					<b-icon icon="shield-lock-fill" style="color: rgba(6, 38, 62, 0.84)" /><span class="ml-3">{{ FormMSG(133, 'Authorisation') }}</span>
				</div>
				<div class="cursor-pointer d-flex justify-content-end w-10">
					<component v-if="!configAccordion['authorisation'].isVisible" :is="getLucideIcon('ChevronDown')" color="#06263E" :size="22" />
					<component v-if="configAccordion['authorisation'].isVisible" :is="getLucideIcon('ChevronUp')" color="#06263E" :size="22" />
				</div>
			</div>
		</div>
		<b-collapse :visible="configAccordion['authorisation'].isVisible">
			<div class="content-card-collapsible">
				<div>
					<div>
						<b-row>
							<b-col sm="12" md="12">
								<fieldset class="card-inside">
									<legend class="card-inside">
										{{ FormMSG(301, 'Application rights') }}
									</legend>
									<b-row style="padding-left: 30px">
										<b-col md="4" class="mb-3">
											<b-form-checkbox
												size="lg"
												v-model="roleUserData.canManageCrewList"
												:value="true"
												class="pj-cb pb-1"
												:unchecked-value="false"
												@change="handleAccessLevel('canManageCrewList', $event, roleUserData, screenAccess)"
											>
												{{ FormMSG(2970, 'Manage crew list') }}
											</b-form-checkbox>
										</b-col>
									</b-row>
									<b-row style="padding-left: 30px">
										<b-col md="4" v-if="useDoc" class="mb-3">
											<b-form-checkbox
												size="lg"
												v-model="roleUserData.canManageDocuments"
												:value="true"
												class="pj-cb pb-1"
												:unchecked-value="false"
												@change="handleAccessLevel('canManageDocuments', $event, roleUserData, screenAccess)"
											>
												{{ FormMSG(2971, 'Manage documents') }}
											</b-form-checkbox>
										</b-col>
										<b-col md="4" v-if="useDoc" class="mb-3">
											<b-form-checkbox
												size="lg"
												v-model="roleUserData.canManageDocumentPackage"
												:value="true"
												:unchecked-value="false"
												class="pj-cb pb-1"
												@change="handleAccessLevel('canManageDocumentPackage', $event, roleUserData, screenAccess)"
											>
												{{ FormMSG(2440, 'Manage document package') }}
											</b-form-checkbox>
										</b-col>
										<b-col v-if="useCallSheet" md="4" class="mb-3">
											<b-form-checkbox
												size="lg"
												class="pj-cb pb-1"
												v-model="roleUserData.canManageCallSheets"
												@change="handleAccessLevel('canManageCallSheets', $event, roleUserData, screenAccess)"
											>
												{{ FormMSG(2450, 'Manage call sheets') }}
											</b-form-checkbox>
										</b-col>
									</b-row>
									<b-row style="padding-left: 30px">
										<b-col md="4" v-if="useAcc" class="mb-3">
											<b-form-checkbox
												size="lg"
												v-model="roleUserData.canManageAccommodations"
												:value="true"
												class="pj-cb pb-1"
												:unchecked-value="false"
												@change="handleAccessLevel('canManageAccommodations', $event, roleUserData, screenAccess)"
											>
												{{ FormMSG(2973, 'Manage accomodations') }}
											</b-form-checkbox>
										</b-col>
										<b-col md="4" v-if="useLoc" class="mb-3">
											<b-form-checkbox
												size="lg"
												v-model="roleUserData.canManageLocations"
												:value="true"
												:unchecked-value="false"
												class="pj-cb pb-1"
												@change="handleAccessLevel('canManageLocations', $event, roleUserData, screenAccess)"
											>
												{{ FormMSG(2449, 'Manage locations') }}
											</b-form-checkbox>
										</b-col>
										<b-col md="4" v-if="useTrans" class="mb-3">
											<b-form-checkbox
												size="lg"
												v-model="roleUserData.canManageTransport"
												:value="true"
												:unchecked-value="false"
												class="pj-cb pb-1"
												@change="handleAccessLevel('canManageTransport', $event, roleUserData, screenAccess)"
											>
												{{ FormMSG(20449, 'Manage transport') }}
											</b-form-checkbox>
										</b-col>
									</b-row>
									<b-row style="padding-left: 30px">
										<b-col md="4" v-if="useImportExport" class="mb-3">
											<b-form-checkbox
												size="lg"
												class="pj-cb pb-1"
												v-model="roleUserData.canExportImport"
												@change="handleAccessLevel('canExportImport', $event, roleUserData, screenAccess)"
											>
												{{ FormMSG(2455, 'Can import/export') }}
											</b-form-checkbox>
										</b-col>
										<b-col md="4" v-if="useBudget" class="mb-3">
											<b-form-checkbox
												size="lg"
												class="pj-cb pb-1"
												v-model="roleUserData.canViewDepartmentBudget"
												@change="handleAccessLevel('canViewDepartmentBudget', $event, roleUserData, screenAccess)"
											>
												{{ FormMSG(2690, 'View department budget') }}
											</b-form-checkbox>
										</b-col>
										<b-col md="4" v-if="useBudget" class="mb-3">
											<b-form-checkbox
												size="lg"
												v-model="roleUserData.canViewGlobalBudget"
												:value="true"
												:unchecked-value="false"
												class="pj-cb pb-1"
												@change="handleAccessLevel('canViewGlobalBudget', $event, roleUserData, screenAccess)"
											>
												{{ FormMSG(24430, 'View global budget') }}
											</b-form-checkbox>
										</b-col>
									</b-row>
									<b-row style="padding-left: 30px">
										<b-col md="4" v-if="useGreenTable" class="mb-3">
											<b-form-checkbox
												size="lg"
												v-model="roleUserData.canViewGreenTable"
												class="pj-cb pb-1"
												@change="handleAccessLevel('canViewGreenTable', $event, roleUserData, screenAccess)"
											>
												{{ FormMSG(245640, 'View Green Table') }}
											</b-form-checkbox>
										</b-col>
										<b-col md="4" v-if="useReporting" class="mb-3">
											<b-form-checkbox
												size="lg"
												v-model="roleUserData.canAccessGreenReporting"
												class="pj-cb pb-1"
												@change="handleAccessLevel(canAccessGreenReporting, $event, roleUserData, screenAccess)"
											>
												{{ FormMSG(24560, 'View Green reporting screens') }}
											</b-form-checkbox>
										</b-col>
										<b-col md="4" v-if="useCarbonRemoval" class="mb-3">
											<b-form-checkbox
												size="lg"
												v-model="roleUserData.canManageCarbonRemoval"
												:value="true"
												:unchecked-value="false"
												class="pj-cb pb-1"
												@change="handleAccessLevel('canManageCarbonRemoval', $event, roleUserData, screenAccess)"
											>
												{{ FormMSG(24480, 'Manage carbon removal') }}
											</b-form-checkbox>
										</b-col>
									</b-row>
									<b-row style="padding-left: 30px">
										<b-col md="4" v-if="useWaste" class="mb-3">
											<b-form-checkbox
												size="lg"
												class="pj-cb pb-1"
												v-model="roleUserData.canViewWasteScreens"
												@change="handleAccessLevel('canViewWasteScreens', $event, roleUserData, screenAccess)"
											>
												{{ FormMSG(244005, 'View Waste screens') }}
											</b-form-checkbox>
										</b-col>
										<b-col md="4" v-if="useTipOfDay" class="mb-3">
											<b-form-checkbox
												size="lg"
												class="pj-cb pb-1"
												v-model="roleUserData.canManageTipOfTheDay"
												@change="handleAccessLevel('canManageTipOfTheDay', $event, roleUserData, screenAccess)"
											>
												{{ FormMSG(24405, 'Manage tip of the day') }}
											</b-form-checkbox>
										</b-col>
									</b-row>
									<b-row style="padding-left: 30px">
										<b-col md="4" class="mb-3">
											<b-form-checkbox
												size="lg"
												v-model="roleUserData.canAccessConfig"
												class="pj-cb pb-1 mb-3"
												@change="handleAccessLevel(canAccessConfig, $event, roleUserData, screenAccess)"
											>
												{{ FormMSG(246, 'Can access config') }}
											</b-form-checkbox>
										</b-col>
									</b-row>
								</fieldset>
								<fieldset v-if="needValidation" class="card-inside">
									<legend class="card-inside">
										{{ FormMSG(302, 'Validation rights') }}
									</legend>
									<div class="lh-22">
										<h6>
											<b>{{ FormMSG(303, 'Validation level for PO, Expenses and Timehseets') }}</b>
										</h6>
										<b-form-select
											v-model="roleUserData.validationLevel"
											:options="optionsForValidationLevel"
											@change="manageValidationChange(roleUserData)"
											id="valLevel"
											size="md"
										>
										</b-form-select>
									</div>
								</fieldset>
								<fieldset v-if="useDoc" class="card-inside">
									<legend class="card-inside">
										{{ FormMSG(305, 'Onboarding validation role') }}
									</legend>
									<div class="lh-22">
										<b-row>
											<b-col md="4">
												<b-form-checkbox
													size="lg"
													class="pj-cb mb-3 pb-1"
													v-model="roleUserData.gateKeeper"
													@change="handleAccessLevel(gateKeeper, $event, roleUserData, isRole)"
												>
													{{ FormMSG(266, 'Gate Keeper') }}
												</b-form-checkbox>
											</b-col>
											<b-col md="4">
												<b-form-checkbox
													size="lg"
													class="pj-cb mb-3 pb-1"
													v-model="roleUserData.productionAccountant"
													@change="handleAccessLevel(productionAccountant, $event, roleUserData, isRole)"
												>
													{{ FormMSG(261, 'Production Account') }}
												</b-form-checkbox>
											</b-col>
											<b-col md="4">
												<b-form-checkbox
													size="lg"
													class="pj-cb mb-3 pb-1"
													v-model="roleUserData.upm"
													@change="handleAccessLevel(upm, $event, roleUserData, isRole)"
												>
													{{ FormMSG(262, 'UPM') }}
												</b-form-checkbox>
											</b-col>
											<b-col md="4">
												<b-form-checkbox
													size="lg"
													class="pj-cb mb-3 pb-1"
													v-model="roleUserData.lineProducer"
													@change="handleAccessLevel(lineProducer, $event, roleUserData, isRole)"
												>
													{{ FormMSG(263, 'Line Producer') }}
												</b-form-checkbox>
											</b-col>
											<b-col md="4">
												<b-form-checkbox
													size="lg"
													class="pj-cb mb-3 pb-1"
													v-model="roleUserData.studio"
													@change="handleAccessLevel(studio, $event, roleUserData, isRole)"
												>
													{{ FormMSG(265, 'Studio') }}
												</b-form-checkbox>
											</b-col>
											<b-col md="4">
												<b-form-checkbox
													size="lg"
													class="pj-cb mb-3 pb-1"
													v-model="roleUserData.canValidateI9"
													@change="handleAccessLevel(canValidateI9, $event, roleUserData, screenAccess)"
												>
													{{ FormMSG(270, 'Verify I-9') }}
												</b-form-checkbox>
											</b-col>
										</b-row>
									</div>
								</fieldset>
								<fieldset v-if="usePoAndExp" class="card-inside">
									<legend class="card-inside">
										{{ FormMSG(355, 'Editing authorization') }}
									</legend>
									<div class="lh-22">
										<b-row>
											<b-col v-if="useExp" md="4">
												<b-form-checkbox
													v-model="roleUserData.canEditExpense"
													@change="handleAccessLevel(canEditExpense, $event, roleUserData, screenAccess)"
													size="lg"
													class="pj-cb mb-3 pb-1"
												>
													{{ FormMSG(250, 'Can edit Expenses') }}
												</b-form-checkbox>
											</b-col>
											<b-col v-if="usePo" md="4">
												<b-form-checkbox
													v-model="roleUserData.canEditPo"
													@change="handleAccessLevel(canEditPo, $event, roleUserData, screenAccess)"
													size="lg"
													class="pj-cb mb-3 pb-1"
												>
													{{ FormMSG(255, 'Can edit PO') }}
												</b-form-checkbox>
											</b-col>
										</b-row>
									</div>
								</fieldset>
								<fieldset class="card-inside" v-if="useFinance">
									<legend class="card-inside">
										{{ FormMSG(360, 'Accounting/Budget situation') }}
									</legend>
									<div class="lh-22">
										<b-row>
											<b-col md="4">
												<b-form-checkbox
													v-model="roleUserData.canLockBudget"
													@change="handleAccessLevel(canLockBudget, $event, roleUserData, screenAccess)"
													size="lg"
													class="pj-cb mb-3 pb-1"
												>
													{{ FormMSG(361, 'Can lock/unlock initial budget') }}
												</b-form-checkbox>
											</b-col>
											<b-col md="4">
												<b-form-checkbox
													v-model="roleUserData.canModifyEstimatedFinalCost"
													@change="handleAccessLevel(canModifyEstimatedFinalCost, $event, roleUserData, screenAccess)"
													size="lg"
													class="pj-cb mb-3 pb-1"
												>
													{{ FormMSG(362, 'Can modify EFC') }}
												</b-form-checkbox>
											</b-col>
											<b-col md="4">
												<b-form-checkbox
													v-model="roleUserData.canEditAndAddCode"
													@change="handleAccessLevel(canEditAndAddCode, $event, roleUserData, screenAccess)"
													size="lg"
													class="pj-cb mb-3 pb-1"
												>
													{{ FormMSG(363, 'Can edit/add codes') }}
												</b-form-checkbox>
											</b-col>
										</b-row>
									</div>
								</fieldset>
								<fieldset v-if="showEntryForCrew" class="card-inside">
									<legend class="card-inside">
										{{ FormMSG(356, 'Entry for someone else') }}
									</legend>
									<div class="lh-22">
										<b-row>
											<b-col v-if="showEntryForCrewTimesheet" md="4">
												<b-form-checkbox
													@change="handleUserEntryLevelChange"
													v-model="userEntryLevel.timeSheets"
													size="lg"
													class="pj-cb mb-3 pb-1"
												>
													{{ FormMSG(251, 'Timesheets') }}
												</b-form-checkbox>
											</b-col>
											<b-col v-if="showEntryForCrewExpenses" md="4">
												<b-form-checkbox
													@change="handleUserEntryLevelChange"
													v-model="userEntryLevel.expenses"
													size="lg"
													class="pj-cb mb-3 pb-1"
												>
													{{ FormMSG(252, 'Expenses') }}
												</b-form-checkbox>
											</b-col>
											<b-col v-if="showEntryForCrewPo" md="4">
												<b-form-checkbox
													@change="handleUserEntryLevelChange"
													v-model="userEntryLevel.po"
													size="lg"
													class="pj-cb mb-3 pb-1"
												>
													{{ FormMSG(253, 'PO') }}
												</b-form-checkbox>
											</b-col>
										</b-row>
										<h6 v-show="userEntryLevel.po || userEntryLevel.expenses || userEntryLevel.timeSheets">
											<b>
												{{ FormMSG(357, 'Department selection') }}
											</b>
										</h6>
										<v-select
											v-show="userEntryLevel.po || userEntryLevel.expenses || userEntryLevel.timeSheets"
											class="style-chooser"
											@input="checkDepartment"
											multiple
											placeholder="Select a department"
											label="text"
											v-model="selectedDepartment"
											:options="allDepartements"
										>
											<template v-slot:option="option">
												<b-form-checkbox v-model="option.selected" disabled size="lg" class="pj-cb mb-3 pb-1">
													{{ option.text }}
												</b-form-checkbox>
											</template>
										</v-select>
									</div>
								</fieldset>
								<fieldset class="card-inside">
									<legend class="card-inside">{{ FormMSG(1200, 'Planning') }}</legend>
									<div class="lh-22">
										<h6>
											<b>{{ FormMSG(1201, 'Validation Fimalac') }}</b>
										</h6>
										<b-form-select
											v-model="validationFimalac"
											:options="optionsForValidationLevelFimamac"
											@change="manageValidationChangeFimalac(roleUserData)"
											id="valLevel-fimalac"
											size="md"
										>
										</b-form-select>
									</div>
								</fieldset>
							</b-col>
						</b-row>
					</div>
				</div>
			</div>
		</b-collapse>
		<NewCapture
			v-model="capturePict"
			:key="refreshTokenCaptureItem"
			parentType="user"
			:parentId="profile.id"
			auto-crop-capture
			cam-mode-first
			only-one-crop-mode
			@change="handleImgsTaked"
		/>
		<b-modal
			header-class="header-class-modal-doc-package"
			ref="my-modal"
			title="Crop Picture"
			size="cropSize"
			v-model="cropPict"
			@ok="endCrop"
			@cancel="endCropCancel"
			ok-variant="success"
			ok-title="Confirm Save"
			cancel-title="Cancel"
			modal-class="mui-animation"
			:fade="false"
		>
			<CropPicture ref="myCropPicture"> </CropPicture>
		</b-modal>
		<change-mail-modal
			:open="showChangeMailModal"
			:profile="profile"
			@modal:close="showChangeMailModal = false"
			@change-mail-modal:save="handleMailModalSave"
		/>
		<change-phone-modal
			:open="showChangePhoneModal"
			:profile="profile"
			@modal:close="showChangePhoneModal = false"
			@change-phone-modal:save="handlePhoneModalSave"
		/>
		<validation-code-modal
			:open="showValidationCodeModal"
			:email-for-validation="profile.email"
			@validation-code-modal:save="handleValidationCodeModalSave"
			@modal:close="handleValidationCodeModalClose"
		/>
		<b-modal
			header-class="header-class-modal-doc-package"
			v-model="openPictureModal"
			:title="FormMSG(105, 'Picture')"
			class="modal-success"
			ok-variant="success"
			ok-only
			@ok="openPictureModal = false"
			modal-class="mui-animation"
			:fade="false"
		>
			<div>
				<b-img :src="imgToShow" fluid-grow />
			</div>
		</b-modal>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import gql from 'graphql-tag';
import { isNil, noTilde } from '~utils';
import * as icons from 'lucide-vue';
import { isValidIBANNumber, isBic } from '@/shared/iban-validator';
import { store } from '@/store';
import NewCapture from '@/components/CaptureModule/Main';
import CropPicture from '@/components/CropPicture';
import MainAddressLocation from '@/components/LocationService/MainAddressLocation';
import CapturePicture from '@/components/CapturePicture';
// import { profile, updateUserProjectReference } from "@/cruds/users.crud";
import moment from 'moment';
import ShareInformation from '@/components/InfoSheet/ShareInformation';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import GlobalMixin from '@/mixins/global.mixin';
import verte from 'verte';
import 'verte/dist/verte.css';
import ChangeMailModal from '@/components/MyProfileMobile/ChangeMailModal';
import ChangePhoneModal from '@/components/MyProfileMobile/ChangePhoneModal';
import ValidationCodeModal from '@/components/MyProfileMobile/ValidationCodeModal';
import MobileProfilePicCaptureBtn from '@/components/CaptureModule/MobileProfilePicCaptureBtn';
import { getObjectFromValue } from '~utils';
import { isSecuritySocialNumberValid } from '@/shared/validators/security-solcial.validator';
import {
	GetPlanningValidation,
	addUpdAccessUsers,
	addUpdAccessBudget,
	addUpdAccessMyBudget,
	addUpdProdFromProject,
	addUpdAdminToProject,
	validate,
	validateFimalac,
	GetEncodingValidation
} from '@/cruds/users.crud';
import { getDepartments } from '../../cruds/department.crud';
import { ACCESS_LEVEL } from '@/utils/utils';
import DeterMixin from '@/mixins/deter.mixin';
import { convertIsoToKey } from '@/shared/helpers';
import GoogleAutoComplete from '@/components/GoogleAutoComplete';

const axios = require('axios').create();

const mutation_UpdMyProfilePicture = gql`
	mutation ($picture: String!) {
		UpdMyProfile(newProfile: { picture: $picture })
	}
`;

export default {
	name: 'MemoProfile',
	mixins: [languageMessages, isSingleProjectMixin, GlobalMixin, DeterMixin],
	props: {
		selectedUserData: {
			type: Object,
			required: true,
			default: () => {}
		},
		data: {
			type: Object,
			required: true,
			default: () => {}
		},
		fillClassContainer: {
			type: String,
			required: false,
			default: 'container-layout' // default empty, or 'container-layout'
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false
		},
		handleAccessLevel: {
			type: Function,
			required: false
		}
	},
	components: {
		CropPicture,
		CapturePicture,
		GoogleAutoComplete,
		NewCapture,
		ShareInformation,
		verte,
		ChangeMailModal,
		ChangePhoneModal,
		ValidationCodeModal,
		MobileProfilePicCaptureBtn,
		'v-address': MainAddressLocation
	},
	computed: {
		isDeterAction() {
			if (+this.profile.bankDetailXid.length !== 0 && +this.profile.medicalVisitDocumentXid.length === 0) {
				this.messageContext = this.FormMSG(1711, 'visit medical document');
				this.incompleteProlile = true;
				return true;
			}
			if (+this.profile.bankDetailXid.length === 0 && +this.profile.medicalVisitDocumentXid.length !== 0) {
				this.messageContext = this.FormMSG(1712, 'bank detail document');
				this.incompleteProlile = true;
				return true;
			}
			if (+this.profile.bankDetailXid.length === 0 && +this.profile.medicalVisitDocumentXid.length === 0) {
				this.messageContext = this.FormMSG(1713, 'visit medical document and bank detail document');
				this.incompleteProlile = true;
				return true;
			} else {
				this.messageContext = '';
				this.incompleteProlile = false;
				return false;
			}
		},
		useCallSheet() {
			return store.state.myProject.useCallSheet;
		},
		useFinance() {
			return store.state.myProject.useFinance;
		},
		useExp() {
			return store.state.myProject.useExpense;
		},
		usePo() {
			return store.state.myProject.usePurchaseOrder;
		},
		usePoAndExp() {
			return store.state.myProject.useExpense || store.state.myProject.usePurchaseOrder;
		},
		needValidation() {
			return (
				store.state.myProject.useExpense ||
				store.state.myProject.usePurchaseOrder ||
				store.state.myProject.useTimesheet ||
				store.state.myProject.useTimesheetPlanning
			);
		},
		useCarbonTemplate() {
			return store.state.myProject.useCarbonTemplate;
		},
		useGreenTable() {
			return store.state.myProject.useGreenTable;
		},
		useReporting() {
			return store.state.myProject.useReporting;
		},
		useCarbonRemoval() {
			return store.state.myProject.useCarbonRemoval;
		},
		useWaste() {
			return store.state.myProject.useWaste;
		},
		useTipOfDay() {
			return store.state.myProject.useTipOfDay;
		},
		useBudget() {
			return store.state.myProject.useBudget;
		},
		useImportExport() {
			return store.state.myProject.useImport || store.state.myProject.useExport;
		},
		useTrans() {
			return store.state.myProject.useTransport;
		},
		useLoc() {
			return store.state.myProject.useLocations;
		},
		useAcc() {
			return store.state.myProject.useAccommodation;
		},
		useDoc() {
			return store.state.myProject.useDocument;
		},
		menuTitle() {
			let titles = this.FormMenu(1338);
			return titles;
		},
		countryCodes() {
			return this.FormMenu(9);
		},
		distanceUnit() {
			return store.state.myProfile.distanceUnit;
		},
		menuInvalidity() {
			let invalidity = this.FormMenu(1339);
			return invalidity;
		},
		displayDateLastMedicalVisit() {
			const splitDate = this.profile.lastMedicalVisit ? this.profile.lastMedicalVisit.substring(0, 10) : '';
			if (splitDate !== '0001-01-01') {
				return moment(splitDate).format('YYYY-MM-DD');
			}
			return '';
		},
		displayDateMedicalVisitValidity() {
			const splitDate = this.profile.medicalVisitValidity ? this.profile.medicalVisitValidity.substring(0, 10) : '';
			if (splitDate !== '0001-01-01') {
				return moment(splitDate).format('YYYY-MM-DD');
			}
			return '';
		},
		displayDateDisabilityNotification() {
			const splitDate = this.profile.disabilityNotification ? this.profile.disabilityNotification.substring(0, 10) : '';
			if (splitDate !== '0001-01-01') {
				return moment(splitDate).format('YYYY-MM-DD');
			}
			return '';
		},
		displayDateRetirementDate() {
			const splitDate = this.profile.retirementDate ? this.profile.retirementDate.substring(0, 10) : '';
			if (splitDate !== '0001-01-01') {
				return moment(splitDate).format('YYYY-MM-DD');
			}
			return '';
		},
		displayDateResidencePermitEndValidity() {
			const splitDate = this.profile.residencePermitEndValidity ? this.profile.residencePermitEndValidity.substring(0, 10) : '';
			if (splitDate !== '0001-01-01') {
				return moment(splitDate).format('YYYY-MM-DD');
			}
			return '';
		},
		countries() {
			let countries = this.FormMenu(8);

			countries.sort((a, b) => {
				if (noTilde(a.text).toLowerCase() < noTilde(b.text).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text).toLowerCase() > noTilde(b.text).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			countries = [
				{
					value: 0,
					text: this.FormMSG(187, 'Select a country...')
				},
				...countries
			];

			return countries;
		},
		showEntryForCrew() {
			return store.state.myProject.useEntryForCrew;
		},
		showEntryForCrewTimesheet() {
			return store.state.myProject.useEntryForCrewTimesheet;
		},
		showEntryForCrewExpenses() {
			return store.state.myProject.useEntryForCrewExpenses;
		},
		showEntryForCrewPo() {
			return store.state.myProject.useEntryForCrewPo;
		},
		optionsForValidationLevelFimamac() {
			const defaultMenu = {
				...[...this.FormMenu(1113)][0],
				value: -1
			};
			const menuFimalac = [...this.FormMenu(10010)];
			const menu = [defaultMenu, ...menuFimalac];
			return menu;
		},
		optionsForValidationLevel() {
			return this.FormMenu(1113);
		},
		displayBirthDate() {
			const splitBirthDate = this.profile.birthDate ? this.profile.birthDate.substring(0, 10) : '';
			if (splitBirthDate !== '0001-01-01') {
				return splitBirthDate;
			}

			return '';
		},
		displaySpouseBirthDate() {
			const splitBirthDate = this.profile.spouseBirthDate ? this.profile.spouseBirthDate.substring(0, 10) : '';
			if (splitBirthDate !== '0001-01-01') {
				return splitBirthDate;
			}

			return '';
		},
		menuGenders() {
			const res = this.FormMenu(1002);
			return [
				{
					value: null,
					text: this.FormMSG(92, 'Please select an option')
				},
				...res
			];
		},
		optionsForCivilStatus() {
			return this.FormMenu(1001);
		},
		optionsForLanguages() {
			return this.FormMenu(1);
		},
		imageName() {
			if (this.profile.picture && this.profile.picture.length > 0) {
				var strImg = process.env.VUE_APP_GQL + '/images/' + this.profile.picture;

				return strImg;
			} else {
				return 'img/brand/avatar.jpg';
			}
		},
		useTask() {
			return store.state.myProject.useTask;
		},
		isProdStore() {
			return store.isProd();
		},
		isFilmSingleUserFree() {
			return store.isFilmSingleUserFree();
		},
		isFilmSingleUser() {
			return store.isFilmSingleUser();
		},
		isPme() {
			return store.isPme();
		},
		vehicleTypes() {
			let types = this.FormMenu(10003);

			types.sort((a, b) => {
				if (noTilde(a.text).toLowerCase() < noTilde(b.text).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text).toLowerCase() > noTilde(b.text).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return types;
		},
		vehicleEnergies() {
			let energies = this.FormMenu(10004);

			energies.sort((a, b) => {
				if (noTilde(a.text).toLowerCase() < noTilde(b.text).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text).toLowerCase() > noTilde(b.text).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return energies;
		}
	},
	data() {
		const {
			isAdmin,
			isVip,
			isProd,
			canAssign,
			canAccessUsers,
			canAccessBudget,
			canAccessMyBudget,
			canAccessGreenReporting,
			canAccessConfig,
			canAccessOnboarding,
			canAccessCrewList,
			canValidateI9,
			gateKeeper,
			productionAccountant,
			upm,
			lineProducer,
			studio,
			canEditExpense,
			canEditPo,
			canLockBudget,
			canModifyEstimatedFinalCost,
			canEditAndAddCode,
			screenAccess,
			isRole,
			delValidationRole,
			addUpdValidationRole
		} = ACCESS_LEVEL;
		return {
			validationFimalac: -1,
			userBankWarning: { iban: false, bic: false },
			isSocSecNumberWarning: false,
			profile: {
				taxesPercentage: 0
			},
			allDeps: {
				value: -1,
				message: 'All Departments'
			},
			projectID: 0,
			capturePict: false,
			cropPict: false,
			allDepartements: [],
			selectedDepartment: [],
			selectedDepartmentFimalac: [],
			pdfFile: '',
			refreshTokenCaptureItem: 0,
			haveAllergies: false,
			haveDependents: false,
			countryKey: 16965, // Belgium's countryKey
			countryKeyPostalAddress: 16965,
			countryCode: 'BE',
			countryCodePostalAddress: 'BE',
			haveChild: false,
			isShowPostalAddress: false,
			showChangeMailModal: false,
			showChangePhoneModal: false,
			showValidationCodeModal: false,
			codeValidation: 0,
			validationPlanning: null,
			statusSendCodeValidation: {
				global: false,
				progress: false,
				success: false
			},
			isMyProfileLoading: false,
			showInfoCapture: false,
			configAccordion: {
				'personnal-data': {
					isVisible: true
				},
				identification: {
					isVisible: false
				},
				finance: {
					isVisible: false
				},
				health: {
					isVisible: false
				},
				address: {
					isVisible: false
				},
				family: {
					isVisible: false
				},
				other: {
					isVisible: false
				},
				password: {
					isVisible: false
				},
				deter: {
					isVisible: false
				},
				authorisation: {
					isVisible: false
				},
				'personal-vehicle': {
					isVisible: false
				}
			},
			imgPropPictureId: { blank: true, blankColor: '#d5d6d7', width: 32, height: 32, class: 'm1' },
			imgPropPictureIdVerso: { blank: true, blankColor: '#d5d6d7', width: 32, height: 32, class: 'm1' },
			imgPropPictureDriverLicense: { blank: true, blankColor: '#d5d6d7', width: 32, height: 32, class: 'm1' },
			imgPropPictureDriverLicenseVerso: { blank: true, blankColor: '#d5d6d7', width: 32, height: 32, class: 'm1' },
			imgPropSignatureId: { blank: true, blankColor: '#d5d6d7', width: 32, height: 32, class: 'm1' },
			openPictureModal: false,
			imgToShow: '',
			isAdmin,
			isVip,
			isProd,
			canAssign,
			canAccessUsers,
			canAccessBudget,
			canAccessMyBudget,
			canAccessGreenReporting,
			canAccessConfig,
			canAccessOnboarding,
			canAccessCrewList,
			canValidateI9,
			gateKeeper,
			productionAccountant,
			upm,
			lineProducer,
			studio,
			canEditExpense,
			canLockBudget,
			canModifyEstimatedFinalCost,
			canEditAndAddCode,
			canEditPo,
			screenAccess,
			isRole,
			delValidationRole,
			addUpdValidationRole,
			userEntryLevel: {}
		};
	},
	created() {
		this.getDepartment();
		this.projectID = parseInt(store.projectID(), 10);
		this.GetEncodingValidation();
		this.reloadGetPlanningValidation();
		this.initCountry();
	},
	mounted() {
		this.$nextTick(() => {
			this.haveAllergies = this.profile.allergies !== '';
			this.haveDependents = parseInt(this.profile.numberOfDependentPerson) > 0 || parseInt(this.profile.numberOfDisabledPerson) > 0;
			this.haveChild = parseInt(this.profile.numberOfDependentChild) > 0 || parseInt(this.profile.numberOfDisabledChild) > 0;
		});
	},
	methods: {
		checkFile(file) {
			if (file !== undefined) {
				let extension = file.toString().match(/.pdf/gi);
				if (extension !== null && extension.join`` === '.pdf') {
					return true;
				}
				return false;
			}
			return false;
		},
		/**
		 * @param  {!String} iban
		 */
		handleSupplierIbanChange(iban) {
			this.userBankWarning.iban = !isValidIBANNumber(iban);
		},
		/**
		 * @param  {!String} bic
		 */
		handleSupplierBicChange(bic) {
			this.userBankWarning.bic = !isBic(bic);
		},
		handleUserEntryLevelChange() {
			let payload = {
				po: this.userEntryLevel.po,
				timeSheets: this.userEntryLevel.timeSheets,
				expenses: this.userEntryLevel.expenses,
				department: [],
				userId: this.profile.id,
				projectId: this.projectID
			};
			this.selectedDepartment.forEach((dep) => {
				payload.department.push(dep.value);
			});
			this.$emit('change', payload);
		},
		async reloadGetPlanningValidation() {
			try {
				const response = await GetPlanningValidation(this.profile.id);
				this.validationPlanning = response || null;
				if (this.validationPlanning !== null) {
					this.validationFimalac = this.validationPlanning.validationLevelTs;
				} else {
					this.validationFimalac = -1;
				}
			} catch (e) {
				console.error(e);
			}
		},
		async GetEncodingValidation() {
			await GetEncodingValidation(this.projectID, this.profile.id).then((result) => {
				if (result) {
					this.userEntryLevel = Object.assign({}, result);
					this.checkDepartment(result.department, true);
				}
			});
		},
		handleDynamicAddressChange(payload) {
			this.profile.socialSecurityCenterAddressId = +payload.id;
		},
		handleDynamicAddressDelete() {
			this.profile.socialSecurityCenterAddressId = 0;
		},
		handleAddressMapLinkChange(payload) {
			console.log({ mapAddress: payload });
		},
		onOpenUrl(url) {
			return window.open(url);
		},
		checkFile(file) {
			if (file !== undefined) {
				let extension = file.toString().match(/.pdf/gi);
				if (extension !== null && extension.join`` === '.pdf') {
					return true;
				}
				return false;
			}
			return false;
		},
		checkDepartmentFimalac(value, created) {
			this.allDepartements.forEach((item) => {
				item.selected = false;
			});
			value.forEach((item1) => {
				if (item1.value === -1) {
					this.selectedDepartmentFimalac = [this.allDepartements[0]];
					this.allDepartements.forEach((item) => {
						item.selected = true;
					});
				} else {
					const found = this.allDepartements.find((item2) => item1.value === item2.value);
					if (found) {
						found.selected = true;
						if (created) {
							this.selectedDepartmentFimalac.push(found);
						}
					}
				}
			});
			this.handleUserEntryLevelChange();
		},
		checkDepartment(value, created) {
			this.allDepartements.forEach((item) => {
				item.selected = false;
			});
			value.forEach((item1) => {
				if (item1.value === -1) {
					this.selectedDepartment = [this.allDepartements[0]];
					this.allDepartements.forEach((item) => {
						item.selected = true;
					});
				} else {
					const found = this.allDepartements.find((item2) => item1.value === item2.value);
					if (found) {
						found.selected = true;
						if (created) {
							this.selectedDepartment.push(found);
						}
					}
				}
			});
			this.handleUserEntryLevelChange();
		},
		mapDepartments(deps) {
			deps = deps.filter((d) => d.value !== 0);
			const departments = deps.map((d) => ({
				value: d.value,
				text: d.message,
				selected: false
			}));
			return departments;
		},
		async getDepartment() {
			this.allDepartements = [];
			let Deps = [];
			Deps.push(Object.assign({}, this.allDeps));
			Deps[0].message = this.FormMSG(201, 'All departments');
			await getDepartments(false, false).then((result) => {
				Deps = [...Deps, ...result];
				this.allDepartements = this.mapDepartments(Deps);
			});
		},
		handleSocSecNumberChange(value) {
			if (isNil(value) || value.trim().length === 0) return;
			this.isSocSecNumberWarning = !isSecuritySocialNumberValid(value);
		},
		handleAutoComplete(addressData, field) {
			if (field === 'legalAddress') {
				this.profile.address = {
					...this.profile.address,
					street: addressData.route || addressData.place.name,
					city: addressData.locality,
					number: addressData.street_number,
					state: addressData.administrative_area_level_1,
					zip: addressData.postal_code,
					country: addressData.country,
					countryCode: addressData.country_code,
					countryKey: convertIsoToKey(addressData.country_code),
					lat: addressData.latitude,
					lng: addressData.longitude
				};
			}
			if (field === 'postalAddress') {
				this.profile.postalAddress = {
					...this.profile.postalAddress,
					street: addressData.route || addressData.place.name,
					city: addressData.locality,
					number: addressData.street_number,
					state: addressData.administrative_area_level_1,
					zip: addressData.postal_code,
					country: addressData.country,
					countryCode: addressData.country_code,
					countryKey: convertIsoToKey(addressData.country_code),
					lat: addressData.latitude,
					lng: addressData.longitude
				};
			}
		},
		getCountry() {
			const country = getObjectFromValue(this.countryCodes, 'value', this.countryKey);
			this.countryCode = country ? country.text.toLowerCase() : '';
			this.$refs['google-search-address'].clearSelected();
			this.profile.address = {};
		},
		getCountryPostalAddress() {
			const country = getObjectFromValue(this.countryCodes, 'value', this.countryKeyPostalAddress);
			this.countryCodePostalAddress = country ? country.text.toLowerCase() : '';
			this.$refs['google-search-address'].clearSelected();
			this.profile.postalAddress = {};
		},
		updateDate($event, field) {
			if (this.profile[field].startsWith('T')) {
				this.profile[field] = $event + this.profile[field];
			} else {
				this.profile[field] = $event + this.profile[field].substring(10);
			}
		},
		initCountry() {
			let project = null;

			if (isNil(store.state) || isNil(store.state.myProject)) {
				project = store.state.myProject;
			}

			if (!isNil(this.profile) && !isNil(this.profile.address) && this.profile.address.countryKey > 0) {
				this.countryKey = this.profile.address.countryKey;
				this.countryCode = this.profile.address.countryCode;
			} else if (!isNil(project) && !isNil(project.legalEntity) && !isNil(project.legalEntity.address) && project.legalEntity.address.countryKey > 0) {
				this.countryKey = project.legalEntity.address.countryKey;
				this.countryCode = project.legalEntity.address.countryCode;
			}
			if (!isNil(this.profile.postalAddress) && this.profile.postalAddress.countryKey > 0) {
				this.countryKeyPostalAddress = this.profile.postalAddress.countryKey;
				this.countryCodePostalAddress = this.profile.postalAddress.countryCode;
			}
			if (this.profile.postalAddressID !== 0) {
				this.isShowPostalAddress = true;
			} else {
				this.isShowPostalAddress = false;
			}
		},
		async addOrUpdateValidation(userId, departmentNumber, levelTs, levelPo, levelExp, type) {
			const replace = true;
			const projectId = parseInt(store.projectID(), 10);
			await validate({
				userId,
				departmentNumber,
				levelTs,
				levelPo,
				levelExp,
				projectId,
				replace,
				type
			})
				.then((result) => {
					return result;
				})
				.catch((error) => {
					this.erreur = error;
				});
		},
		async addOrUpdateValidationFimalac(userId, departmentNumber, levelTs, levelPo, levelExp, type, validationId) {
			const replace = true;
			const projectId = parseInt(store.projectID(), 10);
			await validateFimalac({
				userId,
				departmentNumber,
				levelTs,
				levelPo,
				levelExp,
				projectId,
				replace,
				type,
				validationId
			})
				.then((result) => {
					return result;
				})
				.catch((error) => {
					this.erreur = error;
				});
		},
		manageValidationChangeFimalac(item) {
			const type = 4;
			// const validationId = this.validationPlanning ? +this.validationPlanning.id : 0;
			const validationId = 0;
			this.addOrUpdateValidationFimalac(+item.id, -1, this.validationFimalac, null, null, type, validationId);
		},
		manageValidationChange(item) {
			this.addOrUpdateValidation(
				parseInt(item.id),
				parseInt(item.department),
				parseInt(item.validationLevel),
				parseInt(item.validationLevel),
				parseInt(item.validationLevel),
				0
			);
		},
		async manageCanAccessMyBudgetChange(value, item, showToast = true) {
			try {
				await addUpdAccessMyBudget({
					userId: item.id,
					canAccessMyBudget: value
				});

				item.canAccessMyBudget = value;
				if (showToast) {
					this.createToastForSuccess();
				}
			} catch (error) {
				this.erreur = error;
			}
		},
		async manageCanAccessBudgetChange(value, item, showToast = true) {
			try {
				await addUpdAccessBudget({
					userId: item.id,
					canAccessBudget: value
				});

				item.canAccessBudget = value;
				if (showToast) {
					this.createToastForSuccess();
				}
			} catch (error) {
				this.erreur = error;
			}
		},
		async manageCanAccessUsersChange(value, item, showToast = true) {
			try {
				await addUpdAccessUsers({
					userId: item.id,
					canAccessUsers: value
				});

				item.canAccessUsers = value;
				if (showToast) {
					this.createToastForSuccess();
				}
			} catch (error) {
				this.erreur = error;
			}
		},
		createToastForSuccess() {
			this.$toast({
				message: this.FormMSG(267, 'Update permissions successfully'),
				title: 'Success',
				variant: 'success'
			});
		},
		async manageProdChange(value, item) {
			try {
				await addUpdProdFromProject({
					userId: item.id,
					isProd: value
				});

				item.canAccessUsers = value;
				await this.manageCanAccessUsersChange(value, item, false);

				item.canAccessBudget = value;
				await this.manageCanAccessBudgetChange(value, item, false);

				item.isProd = value;
				this.createToastForSuccess();
			} catch (error) {
				this.erreur = error;
			}
		},
		async manageAdminChange(value, item) {
			try {
				await addUpdAdminToProject({
					userId: item.id,
					isAdmin: value
				});
				item.canAccessMyBudget = value;
				await this.manageCanAccessMyBudgetChange(value, item, false);

				item.isAdmin = value;
				this.createToastForSuccess();
			} catch (error) {
				this.erreur = error;
			}
		},
		// async initProfile() {
		//     this.profile = await profile();
		// },
		handleValidationCodeModalSave() {
			this.$router.push('/password');
		},
		async handleChangePassword() {
			this.statusSendCodeValidation.global = true;
			this.statusSendCodeValidation.progress = true;

			setTimeout(() => {
				this.statusSendCodeValidation.progress = false;
				this.statusSendCodeValidation.success = true;
				this.showValidationCodeModal = true;
			}, 100);
		},
		handleValidationCodeModalClose() {
			this.showValidationCodeModal = false;
			this.codeValidation = '';
			this.statusSendCodeValidation.global = false;
			this.statusSendCodeValidation.progress = false;
			this.statusSendCodeValidation.success = false;
		},
		handlePhoneModalSave(payload) {
			const newPhone = payload.newPhoneE164;
			const mutation = gql`
				mutation UPD_MY_PROFILE($phone: String) {
					UpdMyProfile(newProfile: { phone: $phone })
				}
			`;
			this.$apollo
				.mutate({
					mutation,
					variables: {
						phone: newPhone
					}
				})
				.then((result) => {
					store.state.profile.phone = newPhone;
					this.profile.phone = newPhone;
					if (this.$screen.width <= 567) {
						this.menuActiveForMobile = '';
						this.createToastForMobile(this.FormMSG(93, 'Profile updated successfully'), '', '');
					} else {
						this.$toast({
							message: this.FormMSG(94, 'Profile updated successfully'),
							title: this.FormMSG(95, 'Success'),
							variant: 'success'
						});
					}
				})
				.catch((e) => {
					this.erreur = e;
				});
		},
		handleMailModalSave(payload) {
			const newEmail = payload.newEmail;
			const mutation = gql`
				mutation UPD_MY_PROFILE($email: String) {
					UpdMyProfile(newProfile: { email: $email })
				}
			`;
			this.$apollo
				.mutate({
					mutation,
					variables: {
						email: newEmail
					}
				})
				.then((result) => {
					store.state.profile.email = newEmail;
					this.profile.email = newEmail;
					if (this.$screen.width <= 567) {
						this.menuActiveForMobile = '';
						this.createToastForMobile(this.FormMSG(96, 'Profile updated successfully'), '', '');
					} else {
						this.$toast({
							message: this.FormMSG(97, 'Profile updated successfully'),
							title: this.FormMSG(98, 'Success'),
							variant: 'success'
						});
					}
				})
				.catch((e) => {
					this.erreur = e;
				});
		},
		incrementValue(data) {
			let value = parseInt(this.profile[data]);
			this.profile[data] = value + 1;
		},
		decrementValue(data) {
			let value = parseInt(this.profile[data]);
			if (value > 0) {
				this.profile[data] = value - 1;
			}
		},
		handleChangeHaveAllergies(payload) {
			if (!payload) {
				this.profile.allergies = '';
			}
		},
		handleChangeHaveDependents(payload) {
			if (!payload) {
				this.profile.numberOfDependentPerson = 0;
				this.profile.numberOfDisabledPerson = 0;
			}
		},
		handleChangeHaveChild(payload) {
			if (!payload) {
				this.profile.numberOfDependentChild = 0;
				this.profile.numberOfDisabledChild = 0;
			}
		},
		getIconLucide(name) {
			return icons[name];
		},
		handleMenuMobileClick(payload) {
			this.menuActiveForMobile = payload;
		},
		updateBirthDate: function ($event) {
			if (this.profile.birthDate.startsWith('T')) {
				this.profile.birthDate = $event + this.profile.birthDate;
			} else {
				this.profile.birthDate = $event + this.profile.birthDate.substring(10);
			}
		},
		updateSpouseBirthDate($event) {
			if (this.profile.spouseBirthDate.startsWith('T')) {
				this.profile.spouseBirthDate = $event + this.profile.spouseBirthDate;
			} else {
				this.profile.spouseBirthDate = $event + this.profile.spouseBirthDate.substring(10);
			}
		},
		enableSaveButton() {
			this.disableSaveButton = false;
		},
		startCapture: function () {
			// initialise global store variable and open dialog to capture and crop
			//console.log("instartcapture");
			store.state.capturedImage = '';
			store.state.imageCropped = '';
			this.disableSaveButton = true;
			this.capturePict = true;
		},
		cancelCapture() {
			store.state.capturedImage = '';
			// stop camera
			this.$refs.CaptCompo.onStop();
			this.capturePict = false;
		},
		endCapture: function (item) {
			// stop camera
			this.$refs.CaptCompo.onStop();
			// copy store image name into profile data
			this.capturePict = false;
			// start cropping if image was captured
			if (store.state.capturedImage.length > 0) {
				// an image was captured
				// console.log("in End capture");
				store.state.imageToCrop = store.state.capturedImage;
				this.$refs.myCropPicture.bind(); // force the component to link the new image to the display
				this.cropPict = true;
			}
		},
		endCrop: function (item) {
			// save image to server
			//console.log("this.$refs.myCropPicture.cropped:",this.$refs.myCropPicture.cropped);
			if (store.state.imageCropped.length > 0) {
				this.saveImage(store.state.imageCropped);
			}
			this.cropPict = false;
		},
		endCropCancel: function (item) {
			store.state.imageCropped = '';
			this.cropPict = false;
		},
		b64toFile(b64Data, filename, contentType) {
			var sliceSize = 512;
			var byteCharacters = atob(b64Data);
			var byteArrays = [];
			for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
				var slice = byteCharacters.slice(offset, offset + sliceSize);
				var byteNumbers = new Array(slice.length);

				for (var i = 0; i < slice.length; i++) {
					byteNumbers[i] = slice.charCodeAt(i);
				}
				var byteArray = new Uint8Array(byteNumbers);
				byteArrays.push(byteArray);
			}
			var file = new File(byteArrays, filename, {
				type: contentType
			});
			return file;
		},
		saveImage(imgData) {
			this.isLoading = true;
			var retStr = '';
			// remove header data:image...
			var base64Data = imgData.replace(/^data:image\/jpeg;base64,/, '');
			// create in memory file
			var fil = this.b64toFile(base64Data, 'test.jpg', 'jpg');
			let formData = new FormData(); // instantiate it
			// suppose you have your file ready
			formData.set('uploadimage', fil);
			var config = {
				headers: {
					Authorization: `Bearer ${store.state.token}`,
					'content-type': 'multipart/form-data'
				}
			};
			var urlStr = process.env.VUE_APP_GQL + '/upload';
			axios
				.post(urlStr, formData, config)
				.then((response) => {
					retStr = response.data;
					this.profile.picture = retStr;
					store.state.imageCropped = '';
					this.updateUserPictureName();
					this.isLoading = false;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => (this.isLoading = false));
			return retStr;
		},
		showToken: function () {
			console.log(store.state.token);
		},
		removeImage() {
			this.isLoading = true;
			//console.log("inremoveimage:",this.pdfFile.name);
			var retStr = '';
			let formData = new FormData(); // instantiate it
			formData.append('fileName', this.pdfFile.name);
			var config = {
				headers: {
					Authorization: `Bearer ${store.state.token}`,
					'content-type': 'multipart/form-data'
				}
			};
			var urlStr = process.env.VUE_APP_GQL + '/removedocument';
			axios
				.post(urlStr, formData, config)
				.then((response) => {
					retStr = response.data;
					this.isLoading = false;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => (this.isLoading = false));
			return retStr;
		},
		showToken: function () {
			console.log(store.state.token);
		},
		updateUserPictureName() {
			this.errors = [];
			this.isLoading = true;
			this.$apollo
				.mutate({
					mutation: mutation_UpdMyProfilePicture,
					variables: {
						picture: this.profile.picture
					}
				})
				.then((result) => {
					this.isLoading = false;
					this.successModal = true;
				})
				.catch((error) => {
					this.isLoading = false;
					this.erreur = error;
				});
		},
		submitFile() {
			// console.log("submit file:", this.pdfFile);
			let formData = new FormData();
			formData.append('uploadimage', this.pdfFile);
			formData.append('fileName', this.pdfFile.name);
			formData.append('fileType', this.pdfFile.type);
			var urlStr = process.env.VUE_APP_GQL + '/upload';

			axios
				.post(urlStr, formData, {
					headers: {
						Authorization: `Bearer ${store.state.token}`,
						'Content-Type': 'multipart/form-data'
					}
				})
				.then((result) => {
					//console.log("success result:", result);
				})
				.catch(function () {
					console.log('FAILURE!!');
				});
		},
		/**
		 * @param {Array} imgs
		 */
		handleImgsTaked(imgs) {
			const retStr = imgs[0];
			this.profile.picture = retStr;
			store.state.imageCropped = '';
			this.updateUserPictureName();
			this.isLoading = false;
			this.refreshTokenCaptureItem = this.refreshTokenCaptureItem + 1;
		},
		handleClickHeader(key) {
			this.configAccordion[key].isVisible = !this.configAccordion[key].isVisible;
		},
		getImage(picture, fieldName = '') {
			if (!isNil(picture) && picture.length > 0) {
				if (fieldName === 'pictureId') {
					this.imgPropPictureId.blank = false;
				} else if (fieldName === 'pictureIdVerso') {
					this.imgPropPictureIdVerso.blank = false;
				} else if (fieldName === 'pictureDriverLicense') {
					this.imgPropPictureDriverLicense.blank = false;
				} else if (fieldName === 'pictureDriverLicenseVerso') {
					this.imgPropPictureDriverLicenseVerso.blank = false;
				} else if (fieldName === 'signatureId') {
					this.imgPropSignatureId.blank = false;
				}
			}
			return process.env.VUE_APP_GQL + '/images/' + picture;
		},
		showImage(picture) {
			if (isNil(picture) || (!isNil(picture) && picture.length === 0)) return;
			this.imgToShow = `${process.env.VUE_APP_GQL}/images/${picture}`;
			this.openPictureModal = true;
		},
		async handleChangedAppRight(payload, fieldName) {
			if (fieldName === 'isAdmin' || fieldName === 'canAccessMyBudget') {
				if (fieldName === 'isAdmin') {
					this.roleUserData.canAccessMyBudget = payload;
					await this.handleAccessLevel(this.canAccessMyBudget, payload, this.roleUserData, this.screenAccess);
				}
				if (fieldName === 'canAccessMyBudget') {
					this.roleUserData.isAdmin = payload;
					await this.handleAccessLevel(this.canAccessMyBudget, payload, this.roleUserData, this.screenAccess);
				}
			} else if (fieldName === 'isProd') {
				this.roleUserData.canAccessUsers = payload;
				await this.handleAccessLevel(this.canAccessUsers, payload, this.roleUserData, this.screenAccess);
				this.roleUserData.canAccessBudget = payload;
				await this.handleAccessLevel(this.canAccessBudget, payload, this.roleUserData, this.screenAccess);
				this.roleUserData.canAccessOnboarding = payload;
				await this.handleAccessLevel(this.canAccessOnboarding, payload, this.roleUserData, this.screenAccess);
			} else if (fieldName === 'canAccessUsers' || fieldName === 'canAccessBudget' || fieldName === 'canAccessOnboarding') {
				if (fieldName === 'canAccessUsers') {
					await this.handleAccessLevel(this.canAccessUsers, payload, this.roleUserData, this.screenAccess);
					if ((payload && !this.roleUserData.isProd) || (!payload && !this.roleUserData.canAccessBudget && !this.roleUserData.canAccessOnboarding)) {
						this.roleUserData.isProd = payload;
					}
				}
				if (fieldName === 'canAccessBudget') {
					await this.handleAccessLevel(this.canAccessBudget, payload, this.roleUserData, this.screenAccess);
					if ((payload && !this.roleUserData.isProd) || (!payload && !this.roleUserData.canAccessUsers && !this.roleUserData.canAccessOnboarding)) {
						this.roleUserData.isProd = payload;
					}
				}
				if (fieldName === 'canAccessOnboarding') {
					await this.handleAccessLevel(this.canAccessOnboarding, payload, this.roleUserData, this.screenAccess);
					if ((payload && !this.roleUserData.isProd) || (!payload && !this.roleUserData.canAccessUsers && !this.roleUserData.canAccessBudget)) {
						this.roleUserData.isProd = payload;
					}
				}
			}
		}
	},
	watch: {
		data: {
			handler(profile) {
				if (!isNil(profile)) {
					this.profile = profile;
					// this.profile = {
					// 	...this.profile,
					// 	vehicle: {
					// 		name: '',
					// 		brand: '',
					// 		model: '',
					// 		numberPlate: '',
					// 		type: null,
					// 		energy: null,
					// 		kgCoTwo: ''
					// 	}
					// };
				}
			},
			immediate: true,
			deep: true
		},
		'profile.picture'(picture) {
			this.$store.dispatch('setAuthUser', { picture });
		},
		selectedUserData: {
			handler(value) {
				if (!isNil(value)) {
					this.roleUserData = value;
					this.roleUserData.isAdmin = this.roleUserData.canAccessMyBudget;
					if (this.roleUserData.canAccessUsers && this.roleUserData.canAccessBudget && this.roleUserData.canAccessOnboarding) {
						this.roleUserData.isProd = true;
					}
				}
			},
			immediate: true,
			deep: true
		}
	}
};
</script>
